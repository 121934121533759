import {AiOutlineClose} from "react-icons/ai";
import React from "react";
import {MainBtn} from "../button";
import {TextInput} from "../inputs";

const AddAdminmodal = ({handleClose}) => {
    return (
        <div>
            <div
                className="tw-fixed tw-z-50 tw-inset-0 tw-bg-myblue tw-bg-opacity-30 tw-flex tw-justify-center tw-h-screen tw-w-full tw-items-center">
                <div className="tw-bg-white tw-rounded-xl tw-w-96 tw-p-4">
                    <div className="tw-flex tw-justify-between tw-items-center">
                        <h6 className="tw-text-xl tw-font-medium tw-text-[#013467] satoshi">
                            Add New Admin
                        </h6>
                        <AiOutlineClose className="tw-cursor-pointer" onClick={handleClose}/>
                    </div>
                    <form action="" className="tw-mt-4">
                        <div>

                            <TextInput name={'name'} label={'Name'}/>
                        </div>
                        <div className="tw-mt-4">
                            <TextInput label={'Email'}/>
                        </div>
                        <div className="tw-mt-3">
                            <TextInput label={'Department'}/>
                        </div>
                        <div className="tw-mt-5">
                            <MainBtn text={'Send invite'}/>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
export default AddAdminmodal