import React, { Fragment, useEffect, useState } from "react";
import MainContainer from "../../components/maincontainer";
import MainHeader from "../../components/mainheader";
import {
	AssignedTable,
	CoursesDocumentTable,
	// CompletedTable,
	OngoingTable,
} from "../../components/tables/groupstable";
import Avatar from "../../assets/Category tab.svg";
import { useLocation } from "react-router-dom";
import useSocketStore from "../../data/stores/socketstore";
import useErrorStore from "../../data/stores/errorstore";
import useAuthStore, { apiCall } from "../../data/stores/authstore";
import { toast } from "react-toastify";
import { v4 } from "uuid";
import { MainBtn } from "../../components/button";
import moment from "moment";
import { BiLoader, BiCheckDouble, BiCheck } from "react-icons/bi";
import { useGroupsStore } from "../../data/stores/loggerstore";

export const MainGroupsDetail = () => {
	const [tab, setTab] = useState("assigned"),
		{ state } = useLocation(),
		{ user } = useAuthStore(),
		{ data } = useGroupsStore(),
		[mainState, setMainState] = useState(null),
		[item, setItem] = useState(null),
		tabs = [
			"assigned",
			user?.privilege !== "instructor" ? "ongoing" : "",
			user?.privilege !== "instructor" ? "completed" : "",
			state?.chat ? "chat" : "",
		],
		{ active } = useSocketStore();

	useEffect(() => {
		data?.docs?.map(it => it?._id === state?._id && setMainState(it));
	}, [data, state]);

	return (
		<div>
			<MainContainer>
				<MainHeader text={"Courses"} />
				<div className="tw-flex tw-flex-col tw-mt-8 lg:tw-flex-row tw-gap-6">
					<div className="lg:tw-w-[70%] tw-p-5 tw-bg-white tw-rounded-xl">
						<h3 className="tw-text-xl tw-font-bold tw-text-secondary satoshi">
							All Groups
						</h3>
						<div className="tw-mt-5 tw-flex tw-gap-5">
							{tabs
								?.filter(it => it)
								?.map(tb => (
									<button
										onClick={() => {
											setTab(tb);
											setItem(null);
										}}
										className={`tw-px-5 tw-h-10 tw-rounded-full satoshi tw-text-base tw-font-medium tw-capitalize ${
											tab === tb
												? "tw-bg-[#0269D0] tw-text-white"
												: "tw-bg-[#0269D01A] tw-text-[#0269D0]"
										}`}>
										{tb}
									</button>
								))}
						</div>
						<div className="tw-mt-6">
							{tab === "assigned" && (
								<AssignedTable
									setTab={it => {
										setTab("document");
										setItem(it);
									}}
								/>
							)}
							{tab === "document" && <CoursesDocumentTable item={item} />}
							{["ongoing", "completed"]?.includes(tab) && (
								<OngoingTable
									type={tab}
									setTab={it => {
										setTab("document");
										setItem(it);
									}}
								/>
							)}
							{/* {tab === "completed" && <CompletedTable />} */}
							{tab === "chat" && <ChatSection />}
						</div>
					</div>
					<div className="lg:tw-w-[30%] tw-space-y-12">
						<div
							style={{
								border: "0.5px solid #01346780",
							}}
							className="tw-bg-white tw-rounded-xl tw-p-6">
							<h4 className="tw-text-base tw-font-medium tw-text-secondary">
								Instructors
							</h4>
							<div className="tw-mt-4 tw-w-full">
								{mainState?.instructors?.map((ins, i) => (
									<div
										key={i}
										style={{
											borderBottom: "0.5px solid #0134674D",
										}}
										className="tw-h-12 tw-w-full tw-flex tw-justify-between tw-px-3 tw-items-center tw-cursor-pointer">
										<span className="tw-text-sm tw-font-normal tw-text-secondary">
											{ins?.firstName} {ins?.lastName}
										</span>
										<span
											className={`tw-text-sm tw-font-normal tw-capitalize tw-text-[${
												active?.find(it => it?.userId === ins?._id)?.isOnline
													? "#0269D0"
													: "#FF0000"
											}]`}>
											{active?.find(it => it?.userId === ins?._id)?.isOnline
												? "Online"
												: "Offline"}
										</span>
									</div>
								))}
							</div>
						</div>
						<div
							style={{
								border: "0.5px solid #01346780",
							}}
							className="tw-bg-white tw-rounded-xl tw-p-6">
							<h4 className="tw-text-base tw-font-medium tw-text-secondary">
								Group Members
							</h4>
							<div className="tw-mt-4 tw-w-full">
								{mainState?.members?.map((ins, i) => (
									<div
										key={i}
										// onClick={() => setChat(true)}
										style={{
											borderBottom: "0.5px solid #0134674D",
										}}
										className="tw-h-12 tw-cursor-pointer tw-w-full tw-flex tw-justify-between tw-px-3 tw-items-center">
										<span className="tw-text-sm tw-font-normal tw-text-secondary">
											{ins?.firstName} {ins?.lastName}
										</span>
										<span
											className={`tw-text-sm tw-font-normal tw-capitalize tw-text-[${
												active?.find(it => it?.userId === ins?._id)?.isOnline
													? "#0269D0"
													: "#FF0000"
											}]`}>
											{active?.find(it => it?.userId === ins?._id)?.isOnline
												? "Online"
												: "Offline"}
										</span>
									</div>
								))}
							</div>
						</div>
					</div>
				</div>
			</MainContainer>
		</div>
	);
};

export const ChatSection = () => {
  const { state } = useLocation(),
		{ message, resetSocket, getChats } = useSocketStore(),
		[chats, setChats] = useState(null),
		[content, setContent] = useState(""),
		{ returnErrors } = useErrorStore(),
		{ data } = useGroupsStore(),
		[mainState, setMainState] = useState(null),
		{ user } = useAuthStore();

     useEffect(() => {
				data?.docs?.map(it => it?._id === state?._id && setMainState(it));
			}, [data, state]);

  useEffect(() => {
		if (mainState) {
			setChats(null);
			resetSocket();
			apiCall(
				"get",
				`/api/v1/groups/chat?chat=${mainState?.chat}`,
				null,
				setChats
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [mainState]);

  useEffect(() => {
    apiCall("get", "/api/v1/groups/chat/manage-chat", null, getChats);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // console.log({ message, state });

  useEffect(() => {
		if (message) {
			if (message?.chat === mainState?.chat)
				setChats({
					...chats,
					data: {
						...chats?.data,
						docs: Array.isArray(chats?.data?.docs)
							? [...chats?.data?.docs, message]
							: [message],
					},
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [mainState, message]);

  let handleSubmit = async (e) => {
    e?.preventDefault();
    let newState = {
			chat: mainState?.chat || mainState?._id,
			content,
			type: "text",
			initId: v4(),
			createdAt: new Date(),
		};
    console.log({ newState });
    if (!mainState?.chat || !content) return;

    setChats({
      ...chats,
      data: {
        ...chats?.data,
        docs: Array.isArray(chats?.data?.docs)
          ? [...chats?.data?.docs, { ...newState, sender: user }]
          : [{ ...newState, sender: user }],
      },
    });
    setContent("");
    let { response, errArr, errMsg } = await apiCall(
      "post",
      `/api/v1/groups/chat`,
      newState,
      null,
      null,
      "noToast"
    );
    // console.log({ response, errArr, errMsg });
    if (errArr) {
      return returnErrors(errArr);
    }
    if (errMsg) {
      return toast.error(errMsg);
    }
    if (response) {
      let newD = chats?.data?.docs;
      console.log({ response });
      console.log({ newD }, "one");
      let findIt = newD?.find((it) => it?.initId === newState?.initId);
      if (findIt)
        newD = [
          ...newD?.map((it) =>
            it?.initId === newState?.initId ? response?.data : it
          ),
        ];
      else {
        newD = [...newD, response?.data];
      }

      console.log({ newD }, "two");
      setChats({
        ...chats,
        data: {
          ...chats?.data,
          docs: newD,
        },
      });
      return;
    }
  };

  return (
		<div className="tw-p-5 tw-bg-white tw-rounded-xl tw-h-full tw-flex tw-flex-col tw-relative">
			<h2 className="tw-text-xl tw-font-bold tw-text-secondary tw-hidden">
				Course Chat- English Language
			</h2>
			<div className="tw-mt-5 tw-h-screen tw-mb-16 tw-overflow-y-scroll noScroll tw-space-y-5">
				<div className="tw-space-y-4 tw-overflow-y-auto tw-max-h-[750px] tw-w-full">
					{chats?.data?.docs?.map((ct, m) => (
						<Fragment key={m}>
							{ct?.sender?._id === user?._id ? (
								<>
									<div className="tw-float-end  tw-flex tw-flex-col tw-w-3/4 ">
										<div className="tw-items-center tw-flex tw-gap-4">
											<div
												style={{
													border: "0.5px solid #4ABE82",
												}}
												className="tw-rounded-full tw-p-4 tw-w-full">
												<span className="tw-text-sm tw-font-normal satoshi tw-text-[#001302]">
													{ct?.content}
												</span>
											</div>
											<img
												src={ct?.sender?.image?.url || Avatar}
												alt=""
												className=""
											/>
										</div>
										<div className="tw-flex tw-justify-end tw-items-center">
											<small className="tw-text-sm tw-font-normal satoshi tw-text-[#000851]">
												{moment(ct?.createdAt).diff(moment(), "years") < 0
													? moment(ct?.createdAt).format("L hh:mm A")
													: moment(ct?.createdAt).diff(moment(), "months") < 0
													? moment(ct?.createdAt).format("DD/MM hh:mm A")
													: moment(ct?.createdAt).diff(moment(), "days") < 0
													? moment(ct?.createdAt).format("DD/MM hh:mm A")
													: moment(ct?.createdAt).format("hh:mm A")}
											</small>
											<span className="tw-ml-2">
												{ct?.sender?._id === user?._id &&
													(ct?.isDeliveredTo?.includes(user?._id) ? (
														<BiCheckDouble
															className={`fontReduce ${
																ct?.isSeenTo?.includes(user?._id)
																	? " tw-text-[#002F19]"
																	: "text-dark"
															}`}
														/>
													) : ct?.isSent ? (
														<BiCheck className="" />
													) : (
														<BiLoader className="" />
													))}
											</span>
										</div>
									</div>
								</>
							) : (
								<>
									<div className="tw-float-start tw-flex tw-flex-col tw-w-3/4 ">
										<div className="tw-flex tw-gap-4 tw-items-center ">
											<img
												src={ct?.sender?.image?.url || Avatar}
												alt=""
												className=""
											/>
											<div
												style={{
													border: "1px solid #EBEBEB",
												}}
												className="tw-rounded-xl tw-p-4 tw-w-full">
												<span className="tw-text-sm tw-font-normal satoshi tw-text-[#001302]">
													{ct?.content}
												</span>
											</div>
										</div>
									</div>
								</>
							)}
							{/* <div
								className={`tw-items-center tw-w-3/4 tw-flex tw-gap-4 ${
									ct?.sender?._id === user?._id
										? "tw-float-end"
										: "tw-float-start"
								}`}>
								<div>
									<div
										className={` tw-w-96 tw-p-5  ${
											ct?.sender?._id === user?._id
												? "tw-bg-[#F4FAFF] tw-mr-8"
												: "tw-bg-white tw-border tw-border-[#EEEEEE] tw-ml-8"
										} `}>
										<small className="tw-text-sm satoshi tw-font-normal tw-text-[#000851]">
											{ct?.content}
										</small>
									</div>
									<div className="tw-flex tw-justify-between tw-items-center">
										<div
											className={`tw-flex tw-items-center ${
												ct?.sender?._id === user?._id
													? "tw-order-1"
													: " tw-order-2"
											}`}>
											<small className="tw-text-sm tw-font-normal satoshi tw-text-[#000851]">
												{moment(ct?.createdAt).diff(moment(), "years") < 0
													? moment(ct?.createdAt).format("L hh:mm A")
													: moment(ct?.createdAt).diff(moment(), "months") < 0
													? moment(ct?.createdAt).format("DD/MM hh:mm A")
													: moment(ct?.createdAt).diff(moment(), "days") < 0
													? moment(ct?.createdAt).format("DD/MM hh:mm A")
													: moment(ct?.createdAt).format("hh:mm A")}
											</small>
											<span className="tw-ml-2">
												{ct?.sender?._id === user?._id &&
													(ct?.isDeliveredTo?.includes(user?._id) ? (
														<BiCheckDouble
															className={`fontReduce ${
																ct?.isSeenTo?.includes(user?._id)
																	? " tw-text-[#002F19]"
																	: "text-dark"
															}`}
														/>
													) : ct?.isSent ? (
														<BiCheck className="" />
													) : (
														<BiLoader className="" />
													))}
											</span>
										</div>
										<img
											src={ct?.sender?.image?.url || Avatar}
											alt=""
											className={`tw-size-8 tw-rounded-full ${
												ct?.sender?._id === user?._id
													? "tw-order-2"
													: " tw-order-1"
											}`}
										/>
									</div>
								</div>
							</div> */}
						</Fragment>
					))}
				</div>
				<div className="tw-absolute tw-w-full tw-bottom-6 tw-px-5">
					<div className="tw-w-5/6 tw-mx-auto tw-bg-[#F0F4FF] tw-rounded-lg tw-relative tw-h-12 tw-border-[0.4px] tw-border-[#FFFFFF33]">
						<>
							<input
								type="text"
								className="tw-rounded-lg tw-px-8 tw-w-[80%] tw-h-full tw-bg-transparent tw-border-[0.4px] tw-border-[#FFFFFF33]"
								value={content}
								onChange={e => setContent(e?.target?.value)}
							/>
							<div className="tw-absolute tw-right-2 tw-top-1">
								<MainBtn
									// bg={"#000851"}
									// color={"white"}
									text={"Send"}
									onClick={handleSubmit}
								/>
							</div>
						</>
					</div>
				</div>
			</div>
		</div>
	);
};

const GroupsDetail = () => <MainGroupsDetail />;

export default GroupsDetail;
