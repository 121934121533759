import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import PageFooter from "../../components/pagefooter";
import useErrorStore from "../../data/stores/errorstore";
import axios from "axios";
import OtpInput from "react18-otp-input";
import { ClipLoader } from "react-spinners";

const Activate = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	let [code, setCode] = useState(""),
		navigate = useNavigate(),
		{ returnErrors } = useErrorStore(),
		[loading, setLoading] = useState(false);

	const handleSubmit = async e => {
		e?.preventDefault();

		setLoading(true);
		try {
			let res = await axios.post("/api/v1/user/otp", {
				otp: code,
				nextScreen: `${window.location.origin}/login`,
			});
			console.log({ resp: res?.data });
			toast.success(res?.data?.message);
			navigate("/login");
		} catch (err) {
			if (err?.response?.status === 429 || err?.response?.status === 405)
				toast.error(err?.response?.data ? err?.response?.data : err?.message);
			console.log({ err });
			let error = err.response?.data?.error;
			if (error && error?.length > 1) {
				returnErrors(error);
			} else {
				toast.error(err?.response?.data?.message);
			}
		}
		setLoading(false);
	};

	useEffect(() => {
		if (code && code?.length === 5) {
			handleSubmit();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [code]);

	return (
		<>
			<div className="tw-py-10">
				<div className="fullHeight lg:tw-flex tw-hidden bg-white  ">
					<motion.div
						className="m-auto w-100 h-100 "
						style={{
							maxWidth: "550px",
						}}
						initial={{
							x: -250,
						}}
						animate={{
							x: 0,
						}}
						transition={{
							duration: 2,
							delay: 1,
							type: "tween",
						}}>
						<div
							style={{
								background: "rgba(255, 255, 255, 0.2)",
								border: "1px solid #0B2239",
								backdropFilter: "blur(7px)",
								borderRadius: "20px",
							}}
							className="border-bottom-4-color  p-3 py-md-5">
							<h2 className="text-center tw-text-4xl fw-bold  pb-1 text-[#0B2239]">
								Verification
							</h2>

							<p className="text-center   pb-4 text-[#0B2239]">
								To complete your registration, an OTP has been sent to your
								email address for verification. Enter OTP below to sign up
								successfully!.
							</p>

							<form onSubmit={handleSubmit}>
								<div className="tw-flex tw-flex-col tw-gap-4 tw-items-center tw-justify-center tw-mt-20 ">
									<OtpInput
										value={code}
										onChange={setCode}
										numInputs={5}
										disabled={loading}
										shouldAutoFocus={true}
										isInputNum={true}
										renderSeparator={<span> - </span>}
										renderInput={props => <input {...props} />}
										inputStyle={"tw-h-12 tw-border tw-text-xl  w-100"}
										containerStyle={{ width: "350px" }}
									/>
									{loading && <ClipLoader size={20} color="#0B2239" />}
									{/* <p className="text-muted">01:30</p> */}
								</div>
							</form>
							<p className="tw-text-base tw-font-medium tw-text-[#0B2239] Inter tw-text-center tw-pt-6">
								Have an account?{" "}
								<span
									onClick={() => navigate("/login")}
									className=" f tw-font-bold tw-text-sm tw-underline">
									Log in
								</span>
							</p>
						</div>
					</motion.div>
				</div>
				<div className="lg:tw-hidden tw-block tw-w-full tw-h-100 tw-bg-white fullHeight tw-pb-10">
					<motion.div
						className="m-auto w-100 h-100 "
						style={{
							maxWidth: "550px",
						}}
						initial={{
							x: -250,
						}}
						animate={{
							x: 0,
						}}
						transition={{
							duration: 2,
							delay: 1,
							type: "tween",
						}}>
						{/* <div className="text-center py-3 py-md-5 tw-mx-auto">
            <Logo position="tw-mx-auto" />
          </div> */}
						<div
							style={{
								background: "rgba(255, 255, 255, 0.2)",
								border: "1px solid #0B2239",
								backdropFilter: "blur(7px)",
								borderRadius: "20px",
							}}
							className="border-bottom-4-color  p-3 py-md-5">
							<h2 className="text-center text-uppercase fw-bold  pb-1 text-[#0B2239]">
								Verification
							</h2>

							<p className="text-center   pb-4 text-[#0B2239]">
								To complete your registration, an OTP has been sent to your
								email address for verification. Enter OTP below to sign up
								successfully!.
							</p>

							<form onSubmit={handleSubmit}>
								<div className="tw-flex tw-flex-col tw-gap-4 tw-items-center tw-justify-center tw-mt-20 ">
									<OtpInput
										value={code}
										onChange={setCode}
										numInputs={5}
										disabled={loading}
										shouldAutoFocus={true}
										isInputNum={true}
										renderSeparator={<span> - </span>}
										renderInput={props => <input {...props} />}
										inputStyle={"tw-h-12 tw-border tw-text-xl  w-100"}
										containerStyle={{ width: "350px" }}
									/>
									{loading && <ClipLoader size={20} color="#0B2239" />}
									{/* <p className="text-muted">01:30</p> */}
								</div>
							</form>
							<p className="tw-text-base tw-font-medium tw-text-black Inter tw-text-center tw-pt-6">
								Have an account?{" "}
								<span
									onClick={() => navigate("/login")}
									className=" f tw-font-bold tw-text-sm tw-underline">
									Log in
								</span>
							</p>
						</div>
					</motion.div>
				</div>
			</div>
			<PageFooter />
		</>
	);
};

export default Activate;
