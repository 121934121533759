import React from "react";
import { MainBtn } from "../button";
import moment from "moment";

const TransactionModal = ({ handleModal, modal }) => {
	const Arr = [
		{
			name: "transaction status",
			value: modal?.statusText || "Completed",
		},
		{
			name: "Payment Provider",
			value: modal?.provider,
		},
		{
			name: "Payment mode",
			value: modal?.mode,
		},
		{
			name: "transaction date",
			value: moment(modal?.createdAt)?.format("DD-MM-YYYY"),
		},
		{
			name: "transaction time",
			value: moment(modal?.createdAt)?.format("hh:mm A"),
		},
	];
	return (
		<div>
			<div className="tw-fixed tw-z-50 tw-inset-0 tw-bg-myblue tw-bg-opacity-30 tw-flex tw-justify-center tw-h-screen tw-w-full tw-items-center">
				<div className="tw-bg-white tw-rounded-xl tw-w-80 tw-p-4">
					<h1 className="tw-text-xl bolder tw-text-secondary tw-font-bold">
						Transaction Details
					</h1>
					<div className="tw-mt-6 tw-space-y-4">
						{Arr?.map(it => (
							<div className="tw-flex tw-w-full tw-justify-between tw-items-center">
								<div>
									<small className="tw-text-sm tw-capitalize tw-font-normal satoshi tw-text-secondary">
										{it?.name}
									</small>
								</div>
								<div>
									<small
										className={`tw-text-sm tw-font-normal bolder ${
											["successful", "completed"]?.includes(
												it?.value?.toLowerCase()
											)
												? "tw-text-[#0B6C25]"
												: "tw-text-secondary"
										} tw-capitalize`}>
										{it?.value?.replace?.("_", " ")}
									</small>
								</div>
							</div>
						))}
					</div>
					<div className="tw-mt-6">
						<MainBtn text={"Okay"} onClick={handleModal} />
					</div>
				</div>
			</div>
		</div>
	);
};

export default TransactionModal;
