import React from "react";
import { Addbutton } from "../button";
import { useNavigate } from "react-router-dom";
import { IconContext } from "react-icons";
import { AiOutlinePlus } from "react-icons/ai";

const ParticipantTab = ({ datum, tab }) => {
	// const certs = [
	//   {
	//     name: "John Doe",
	//     status: "in-progress",
	//   },
	//   {
	//     name: "John Doe",
	//     status: "in-progress",
	//   },
	//   {
	//     name: "John Doe",
	//     status: "in-progress",
	//   },
	//   {
	//     name: "John Doe",
	//     status: "in-progress",
	//   },
	//   {
	//     name: "John Doe",
	//     status: "completed",
	//   },
	//   {
	//     name: "John Doe",
	//     status: "locked out",
	//   },
	// ];

	let navigate = useNavigate();

	return (
		<div>
			<div className="tw-w-full tw-space-y-4">
				{datum?.[tab === "instructors" ? "instructor" : "partakers"]?.map(
					(item, i) => (
						<div
							key={i}
							style={{
								border: "0.5px solid rgba(37, 37, 37, 0.50)",
							}}
							className="tw-px-4 tw-w-full tw-cursor-pointer tw-rounded-lg">
							<div className="tw-h-12 tw-flex tw-justify-between tw-items-center tw-w-full">
								<small className="tw-text-sm tw-font-medium tw-text-main satoshi">
									{item?.firstName} {item?.lastName}
								</small>
								<small
									className={`tw-text-xs tw-font-medium tw-capitalize ${
										item?.progress?.status === "in-progress"
											? "tw-text-secondary"
											: item?.progress?.status === "completed"
											? "tw-text-[#0B6C25]"
											: "tw-text-[#E34033]"
									} satoshi`}>
									{item?.progress?.status}
								</small>
							</div>
						</div>
					)
				)}
				<div className="tw-flex tw-items-center tw-justify-between">
					<div className="tw-flex tw-gap-4 tw-cursor-pointer">
						<Addbutton
							onClick={() =>
								navigate(`/courses/add-participant?usecase=${tab}`, {
									state: datum,
								})
							}
							text={`Add ${
								tab === "instructors" ? "Instructor" : "Student"
							} to Course`}
							icon={
								<IconContext.Provider value={{ color: "white" }}>
									<AiOutlinePlus size={20} />
								</IconContext.Provider>
							}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ParticipantTab;
