import React from "react";
import { AiOutlineClose } from "react-icons/ai";
import Video from "../../assets/video.svg";
import { useState } from "react";
import DeleteModal from "./deletemodal";
import { DeleteBtn, MainBtn } from "../button";

const ModuleModal = ({ handleClose }) => {
	const [modal, setModal] = useState("");
	return (
		<div className="tw-fixed tw-z-50 tw-inset-0 tw-bg-myblue tw-bg-opacity-30 tw-flex tw-justify-center tw-h-screen tw-w-full tw-items-center">
			<div className="lg:tw-w-1/2 tw-w-5/6 tw-mx-auto tw-p-4 tw-bg-white tw-rounded-xl">
				<div className="tw-flex tw-justify-between tw-items-center">
					<h2 className="tw-text-2xl tw-font-bold tw-text-secondary satoshi">
						Learning Technology
					</h2>
					<AiOutlineClose className="tw-cursor-pointer" onClick={handleClose} />
				</div>
				<p className="tw-text-xs satoshi tw-font-normal tw-pt-5 tw-text-main">
					You can invest in your future by are the studying with us whenever and
					wherer you want.You can invest in your future by are the studying with
					us whenever and wherer you want.You can invest in your future by are
					the studying with us whenever and wherer you want.
					<br /> You can invest in your future by are the studying with us
					whenever and wherer you want.You can invest in your future by are the
					studying with us whenever and wherer you want.
					<br />
					You can invest in your future by are the studying with us whenever and
					wherer you want.You can invest in your future by are the studying with
					us whenever and wherer you want.You can invest in your future by are
					the studying with us whene{" "}
				</p>
				<img src={Video} alt="" className="tw-my-5" />
				<div className="tw-py-5 tw-border-t tw-border-secondary tw-flex tw-gap-5">
					<MainBtn text={"Edit Overview"} />
					<DeleteBtn
						onClick={() => setModal("delete")}
						text={"Delete Course"}
					/>
				</div>
			</div>
			{modal === "delete" && (
				<DeleteModal handleCloseModal={() => setModal("")} />
			)}
		</div>
	);
};

export default ModuleModal;
