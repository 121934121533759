import { create } from "zustand";
import { persist, devtools } from "zustand/middleware";
import { DeleteData, EditData } from "./authstore";

const PaymentStore = set => ({
	data: null,
	status: "",
	allPayment: null,
	getSearchPayment: payload => {
		set(
			state => ({
				mainSearch:
					payload?.search === state?.search
						? payload?.data || payload
						: state?.mainSearch,
				isFound: true,
			}),
			false,
			"getSearchPayment"
		);
	},
	getSearch: payload => {
		set({ data: payload?.data || payload }, false, "getSearch");
	},
	resetPaymentSearch: () => {
		set({ search: "", mainSearch: null, isFound: null }, false, "getSearch");
	},
	getPayment: payload => {
		set({ data: payload?.data || payload }, false, "getPayment");
	},
	getNotifications: payload => {
		set({ notifications: payload?.data || payload }, false, "getNotifications");
	},
	getAllPayment: payload => {
		set({ allPayment: payload?.data || payload }, false, "getAllPayment");
	},
	getPaymentToEnroll: payload => {
		set({ enroll: payload?.data || payload }, false, "getPayment");
	},
	addPayment: payload => {
		let data = payload?.data || payload;

		set(
			state => ({
				data: {
					...state?.data,
					docs: state?.data?.docs ? [data, ...state?.data?.docs] : [data],
					totalDocs: state?.data?.totalDocs ? 1 + state?.data?.totalDocs : 1,
					docsTotal: state?.data?.docsTotal ? 1 + state?.data?.docsTotal : 1,
				},
				status: "added",
			}),
			false,
			"addPayment"
		);
	},
	deletePayment: payload => {
		let data = payload?.data || payload;
		set(
			state => ({
				data: {
					...state?.data,
					docs: DeleteData(state?.data?.docs, data),
					totalDocs: state?.data?.totalDocs ? state?.data?.totalDocs - 1 : 0,
					docsTotal: state?.data?.docsTotal ? state?.data?.docsTotal - 1 : 0,
				},
				status: "deleted",
			}),
			false,
			"deletePayment"
		);
	},
	updatePayment: payload => {
		let data = payload?.data || payload;
		set(
			state => ({
				data: {
					...state?.data,
					docs: EditData(state?.data?.docs, data),
				},
				status: "updated",
			}),
			false,
			"editPayment"
		);
	},
	paymentFail: () => {
		set({ status: "", isFound: null });
	},
	logoutPayment: () => {
		set({
			status: "",
			isFound: null,
			data: null,
			mainSearch: null,
			allPayment: null,
		});
	},
});

const usePaymentStore = create(
	devtools(
		persist(PaymentStore, {
			name: "payment",
		})
	)
);

export default usePaymentStore;
