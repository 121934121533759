import React from "react";
import MainContainer from "../../components/maincontainer";
import MainHeader from "../../components/mainheader";
import { SearchInput } from "../../components/inputs";
import UserTable from "../../components/tables/userstable";
import useAuthStore from "../../data/stores/authstore";
import useUserStore from "../../data/stores/userstore";
import { useState } from "react";
import { MainBtn } from "../../components/button";
import SentModal from "../../components/modal/sentmodal";
import Adminmodal from "../../components/modal/adminmodal";

export const MainStudents = () => {
	const { userPage } = useAuthStore(),
		{ data } = useUserStore();
	const [modal, setModal] = useState("");
	const [user, setUser] = useState(null);

	return (
		<div>
			<MainContainer>
				<MainHeader
					text={userPage === "institution" ? "Students" : `Staffs`}
					small={`${data?.totalDocs || 0} staffs`}
				/>
				<div className="tw-w-full tw-bg-white tw-rounded-3xl tw-p-4">
					<div className={"tw-flex tw-justify-between tw-items-center"}>
						<div className="tw-flex tw-gap-4 tw-items-center">
							<h5 className="tw-text-base tw-text-skyblue tw-font-bold satoshi">
								All {userPage === "institution" ? "Students" : `Staffs`}
							</h5>
							<SearchInput />
						</div>
						{userPage === "institution" && (
							<MainBtn
								text={"Add Student"}
								onClick={() => setModal("add-admin")}
							/>
						)}
					</div>
					<div className="tw-mt-8">
						<UserTable userPage={userPage} />
					</div>
				</div>
			</MainContainer>
			{modal === "add-admin" && (
				<Adminmodal
					handleModal={d => {
						setUser(d);
						setModal("sent");
					}}
					handleClose={() => {
						setUser(null);
						setModal("");
					}}
					privilege="student"
				/>
			)}
			{modal === "sent" && (
				<SentModal
					handleClose={() => {
						setUser(null);
						setModal("");
					}}
					user={user}
					privilege="student"
				/>
			)}
		</div>
	);
};

const Students = () => <MainStudents />;

export default Students;
