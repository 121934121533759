import React, { useEffect, useState } from "react";
import { ModalContainer } from "./creategroupmodal";
import { TextInput } from "../inputs";
import { Button } from "../button";
import { useLocation } from "react-router-dom";
import { useGroupsStore } from "../../data/stores/loggerstore";
import useErrorStore from "../../data/stores/errorstore";
import { toast } from "react-toastify";
import { apiCall } from "../../data/stores/authstore";

const AddMemberModal = ({ handleClose, name, title, item }) => {
	let [state, setState] = useState({}),
		[members, setMembers] = useState(null),
		[instructors, setInstructors] = useState(null),
		items = useLocation()?.state,
		textChange = ({ target: { value, name } }) => {
			setState({ ...state, [name]: value });
		},
		[loading, setLoading] = useState(null),
		{ updateLogger } = useGroupsStore(),
		{ returnErrors } = useErrorStore(),
		handleSubmit = async e => {
			e?.preventDefault();
			let newState = state;
			if (name === "members")
				newState.members = members?.docs
					?.filter(ii => ii?.isChecked)
					?.map(ic => ic?.email);
			if (name === "instructors")
				newState.instructors = instructors?.docs
					?.filter(ii => ii?.isChecked)
					?.map(ic => ic?.email);

			if (newState?.[name]?.length === 0 || !newState?.[name])
				return toast.info(`${title} is required`);
			console.log({ newState });
			setLoading(true);
			let { response, errArr, errMsg } = await apiCall(
				"put",
				`/api/v1/groups?group=${item?._id || items?._id}`,
				newState
			);
			console?.log({ response, errArr, errMsg });
			if (errArr) {
				setLoading(false);
				return returnErrors(errArr);
			}
			if (errMsg) {
				setLoading(false);
				return toast.error(errMsg);
			}
			setLoading(false);
			if (response) {
				updateLogger(response);
				setState(null);
				handleClose();
				return;
			}
			setLoading(false);
		};

	useEffect(() => {
		if (name === "members")
			apiCall(
				"get",
				`/api/v1/user/manage-users?privilege=student&pagination=not`,
				null,
				data => setMembers(data?.data || data)
			);
		if (name === "instructors")
			apiCall(
				"get",
				`/api/v1/user/manage-users?privilege=instructor&pagination=not`,
				null,
				data => setInstructors(data?.data || data)
			);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [name]);

	let InsiderDispay = ({ x, it, setDatum }) => {
		return (
			<div
				key={x}
				className="tw-flex tw-border-b tw-pb-2 tw-justify-between tw-items-center">
				<div className="tw-flex tw-gap-4">
					<input
						onChange={e => {
							setDatum(prev => {
								let prevData = { ...prev },
									dd = prevData?.docs,
									current = dd?.find(ix => ix?.email === it?.email);
								if (e?.target?.checked) {
									current.isChecked = true;
								} else {
									current.isChecked = false;
								}
								dd = dd?.map(ic => (ic?.email === it?.email ? current : ic));
								prevData.docs = dd;
								return prevData;
							});
						}}
						type="checkbox"
						checked={it?.isChecked}
						className="tw-h-4 tw-w-4 tw-border"
						id={it?._id}
					/>
					<label
						className="tw-text-sm tw-text-main satoshi tw-font-normal"
						htmlFor={it?._id}>
						{it?.firstName} {it?.lastName}
					</label>
				</div>
				<h6 className="tw-text-sm first-letter:tw-uppercase tw-text-main satoshi tw-font-normal">
					{it?.dept}
				</h6>
			</div>
		);
	};

	return (
		<div>
			<ModalContainer handleClose={handleClose}>
				<div className="tw-bg-white tw-p-5">
					<h1 className="tw-text-xl tw-font-medium satoshi tw-text-secondary">
						Add {title || "Member"}
					</h1>
					<form action="" className="tw-mt-6">
						{name === "title" && (
							<TextInput
								label={"Name of Group"}
								onChange={textChange}
								value={state?.title || item?.title || items?.title}
								name="title"
							/>
						)}
						{name === "instructors" && (
							<>
								{/* <TextInput
									label={"Add Supervisor"}
									onChange={textChange}
									value={state?.instructors}
									name="instructors"
								/> */}
								<p className="tw-text-base tw-font-medium satoshi tw-text-secondary">
									{"Add Supervisor"}
								</p>
								<div className="tw-space-y-4 tw-mt-5">
									{instructors?.docs?.map((it, x) => (
										<InsiderDispay x={x} it={it} setDatum={setInstructors} />
									))}
								</div>
							</>
						)}
						{name === "members" && (
							<>
								{/* <TextInput
									label={"Add Member"}
									onChange={textChange}
									value={state?.members}
									name="members"
								/> */}
								<p className="tw-text-base tw-font-medium satoshi tw-text-secondary">
									{"Add Member"}
								</p>
								<div className="tw-space-y-4 tw-mt-5">
									{members?.docs?.map((it, x) => (
										<InsiderDispay x={x} it={it} setDatum={setMembers} />
									))}
								</div>
							</>
						)}
						<div className="tw-mt-8">
							<Button onClick={handleSubmit} loading={loading} type="button">
								Add {title || "Member"}
							</Button>
						</div>
					</form>
				</div>
			</ModalContainer>
		</div>
	);
};

export const AddInstructorModal = ({ handleClose }) => {
	return (
		<div>
			<ModalContainer handleClose={handleClose}>
				<div className="tw-bg-white tw-p-5">
					<h1 className="tw-text-xl tw-font-medium satoshi tw-text-secondary">
						Add Instructor
					</h1>
					<form action="" className="tw-mt-6">
						<TextInput
							label={"Name of Instructor"}
							placeholder={"Search name of member"}
						/>
						<div className="tw-mt-8">
							<Button>Add Instructor</Button>
						</div>
					</form>
				</div>
			</ModalContainer>
		</div>
	);
};
export default AddMemberModal;
