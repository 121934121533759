import React from "react";
import { useState } from "react";
import useSessionStore from "../../data/stores/sessionstore";
import { useEffect } from "react";
import { apiCall } from "../../data/stores/authstore";
import { MainPaginate } from "../button";
import moment from "moment";

const SessionTable = () => {
	const headers = [
			"session name",
			"date",
			"time",
			"invitees",
			"Meeting Invite",
		],
		data = [
			{
				name: "safety at work",
				date: "25-02-2034",
				time: "8pm - 0pm",
				invitees: "34",
				google_invite:
					"kkkajfkdsj kjfaksdjfkl sdfkjsd fkajd kkjklkljkljkljkjkjkjkjkjkjklj",
			},
			{
				name: "safety at work",
				date: "25-02-2034",
				time: "8pm - 0pm",
				invitees: "34",
				google_invite: "kkkajfkdsj kjfaksdjfkl sdfkjsd fkajd k",
			},
			{
				name: "safety at work",
				date: "25-02-2034",
				time: "8pm - 0pm",
				invitees: "34",
				google_invite: "kkkajfkdsj kjfaksdjfkl sdfkjsd fkajd k",
			},
		];

	let [datum, setDatum] = useState({ docs: data }),
		{ isFound, mainSearch, institutionPending, getInstitutionPendingSession } =
			useSessionStore();

	useEffect(() => {
		if (isFound) setDatum(mainSearch);
		else setDatum(institutionPending);
	}, [institutionPending, isFound, mainSearch]);

	useEffect(() => {
		apiCall(
			"get",
			`/api/v1/session?status=pending`,
			null,
			getInstitutionPendingSession
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	const [itemOffset, setItemOffset] = useState(0);
	let [range] = useState(10);
	const endOffset = itemOffset + range;

	// if (!datum && !data) return <PageLoader />;
	if (!datum) return;

	const currentItems = datum?.docs?.slice(itemOffset, endOffset);
	const pageCount = Math.ceil(datum?.docs?.length / range);

	const handlePageClick = event => {
		const newOffset = (event.selected * range) % datum?.docs?.length;
		setItemOffset(newOffset);
	};

	return (
		<div className={"tw-overflow-x-auto"}>
			<table className="tw-min-w-full tw-min-h-full tw-table-auto">
				<thead>
					<tr>
						{headers?.map(item => (
							<th className="tw-text-ics-blue tw-p-3 tw-font-medium satoshi tw-text-base tw-capitalize">
								{item}
							</th>
						))}
					</tr>
				</thead>
				<tbody>
					{currentItems?.map((item, i) => (
						<tr
							style={{
								borderBottom: "1px solid rgba(1, 52, 103, 0.30)",
							}}
							key={i}>
							<td className="tableText">{item?.title}</td>
							<td className="tableText">
								{item?.date ? moment(item?.date).format("DD-MM-YYYY") : ""}
							</td>
							<td className="tableText">
								{item?.time ? moment(item?.time, "HH:mm:ss")?.format("LT") : ""}
							</td>
							<td className="tableText">{item?.recipient?.length}</td>
							<td className="tableText">
								<div className={"tw-w-24"}>
									<span className="tw-truncate tw-w-full">
										{item?.link ? (
											<span
												className="tw-underline tw-cursor-pointer"
												onClick={() => window.open(item?.link, "_blank")}>
												Meeting Link
											</span>
										) : null}
									</span>
								</div>
							</td>
						</tr>
					))}
				</tbody>
				{pageCount > 1 && (
					<MainPaginate
						pageCount={pageCount}
						handlePageClick={handlePageClick}
					/>
				)}
			</table>
		</div>
	);
};

export const PastSessionTable = () => {
	const headers = ["session name", "date joined", "duration", "participant"],
		data = [
			{
				name: "safety at work",
				date: "25-02-2034",
				duration: "4 Hours",
				participant: "34",
			},
			{
				name: "safety at work",
				date: "25-02-2034",
				duration: "4 Hours",
				participant: "34",
			},
			{
				name: "safety at work",
				date: "25-02-2034",
				duration: "4 Hours",
				participant: "34",
			},
			{
				name: "safety at work",
				date: "25-02-2034",
				duration: "4 Hours",
				participant: "34",
			},
		];

	let [datum, setDatum] = useState({ docs: data }),
		{ isFound, mainSearch, institutionPast, getInstitutionPastSession } =
			useSessionStore();

	useEffect(() => {
		if (isFound) setDatum(mainSearch);
		else setDatum(institutionPast);
	}, [institutionPast, isFound, mainSearch]);

	useEffect(() => {
		apiCall(
			"get",
			`/api/v1/session?status=past`,
			null,
			getInstitutionPastSession
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	const [itemOffset, setItemOffset] = useState(0);
	let [range] = useState(10);
	const endOffset = itemOffset + range;

	// if (!datum && !data) return <PageLoader />;
	if (!datum) return;

	const currentItems = datum?.docs?.slice(itemOffset, endOffset);
	const pageCount = Math.ceil(datum?.docs?.length / range);

	const handlePageClick = event => {
		const newOffset = (event.selected * range) % datum?.docs?.length;
		setItemOffset(newOffset);
	};

	return (
		<div className={"tw-overflow-x-auto"}>
			<table className="tw-min-w-full tw-min-h-full tw-table-auto">
				<thead>
					<tr>
						{headers?.map(item => (
							<th className="tw-text-ics-blue tw-p-3 tw-font-medium satoshi tw-text-base tw-capitalize">
								{item}
							</th>
						))}
					</tr>
				</thead>
				<tbody>
					{currentItems?.map((item, i) => (
						<tr
							style={{
								borderBottom: "1px solid rgba(1, 52, 103, 0.30)",
							}}
							key={i}>
							<td className="tableText">{item?.title}</td>
							<td className="tableText">
								{item?.date ? moment(item?.date).format("DD-MM-YYYY") : ""}
							</td>
							<td className="tableText">{item?.duration} Hour(s)</td>
							<td className="tableText">{item?.recipient?.length}</td>
						</tr>
					))}
				</tbody>
				{pageCount > 1 && (
					<MainPaginate
						pageCount={pageCount}
						handlePageClick={handlePageClick}
					/>
				)}
			</table>
		</div>
	);
};

export default SessionTable