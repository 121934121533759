/* eslint-disable no-undef */
import {createElement, useContext, useEffect} from "react";
import {useNavigate, useParams} from "react-router-dom";
import useAuthStore from "./data/stores/authstore";
import useErrorStore from "./data/stores/errorstore";
import {GlobalState} from "./data/Context";

const GeneratePage = (pageName, folder) => {
	const component = () => require(`./${folder}/${pageName}`).default;
	let navigate = useNavigate();
	try {
		return createElement(component());
	} catch (e) {
		console.log(e);
		navigate("/");
	}
};

const PageRender = () => {
	const { isAuth, userPage } = useAuthStore(),
		{ errorText, clearErrors } = useErrorStore(),
		{ sidebarList } = useContext(GlobalState),
		{ page, id, step } = useParams(),
		escape2 = [
			"quiz",
			"leadership",
			"humanresources",
			"details",
			"add-course",
			"manage-course",
			"add-participant",
			"completion-rate",
			"assessment",
			"progress",
		],
		navigate = useNavigate(),
		lister = sidebarList?.map(it => it?.url?.replace("/", ""));

	// console.log({ lister });

	useEffect(() => {
		if (isAuth) {
			if (
				["register", "home", "create-account", "login", "activate"]?.includes(
					page
				)
			) {
				navigate("/");
			}
		}
		if (!isAuth) {
			if (errorText) {
				if (lister?.includes(page)) {
					navigate("/");
				}
				clearErrors();
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page, isAuth, navigate, errorText]);

	let pageName = "";
	if (step) {
		pageName = `${page}/${id}/${"[id]"}`;
	} else if (id) {
		if (
			(page === "courses" && escape2.includes(id)) ||
			(page === "events" && escape2.includes(id)) ||
			(page === "reports" && escape2.includes(id))
		) {
			pageName = `${page}/${id}`;
		} else {
			pageName = `${page}/${"[id]"}`;
		}
	} else {
		pageName = `${page}`;
	}
	return GeneratePage(
		pageName,
		isAuth && userPage === "institution"
			? "institution"
			: isAuth && userPage === "company"
			? "views"
			: isAuth && userPage === "instructor"
			? "instructor"
			: isAuth
			? "pages"
			: "screens"
	);
};

export default PageRender;
