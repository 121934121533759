import MainContainer from "../../components/maincontainer";
import MainHeader from "../../components/mainheader";
import {SearchInput} from "../../components/inputs";
import {MainBtn} from "../../components/button";
import React, { useEffect, useState } from "react";
import Adminstable from "../../components/tables/adminstable";
import AddAdminmodal from "../../components/modal/addadminmodal";
import Adminmodal from "../../components/modal/adminmodal";
import SentModal from "../../components/modal/sentmodal";
import useAuthStore from "../../data/stores/authstore";
import { useNavigate } from "react-router-dom";

const Admins = () => {
	const [modal, setModal] = useState("");
	const [user, setUser] = useState(null);
	const users = useAuthStore(),
		navigate = useNavigate();

	useEffect(() => {
		if (users?.user?.privilege !== "institution") navigate(-1);
	}, [users?.user, navigate]);

	return (
		<div>
			<MainContainer>
				<MainHeader text={"Admins"} small={"All Admins"} />
				<div className="tw-bg-white tw-p-6 tw-rounded-xl">
					<div className={"tw-flex tw-justify-between tw-items-center"}>
						<div className="tw-flex tw-items-center tw-gap-6">
							<h2 className="tw-text-skyblue tw-font-bold tw-text-xl satoshi">
								All Admins
							</h2>
							<SearchInput />
						</div>
						<MainBtn text={"Add Admin"} onClick={() => setModal("add-admin")} />
					</div>
					<div className={"tw-mt-10"}>
						<Adminstable />
					</div>
				</div>
				{modal === "add" && <AddAdminmodal handleClose={() => setModal("")} />}
			</MainContainer>
			{modal === "add-admin" && (
				<Adminmodal
					handleModal={d => {
						setUser(d);
						setModal("sent");
					}}
					handleClose={() => {
						setUser(null);
						setModal("");
					}}
					privilege="institution-admin"
				/>
			)}
			{modal === "sent" && (
				<SentModal
					handleClose={() => {
						setUser(null);
						setModal("");
					}}
					user={user}
					privilege="institution-admin"
				/>
			)}
		</div>
	);
};

export default Admins