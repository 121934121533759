import { BsInstagram, BsLinkedin } from "react-icons/bs";
import { FaFacebookF } from "react-icons/fa";
import { Link } from "react-router-dom";
import { LogoDark } from "./pageheader";

const PageFooter = () => {
  return (
    <div className="tw-py-8 tw-bg-[#0B2239] tw-relative">
      <div className="tw-px-8 container text-white">
        <div className="tw-grid lg:tw-grid-cols-5 tw-gap-6">
          <div className="">
            <LogoDark />
          </div>
          <div className="tw-w-full">
            <h5 className="tw-text-sm tw-font-bold tw-text-white satoshi">
              ICS Limited
            </h5>
            <ul className="list-group tw-mt-2 tw-text-white satoshi tw-text-sm tw-font-normal list-unstyled">
              <li className="list-group-item bg-transparent text-white border-0 px-0">
                About Us
              </li>
              <li className="list-group-item bg-transparent text-white border-0 px-0">
                FAQ
              </li>
              <li className="list-group-item bg-transparent text-white border-0 px-0">
                Terms
              </li>
            </ul>
          </div>
          <div className="">
            <h5 className="tw-text-sm tw-font-bold tw-text-white satoshi">
              Popular Course
            </h5>
            <ul className="list-group tw-mt-2 tw-text-white satoshi tw-text-sm tw-font-normal list-unstyled">
              <li className="list-group-item bg-transparent text-white border-0 px-0">
                Leadership
              </li>
              <li className="list-group-item bg-transparent text-white border-0 px-0">
                Human Resouces
              </li>
              <li className="list-group-item bg-transparent text-white border-0 px-0">
                Business Administration
              </li>
            </ul>
          </div>
          <div className="">
            <h5 className="tw-text-sm tw-font-bold tw-text-white satoshi">
              Legal
            </h5>
            <ul className="list-group tw-mt-2 tw-text-white satoshi tw-text-sm tw-font-normal list-unstyled">
              <li className="list-group-item bg-transparent text-white border-0 px-0 d-flex align-items-center">
                Terms
              </li>
              <li className="list-group-item bg-transparent text-white border-0 px-0 d-flex align-items-center">
                Privacy
              </li>
              <li className="list-group-item bg-transparent text-white border-0 px-0 d-flex align-items-center">
                Security
              </li>
            </ul>
          </div>
          <div className="">
            <div className="d-flex tw-justify-end tw-mb-5">
              <div
                className="rounded-circle d-flex align-items-center justify-content-center mx-2"
                style={{
                  height: "2.5rem",
                  width: "2.5rem",
                  background: "white",
                }}
              >
                <Link
                  target="_blank"
                  to={"https://www.facebook.com/icsoutsourcinglimited/"}
                >
                  <FaFacebookF size={20} className="text-main" />
                </Link>
              </div>

              <div
                className="rounded-circle d-flex align-items-center justify-content-center mx-2"
                style={{
                  height: "2.5rem",
                  width: "2.5rem",
                  background: "white",
                }}
              >
                <Link
                  target="_blank"
                  to={
                    "https://www.instagram.com/icssourcing_academy?igsh=MTVsYnJham51dno2ZA=="
                  }
                >
                  <BsInstagram size={20} className="text-main" />
                </Link>
              </div>
              <div
                className="rounded-circle d-flex align-items-center justify-content-center mx-2"
                style={{
                  height: "2.5rem",
                  width: "2.5rem",
                  background: "white",
                }}
              >
                <Link
                  target="_blank"
                  to={"https://www.linkedin.com/company/ics-outsourcing/about/"}
                >
                  <BsLinkedin size={20} className="text-main" />
                </Link>
              </div>
            </div>
            <p>
              6, Olusoji Idowu Street,
              <br /> Ilupeju, Obanikoro Bus-Stop,
              <br /> Lagos.
            </p>
          </div>
        </div>
        <hr className="border" />
        <div className="d-md-flex  justify-content-center align-items-center py-3 text-white">
          <div> &copy; @ICS Sourcing Academy</div>
        </div>
      </div>
    </div>
  );
};

export default PageFooter;
