import { create } from "zustand";
import { persist, devtools } from "zustand/middleware";
import { DeleteData, EditData } from "./authstore";

const SessionStore = set => ({
	data: null,
	status: "",
	allSession: null,
	getSearchSession: payload => {
		set(
			state => ({
				mainSearch:
					payload?.search === state?.search
						? payload?.data || payload
						: state?.mainSearch,
				isFound: true,
			}),
			false,
			"getSearchSession"
		);
	},
	getSearch: payload => {
		set({ data: payload?.data || payload }, false, "getSearch");
	},
	resetSessionSearch: () => {
		set({ search: "", mainSearch: null, isFound: null }, false, "getSearch");
	},
	getSession: payload => {
		set({ data: payload?.data || payload }, false, "getSession");
	},
	getInstitutionSession: payload => {
		set(
			{ institution: payload?.data || payload },
			false,
			"getInstitutionSession"
		);
	},
	getInstitutionPendingSession: payload => {
		set(
			{ institutionPending: payload?.data || payload },
			false,
			"getInstitutionPendingSession"
		);
	},
	getInstitutionPastSession: payload => {
		set(
			{ institutionPast: payload?.data || payload },
			false,
			"getInstitutionPastSession"
		);
	},
	getAllSession: payload => {
		set({ allSession: payload?.data || payload }, false, "getAllSession");
	},
	getSessionToEnroll: payload => {
		set({ enroll: payload?.data || payload }, false, "getSession");
	},
	addSession: payload => {
		let data = payload?.data || payload;

		set(
			state => ({
				institution: {
					...state?.institution,
					docs: state?.institution?.docs
						? [data, ...state?.institution?.docs]
						: [data],
					totalDocs: state?.institution?.totalDocs
						? 1 + state?.institution?.totalDocs
						: 1,
					docsTotal: state?.institution?.docsTotal
						? 1 + state?.institution?.docsTotal
						: 1,
				},
				institutionPending: {
					...state?.institutionPending,
					docs: state?.institutionPending?.docs
						? [data, ...state?.institutionPending?.docs]
						: [data],
					totalDocs: state?.institutionPending?.totalDocs
						? 1 + state?.institutionPending?.totalDocs
						: 1,
					docsTotal: state?.institutionPending?.docsTotal
						? 1 + state?.institutionPending?.docsTotal
						: 1,
				},
				status: "added",
			}),
			false,
			"addSession"
		);
	},
	deleteSession: payload => {
		let data = payload?.data || payload;
		set(
			state => ({
				institution: {
					...state?.institution,
					docs: DeleteData(state?.institution?.docs, data),
					totalDocs: state?.institution?.totalDocs
						? state?.institution?.totalDocs - 1
						: 0,
					docsTotal: state?.institution?.docsTotal
						? state?.institution?.docsTotal - 1
						: 0,
				},
				status: "deleted",
			}),
			false,
			"deleteSession"
		);
	},
	updateSession: payload => {
		let data = payload?.data || payload;
		set(
			state => ({
				institution: {
					...state?.institution,
					docs: EditData(state?.institution?.docs, data),
				},
				status: "updated",
			}),
			false,
			"editSession"
		);
	},
	subjectFail: () => {
		set({ status: "", isFound: null });
	},
	logoutSession: () => {
		set({
			status: "",
			isFound: null,
			data: null,
			mainSearch: null,
			allSession: null,
		});
	},
});

const useSessionStore = create(
	devtools(
		persist(SessionStore, {
			name: "session",
		})
	)
);

export default useSessionStore;
