import React from "react";
import MainContainer from "../../components/maincontainer";
import MainHeader from "../../components/mainheader";
import { OngoingCouses } from "../dashboard";
import { CourseDashTable } from "../../components/tables/userstable";

const Courses = () => {
	return (
		<div>
			<MainContainer>
				<MainHeader />
				<>
					<OngoingCouses
						title={"Ongoing Courses"}
						subtitle={<CourseDashTable />}
						type="in-progress"
					/>
					<OngoingCouses
						title={"Completed Courses"}
						noApi
						subtitle={<CourseDashTable type={"completed"} />}
						type="completed"
					/>
					<OngoingCouses
						title={"Locked Out Courses"}
						noApi
						subtitle={<CourseDashTable type={"lock-out"} />}
						type="lock-out"
					/>
				</>
			</MainContainer>
		</div>
	);
};

export default Courses;
