import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import { MainPaginate, Tablebtn } from "../button";
import TransactionModal from "../modal/transactionmodal";
import useAuthStore, { apiCall } from "../../data/stores/authstore";
import usePaymentStore from "../../data/stores/paymentstore";
import { GlobalState } from "../../data/Context";

const PaymentTable = () => {
	const [modal, setModal] = useState(false);
	const { userPage } = useAuthStore();
	const { nairaSign, numberWithCommas } = useContext(GlobalState);

	let Headers = [
			"Amount",
			"Transaction Date",
			"course title",
			"Transaction Status",
			"action",
		],
		data1 = [
			{
				amount: "23,931",
				date: "07 May, 2023",
				no_of_courses: "4",
				status: "completed",
			},
			{
				amount: "23,931",
				date: "07 May, 2023",
				no_of_courses: "4",
				status: "failed",
			},
			{
				amount: "23,931",
				date: "07 May, 2023",
				no_of_courses: "4",
				status: "pending",
			},
			{
				amount: "23,931",
				date: "07 May, 2023",
				no_of_courses: "4",
				status: "completed",
			},
		];

	if (userPage === "company") {
		Headers = [
			"Amount",
			"Transaction Date",
			"course title",
			"Number of Users",
			"Transaction Status",
			"action",
		];
	}

	let [datum, setDatum] = useState({ docs: data1 }),
		{ isFound, mainSearch, data, getPayment, getAllPayment } =
			usePaymentStore();

	useEffect(() => {
		if (isFound) setDatum(mainSearch);
		else setDatum(data);
	}, [data, isFound, mainSearch]);

	useEffect(() => {
		apiCall(
			"get",
			`/api/v1/transactions?pagination=not${
				userPage === "company" ? `&company=company` : ""
			}`,
			null,
			getAllPayment
		);
		apiCall(
			"get",
			`/api/v1/transactions${userPage === "company" ? `?company=company` : ""}`,
			null,
			getPayment
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userPage]);

	const [itemOffset, setItemOffset] = useState(0);
	let [range] = useState(10);
	const endOffset = itemOffset + range;

	// if (!datum && !data) return <PageLoader />;
	if (!datum) return;

	const currentItems = datum?.docs?.slice(itemOffset, endOffset);
	const pageCount = Math.ceil(datum?.docs?.length / range);

	const handlePageClick = event => {
		const newOffset = (event.selected * range) % datum?.docs?.length;
		setItemOffset(newOffset);
	};

	return (
		<div className=" tw-overflow-x-auto">
			<table className="tw-min-w-full tw-min-h-full tw-table-auto">
				<thead>
					<tr>
						{Headers?.map(item => (
							<th className="tw-text-secondary tw-p-3 tw-font-medium bolder tw-text-sm tw-capitalize">
								{item}
							</th>
						))}
					</tr>
				</thead>
				<tbody>
					{currentItems?.map((item, i) => (
						<tr
							style={{
								borderBottom: "1px solid rgba(1, 52, 103, 0.30)",
							}}
							key={i}
							className="">
							<td className="tableText">
								{nairaSign} {numberWithCommas(Number(item?.amount).toFixed(2))}
							</td>
							<td className="tableText">
								{moment(item?.createdAt).format("DD MMMM, YYYY")}
							</td>
							<td className="tableText">{item?.course?.title}</td>
							{userPage === "company" && (
								<td className="tableText">{item?.partakers?.length}</td>
							)}
							<td
								className={`tw-text-sm ${
									item?.status
										? "tw-text-[#0B6C25]"
										: item?.status === "failed"
										? "tw-text-[#E34033]"
										: "tw-text-[#F2B605]"
								} tw-p-3 tw-font-normal tw-capitalize`}>
								{item?.statusText}
							</td>
							<td>
								<div className="">
									<Tablebtn onClick={() => setModal(item)} text={"View"} />
								</div>
							</td>
						</tr>
					))}
				</tbody>
				{pageCount > 1 && (
					<MainPaginate
						pageCount={pageCount}
						handlePageClick={handlePageClick}
					/>
				)}
			</table>
			{modal && (
				<TransactionModal handleModal={() => setModal(false)} modal={modal} />
			)}
		</div>
	);
};

export default PaymentTable;
