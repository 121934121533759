import axios from "axios";
import { SetAuthToken } from "./Config";
import useAuthStore, { TOKEN, apiCall } from "./stores/authstore";
import { toast } from "react-toastify";
import useErrorStore from "./stores/errorstore";
import useCourseStore from "./stores/coursestore";
import usePlanStore from "./stores/planstore";
import useUserStore from "./stores/userstore";
import usePaymentStore from "./stores/paymentstore";
import useSessionStore from "./stores/sessionstore";
import useTransactionsStore from "./stores/transactionstore";

const useGenFetcher = () => {
	let { getErrorText, clearErrors } = useErrorStore(),
		{ getUser, getUserFail, getUserLoading, userPage } = useAuthStore(),
		{ getCourse, getCourseToEnroll, getAllCourse, getInstitutionCourse } =
			useCourseStore(),
		{ getPlan } = usePlanStore(),
		{ getInstitutionPendingSession, getInstitutionPastSession } =
			useSessionStore(),
		{ getNotifications } = usePaymentStore(),
		{ getAllTransactionss, getTransactionss } = useTransactionsStore(),
		{ getUsers, getAllUsers, getAdmins, getInstructors } = useUserStore(),
		loadUser = async () => {
			let token = localStorage.getItem(TOKEN);
			if (token) SetAuthToken(token);

			getUserLoading();
			clearErrors();
			try {
				let res = await axios.get(`/api/v1/user`);
				if (res?.data) {
					getUser(res.data);
					if (!["staff", "company"]?.includes(res?.data?.data?.privilege)) {
						apiCall(
							"get",
							`/api/v1/course?institution=${res?.data?.data?.organisation}`,
							null,
							getInstitutionCourse
						);
						apiCall(
							"get",
							`/api/v1/session?status=pending`,
							null,
							getInstitutionPendingSession
						);
						apiCall(
							"get",
							`/api/v1/session?status=past`,
							null,
							getInstitutionPastSession
						);
					}
					if (["institution"]?.includes(res?.data?.data?.privilege)) {
						apiCall(
							"get",
							`/api/v1/user/manage-users?privilege=institution-admin`,
							null,
							getAdmins
						);
					}
					if (
						["institution", "institution-admin"]?.includes(
							res?.data?.data?.privilege
						)
					) {
						apiCall(
							"get",
							`/api/v1/user/manage-users?privilege=instructor`,
							null,
							getInstructors
						);
						apiCall("get", `/api/v1/transactions`, null, getTransactionss);
						apiCall(
							"get",
							`/api/v1/transactions?pagination=not`,
							null,
							getAllTransactionss
						);
					}
					if (
						["company", "institution", "institution-admin"]?.includes(
							res?.data?.data?.privilege
						)
					) {
						apiCall(
							"get",
							`/api/v1/user/manage-users?privilege=${
								res?.data?.data?.privilege === "company" ? "staff" : "student"
							}`,
							null,
							getUsers
						);
						apiCall(
							"get",
							`/api/v1/user/manage-users?privilege=${
								res?.data?.data?.privilege === "company" ? "staff" : "student"
							}&pagination=not`,
							null,
							getAllUsers
						);
					}
					apiCall(
						"get",
						`/api/v1/course${
							userPage !== "student" ? `?company=${userPage}` : ""
						}`,
						null,
						getCourse
					);
					apiCall(
						"get",
						`/api/v1/course?pagination=not${
							userPage !== "student" ? `&company=${userPage}` : ""
						}`,
						null,
						getAllCourse
					);
					apiCall(
						"get",
						`/api/v1/course?enroll=enroll${
							userPage !== "student" ? `&company=${userPage}` : ""
						}`,
						null,
						getCourseToEnroll
					);
					apiCall("get", "/api/v1/plan", null, getPlan);
					apiCall(
						"get",
						"/api/v1/notification?type=incoming",
						null,
						getNotifications
					);
				} else {
					getUserFail();
					getErrorText("Access denied");
				}
			} catch (err) {
				if (err) console.log({ error: err.response?.data, err });
				if (err?.response?.status === 429) toast.error(err?.response?.data);
				getUserFail();
				getErrorText(
					err?.response?.data?.message ||
						err?.response?.data?.error?.[0]?.message ||
						""
				);
			}
		};
	return { loadUser };
};

export default useGenFetcher;
