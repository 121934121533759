import { create } from "zustand";
import { persist, devtools } from "zustand/middleware";
import { DeleteData, EditData } from "./authstore";

const CourseStore = set => ({
	data: null,
	status: "",
	allCourse: null,
	getSearchCourse: payload => {
		set(
			state => ({
				mainSearch:
					payload?.search === state?.search
						? payload?.data || payload
						: state?.mainSearch,
				isFound: true,
			}),
			false,
			"getSearchCourse"
		);
	},
	getSearch: payload => {
		set({ data: payload?.data || payload }, false, "getSearch");
	},
	resetCourseSearch: () => {
		set({ search: "", mainSearch: null, isFound: null }, false, "getSearch");
	},
	getCourse: payload => {
		set({ data: payload?.data || payload }, false, "getCourse");
	},
	getInstitutionCourse: payload => {
		set(
			{ institution: payload?.data || payload },
			false,
			"getInstitutionCourse"
		);
	},
	getAllCourse: payload => {
		set({ allCourse: payload?.data || payload }, false, "getAllCourse");
	},
	getCourseToEnroll: payload => {
		set({ enroll: payload?.data || payload }, false, "getCourse");
	},
	addCourse: payload => {
		let data = payload?.data || payload;

		set(
			state => ({
				institution: {
					...state?.institution,
					docs: state?.institution?.docs
						? [data, ...state?.institution?.docs]
						: [data],
					totalDocs: state?.institution?.totalDocs
						? 1 + state?.institution?.totalDocs
						: 1,
					docsTotal: state?.institution?.docsTotal
						? 1 + state?.institution?.docsTotal
						: 1,
				},
				status: "added",
			}),
			false,
			"addCourse"
		);
	},
	deleteCourse: payload => {
		let data = payload?.data || payload;
		set(
			state => ({
				institution: {
					...state?.institution,
					docs: DeleteData(state?.institution?.docs, data),
					totalDocs: state?.institution?.totalDocs
						? state?.institution?.totalDocs - 1
						: 0,
					docsTotal: state?.institution?.docsTotal
						? state?.institution?.docsTotal - 1
						: 0,
				},
				status: "deleted",
			}),
			false,
			"deleteCourse"
		);
	},
	updateCourse: payload => {
		let data = payload?.data || payload;
		set(
			state => ({
				institution: {
					...state?.institution,
					docs: EditData(state?.institution?.docs, data),
				},
				status: "updated",
			}),
			false,
			"editCourse"
		);
	},
	subjectFail: () => {
		set({ status: "", isFound: null });
	},
	logoutCourse: () => {
		set({
			status: "",
			isFound: null,
			data: null,
			mainSearch: null,
			allCourse: null,
		});
	},
});

const useCourseStore = create(
	devtools(
		persist(CourseStore, {
			name: "course",
		})
	)
);

export default useCourseStore;
