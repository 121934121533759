import React, { useState, useEffect } from "react";
import MainContainer from "../../components/maincontainer";
import MainHeader from "../../components/mainheader";
import { SearchInput } from "../../components/inputs";
import { useNavigate } from "react-router-dom";
import Play from "../../assets/play.svg";
import Duration from "../../assets/duration.svg";
import Videos from "../../assets/videos.svg";
import AllcoursesImg from "../../assets/allcourses.png";
import useCourseStore from "../../data/stores/coursestore";
import useAuthStore, { apiCall } from "../../data/stores/authstore";
import { MainBtn, MainPaginate } from "../../components/button";
import { ContentWriteup } from "../../pages/courses/[id]";

export const Arr3 = [
	{
		img: AllcoursesImg,
		title: "leadership",
		videos: "4",
		duration: "23",
		desc: "You can invest in your future by are the studying with us whenever and wherer you want.",
	},
	{
		img: AllcoursesImg,
		title: "leadership",
		videos: "4",
		duration: "23",
		desc: "You can invest in your future by are the studying with us whenever and wherer you want.",
	},
	{
		img: AllcoursesImg,
		title: "leadership",
		videos: "4",
		duration: "23",
		desc: "You can invest in your future by are the studying with us whenever and wherer you want.",
	},
	{
		img: AllcoursesImg,
		title: "leadership",
		videos: "4",
		duration: "23",
		desc: "You can invest in your future by are the studying with us whenever and wherer you want.",
	},
];

export const MainCourses = () => {
	const { userPage } = useAuthStore();
	return (
		<div>
			<MainContainer>
				<MainHeader text={"Courses"} small={"34 Courses"} />
				{userPage === "institution" && (
					<OngoingCouses addCourse={"institution"} />
				)}
				<OngoingCouses institutionCourse={userPage === "institution"} />
				<EnrollCouses institutionCourse={userPage === "institution"} />
			</MainContainer>
		</div>
	);
};

export const CourseCard = ({ Img, title, desc, onClick }) => {
	return (
		<div
			style={{
				border: "0.5px solid rgba(1, 52, 103, 0.30)",
			}}
			className="tw-h-80 tw-relative tw-bg-white tw-rounded-xl tw-w-full">
			<img src={Img} alt="" className="tw-rounded-t-2xl tw-w-full tw-h-36" />
			<div className="tw-p-4">
				<h5 className="tw-font-medium tw-text-secondary tw-text-base satoshi">
					{title}
				</h5>
				<p className="satoshi tw-text-xs tw-font-normal tw-text-main">{desc}</p>
				<h6
					onClick={onClick}
					className="tw-text-sm tw-absolute tw-bottom-2 tw-font-medium satoshi tw-text-myblue tw-underline tw-cursor-pointer">
					View
				</h6>
			</div>
		</div>
	);
};

export const CoursesCard2 = ({
	img,
	title,
	description,
	videos,
	duration,
	onClick,
	text,
}) => {
	const [hovered, setHovered] = useState(false);
	const handleHover = () => {
		setHovered(true);
	};
	const handleMouseOut = () => {
		setHovered(false);
	};
	return (
		<>
			<div
				onMouseOver={handleHover}
				onMouseOut={handleMouseOut}
				onClick={onClick}
				style={{
					border: "0.5px solid rgba(1, 52, 103, 0.30)",
				}}
				className="tw-rounded-lg tw-relative tw-pb-8 tw-cursor-pointer tw-bg-white">
				<div className="tw-relative">
					<img src={img} alt="" className="tw-rounded-t-lg tw-w-full" />
					{hovered && (
						<div className="tw-absolute tw-w-full tw-left-0 tw-top-0 tw-h-full tw-bg-black tw-bg-opacity-30 tw-flex tw-items-center tw-justify-center tw-cursor-pointer">
							<div className="tw-h-10 tw-w-10 tw-rounded-full tw-flex tw-justify-center tw-items-center tw-bg-white">
								<img src={Play} alt="" className="" />
							</div>
						</div>
					)}
				</div>
				<div className="tw-py-2 tw-relative tw-px-4">
					<h6 className="tw-text-base tw-capitalize tw-text-secondary tw-font-bold">
						{title}
					</h6>
					{/* <p className="tw-text-xs tw-font-normal tw-text-main">
						{description}
					</p> */}
					{ContentWriteup(
						description,
						"tw-text-xs tw-font-normal tw-text-main"
					)}

					<div className="tw-mt-4 tw-flex tw-justify-between tw-items-center">
						<div className="tw-flex tw-items-center tw-gap-2">
							<img src={Videos} alt="" className="" />
							<small className="tw-text-sm tw-font-light satoshi tw-text-main">
								{videos} videos
							</small>
						</div>
						<div className="tw-flex tw-items-center tw-gap-2">
							<img src={Duration} alt="" className="" />
							<small className="tw-text-sm tw-font-light satoshi tw-text-main">
								{duration} hours
							</small>
						</div>
					</div>
				</div>
				<div className="tw-absolute tw-bottom-3 tw-left-6">
					<small className="tw-text-sm tw-font-medium tw-underline tw-text-[#0269D0] satoshi">
						{text}
					</small>
				</div>
			</div>
		</>
	);
};

export const OngoingCouses = ({ addCourse, institutionCourse }) => {
	const navigate = useNavigate();

	let [datum, setDatum] = useState(null),
		{
			isFound,
			mainSearch,
			data,
			getCourse,
			resetCourseSearch,
			institution,
			getInstitutionCourse,
		} = useCourseStore(),
		{ user } = useAuthStore();
	useEffect(() => {
		if (isFound) setDatum(mainSearch);
		else if (addCourse) setDatum(institution);
		else setDatum(data);
	}, [data, isFound, mainSearch, addCourse, institution]);

	useEffect(() => {
		apiCall(
			"get",
			`/api/v1/course?company=${addCourse || "company"}${
				addCourse ? `&institution=${user?.organisation}` : ""
			}${institutionCourse ? `&institutionCourse=${user?.organisation}` : ""}`,
			null,
			addCourse ? getInstitutionCourse : getCourse
		);
		resetCourseSearch();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	let [range] = useState(10);

	const [itemOffset, setItemOffset] = useState(0);
	const endOffset = itemOffset + range;

	// if (!datum && !data) return <PageLoader />;
	if (!datum) return;

	const currentItems = datum?.docs?.slice(itemOffset, endOffset);
	const pageCount = Math.ceil(datum?.docs?.length / range);

	const handlePageClick = event => {
		const newOffset = (event.selected * range) % datum?.docs?.length;
		setItemOffset(newOffset);
	};

	return (
		<div
			className={`tw-w-full tw-bg-white tw-rounded-3xl tw-p-4 ${
				addCourse ? "tw-mb-20" : ""
			}`}>
			<div className="tw-flex tw-justify-between tw-items-center">
				<div className="tw-flex tw-gap-4 tw-items-center">
					<h5 className="lg:tw-text-base tw-text-xs tw-text-skyblue tw-font-bold satoshi tw-capitalize">
						{addCourse || "Ongoing"} Courses
					</h5>
					<SearchInput />
				</div>
				{addCourse && (
					<MainBtn
						onClick={() => navigate("/courses/add-course")}
						text={"Add course"}
					/>
				)}
			</div>
			<div className="tw-mt-6 tw-grid lg:tw-grid-cols-4 tw-gap-6">
				{currentItems?.map((item, i) => (
					<CoursesCard2
						key={i}
						onClick={() =>
							navigate(
								`/courses/${addCourse ? "manage-course" : item?.title}`,
								{ state: item }
							)
						}
						img={
							item?.image?.url ||
							item?.module?.[0]?.image?.url ||
							item?.module?.[0]?.section?.[0]?.image?.url ||
							Arr3?.[i % (Arr3?.length - 1)]?.img
						}
						text={"View"}
						description={item?.description}
						title={item?.title}
						videos={item?.module?.reduce?.(
							(ac, i) => (ac += i?.section?.length),
							0
						)}
						duration={item?.duration ? item?.duration % 60 : 0}
					/>
				))}
			</div>
			{pageCount > 1 && (
				<MainPaginate pageCount={pageCount} handlePageClick={handlePageClick} />
			)}
		</div>
	);
};

export const EnrollCouses = ({ institutionCourse }) => {
	const navigate = useNavigate();

	let [datum, setDatum] = useState(null),
		{ isFound, mainSearch, enroll, getCourseToEnroll, resetCourseSearch } =
			useCourseStore(),
		{ user } = useAuthStore();

	useEffect(() => {
		if (isFound) setDatum(mainSearch);
		else setDatum(enroll);
	}, [enroll, isFound, mainSearch]);

	useEffect(() => {
		apiCall(
			"get",
			`/api/v1/course?enroll=enroll&company=company${
				institutionCourse ? `&institutionCourse=${user?.organisation}` : ""
			}`,
			null,
			getCourseToEnroll
		);
		resetCourseSearch();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	let [range] = useState(10);

	const [itemOffset, setItemOffset] = useState(0);
	const endOffset = itemOffset + range;

	// if (!datum && !data) return <PageLoader />;
	if (!datum) return;

	const currentItems = datum?.docs?.slice(itemOffset, endOffset);
	const pageCount = Math.ceil(datum?.docs?.length / range);

	const handlePageClick = event => {
		const newOffset = (event.selected * range) % datum?.docs?.length;
		setItemOffset(newOffset);
	};

	return (
		<div className="tw-w-full tw-mt-20 tw-bg-white tw-rounded-3xl tw-p-4">
			<div className="tw-flex tw-justify-between tw-items-center">
				<div className="tw-flex tw-gap-4 tw-items-center">
					<h5 className="lg:tw-text-base tw-text-xs tw-text-skyblue tw-font-bold satoshi">
						Suggested Courses
					</h5>
					<SearchInput />
				</div>
			</div>
			<div className="tw-mt-6 tw-grid lg:tw-grid-cols-4 tw-gap-6">
				{currentItems?.map((item, i) => (
					<CoursesCard2
						key={i}
						img={
							item?.image?.url ||
							item?.module?.[0]?.image?.url ||
							item?.module?.[0]?.section?.[0]?.image?.url ||
							Arr3?.[i % (Arr3?.length - 1)]?.img
						}
						text={"Learn More"}
						description={item?.description}
						title={item?.title}
						videos={item?.module?.reduce?.(
							(ac, i) => (ac += i?.section?.length),
							0
						)}
						duration={item?.duration ? item?.duration % 60 : 0}
						onClick={() => navigate(`/courses/details`, { state: item })}
					/>
				))}
			</div>
			{pageCount > 1 && (
				<MainPaginate pageCount={pageCount} handlePageClick={handlePageClick} />
			)}
		</div>
	);
};


const Courses = ()=> <MainCourses />

export default Courses;
