import {AiOutlineClose} from "react-icons/ai";
import {TextInput} from "../inputs";
import {MainBtn} from "../button";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { apiCall } from "../../data/stores/authstore";
import useSessionStore from "../../data/stores/sessionstore";
import useErrorStore from "../../data/stores/errorstore";
import moment from "moment";
import { useEffect } from "react";
import useUserStore from "../../data/stores/userstore";
import { InsiderDispay } from "../../views/all-staff";

const AddSession = ({ handleClose }) => {
	let [state, setState] = useState({}),
		textChange = ({ target: { value, name } }) => {
			setState({ ...state, [name]: value });
		},
		[loading, setLoading] = useState(null),
		{ returnErrors } = useErrorStore(),
		{ addSession } = useSessionStore(),
		[step, setStep] = useState("1"),
		[datum, setDatum] = useState(null),
		handleSubmit = async e => {
			e?.preventDefault();
			// if (!state?.password) return toast.info("Password is required");
			// if (!state?.email) return toast.info("User email is required");
			let newState = state;
			newState.recipient = datum?.docs
				?.filter(ii => ii?.isChecked)
				?.map(ic => ic?._id);

			console.log({ newState });
			setLoading(true);
			let { response, errArr, errMsg } = await apiCall(
				"post",
				`/api/v1/session`,
				newState
			);
			console?.log({ response, errArr, errMsg });
			if (errArr) {
				setLoading(false);
				return returnErrors(errArr);
			}
			if (errMsg) {
				setLoading(false);
				return toast.error(errMsg);
			}
			setLoading(false);
			if (response) {
				addSession(response);
				setState(null);
				handleClose(state);
				return;
			}
			setLoading(false);
		};

	return (
		<div>
			<div className="tw-fixed tw-z-50 tw-inset-0 tw-bg-myblue tw-bg-opacity-30 tw-flex tw-justify-center tw-h-screen tw-w-full tw-items-center">
				<div className="tw-bg-white tw-rounded-xl tw-w-96 tw-p-4">
					<div className="tw-flex tw-justify-between tw-items-center">
						<h6 className="tw-text-xl tw-font-medium tw-text-[#013467] satoshi">
							Add a session
						</h6>
						<AiOutlineClose
							className="tw-cursor-pointer"
							onClick={handleClose}
						/>
					</div>
					<form action="" className="tw-mt-4">
						{step === "1" && (
							<>
								<div>
									<TextInput
										label={"Session Name"}
										value={state?.title}
										onChange={textChange}
										name={"title"}
									/>
								</div>
								<div className="tw-mt-4">
									<TextInput
										label={"Session Meeting Link"}
										value={state?.link}
										onChange={textChange}
										name={"link"}
										type="url"
									/>
								</div>
								<div className="tw-mt-3">
									<TextInput
										label={"Date"}
										type={"date"}
										value={state?.date}
										onChange={textChange}
										name={"date"}
										min={moment().format("YYYY-MM-DD")}
									/>
								</div>
								<div className="tw-mt-3">
									<TextInput
										label={"Start Time"}
										type={"time"}
										value={state?.time}
										onChange={textChange}
										name={"time"}
									/>
								</div>
								{state?.time && state?.date && (
									<div className="tw-mt-3">
										<TextInput
											label={"Meeting Duration(in hours)"}
											type={"tel"}
											value={state?.duration}
											onChange={textChange}
											name={"duration"}
											setState={e => setState({ ...state, duration: e })}
											noFormat
										/>
									</div>
								)}
							</>
						)}
						{step === "2" && (
							<>
								<AddStudents datum={datum} setDatum={setDatum} />
							</>
						)}
						<div className="tw-mt-5">
							<MainBtn
								text={"Invite Member"}
								onClick={
									step === "1"
										? () => {
												let errArr = [];
												if (!state?.title) errArr?.push("Title is required");
												if (!state?.date)
													errArr?.push("Session Date is required");
												if (!state?.time)
													errArr?.push("Session Time is required");
												if (!state?.duration)
													errArr?.push("Session Duration is required");
												if (errArr?.length > 0)
													return errArr?.forEach(it => toast.info(it));
												setStep("2");
										  }
										: handleSubmit
								}
								loading={loading}
								type="button"
							/>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export default AddSession;

export const AddStudents = ({ datum, setDatum }) => {
	let { getAllUsers, allData, data } = useUserStore();

	useEffect(() => {
		let dd = data;
		if (allData) dd = allData;
		setDatum(dd);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [allData, data]);

	useEffect(() => {
		apiCall(
			"get",
			"/api/v1/user/manage-student?privilege=student&pagination=not",
			null,
			getAllUsers
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<div className="tw-bg-white tw-p-6 tw-rounded-xl">
				<div className="tw-flex tw-items-center tw-gap-6">
					<h2 className="tw-text-skyblue tw-font-bold tw-text-xl satoshi">
						All Students
					</h2>
					{/* <SearchInput /> */}
				</div>
				<div className="tw-mt-6 tw-w-full tw-flex tw-justify-between tw-items-center">
					<h5 className="tw-text-base tw-font-medium satoshi tw-text-secondary">
						Name
					</h5>
					<h5 className="tw-text-base tw-font-medium satoshi tw-text-secondary">
						Department
					</h5>
				</div>
				<div className="tw-space-y-4 tw-mt-5">
					{datum?.docs?.map((it, x) => (
						<InsiderDispay x={x} it={it} setDatum={setDatum} />
					))}
				</div>
			</div>
		</>
	);
};