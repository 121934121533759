import { MainPaginate, Tablebtn } from "../button";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useUserStore from "../../data/stores/userstore";
import useCourseStore from "../../data/stores/coursestore";
import useAuthStore, { apiCall } from "../../data/stores/authstore";
import moment from "moment";
import { UserCourseTable } from "./userstable";

const Instructorstable = () => {
	const navigate = useNavigate();
	const Headers = [
			"name",
			"date registered",
			"no. of courses",
			"status",
			"action",
		],
		data1 = [
			{
				name: "John Doe",
				conpany: "my company",
				department: "Fleet Management",
				date_registered: "07 May, 2023",
				no_of_courses: "4",
			},
			{
				name: "John Doe",
				conpany: "my company",
				department: "Fleet Management",
				date_registered: "07 May, 2023",
				no_of_courses: "4",
			},
			{
				name: "John Doe",
				conpany: "my company",
				department: "Fleet Management",
				date_registered: "07 May, 2023",
				no_of_courses: "4",
			},
		];

	let [datum, setDatum] = useState({ docs: data1 }),
		{ isFound, mainSearch, instructors, getInstructors } = useUserStore(),
		{ getAllCourse } = useCourseStore(),
		{ userPage, user } = useAuthStore();

	useEffect(() => {
		if (isFound) setDatum(mainSearch);
		else setDatum(instructors);
	}, [instructors, isFound, mainSearch]);

	useEffect(() => {
		apiCall(
			"get",
			`/api/v1/user/manage-users?privilege=instructor`,
			null,
			getInstructors
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		apiCall(
			"get",
			`/api/v1/course?pagination=not${
				userPage !== "student" ? `&company=${userPage}` : ""
			}${
				userPage === "institution" ? `&institution=${user?.organisation}` : ""
			}`,
			null,
			getAllCourse
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const [itemOffset, setItemOffset] = useState(0);
	let [range] = useState(10);
	const endOffset = itemOffset + range;

	// if (!datum && !data) return <PageLoader />;
	if (!datum) return;

	const currentItems = datum?.docs?.slice(itemOffset, endOffset);
	const pageCount = Math.ceil(datum?.docs?.length / range);

	const handlePageClick = event => {
		const newOffset = (event.selected * range) % datum?.docs?.length;
		setItemOffset(newOffset);
	};

	return (
		<div className={"tw-overflow-x-auto"}>
			<table className="tw-min-w-full tw-min-h-full tw-table-auto">
				<thead>
					<tr>
						{Headers?.map(item => (
							<th className="tw-text-secondary tw-p-3 tw-font-medium satoshi tw-text-sm tw-capitalize">
								{item}
							</th>
						))}
					</tr>
				</thead>
				<tbody>
					{currentItems?.map((item, i) => (
						<tr
							style={{
								borderBottom: "1px solid rgba(1, 52, 103, 0.30)",
							}}
							key={i}>
							<td className="tableText">
								{item?.firstName} {item?.lastName}
							</td>
							<td className="tableText">
								{moment(item?.createdAt).format("Do MMMM, YYYY")}
							</td>
							<td className="tableText">
								<UserCourseTable
									type={"length"}
									datum={item}
									userType={"instructor"}
								/>
							</td>
							<td className="tableText tw-capitalize">{item?.statusText}</td>

							<td>
								<div className="">
									<Tablebtn
										onClick={() =>
											navigate(`/instructors/${item?.firstName || i}`, {
												state: item,
											})
										}
										text={"View"}
									/>
								</div>
							</td>
						</tr>
					))}
				</tbody>
				{pageCount > 1 && (
					<MainPaginate
						pageCount={pageCount}
						handlePageClick={handlePageClick}
					/>
				)}
			</table>
		</div>
	);
};
export default Instructorstable;
