import React from "react";

const CertificatesTab = () => {
  const certs = [
		// "John Doe",
		// "John Doe",
		// "John Doe",
		// "John Doe",
		// "John Doe",
		// "John Doe",
	];
  return (
    <div>
      <div className="tw-w-full tw-space-y-4">
        {certs?.map((item, i) => (
          <div
            key={item}
            style={{
              border: "0.5px solid rgba(37, 37, 37, 0.50)",
            }}
            className="tw-px-4 tw-w-full tw-cursor-pointer tw-rounded-lg"
          >
            <div className="tw-h-12 tw-flex tw-justify-between tw-items-center tw-w-full">
              <small className="tw-text-sm tw-font-medium tw-text-main satoshi">
                {item}
              </small>
              <small className="tw-text-xs tw-font-medium tw-text-main satoshi">
                Earned
              </small>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CertificatesTab;
