import "./App.css";
import $ from "jquery";
import "react-toastify/dist/ReactToastify.css";
import { Provider } from "react-redux";
import DataProvider from "./data/Context";
import store from "./data/Store";
import { BrowserRouter as Router } from "react-router-dom";
import Routers from "./Routes";
import { SetAuthToken, SetDefaultHeaders } from "./data/Config";
import useAuthStore, { apiCall, TOKEN } from "./data/stores/authstore";
import useGenFetcher from "./data/useGenFetcher";
import { useEffect } from "react";
import useCourseStore from "./data/stores/coursestore";

// Preloader
$(window).on("load", function () {
	$(".lds-ellipsis").fadeOut(); // will first fade out the loading animation
	$(".preloader").delay(333).fadeOut("slow"); // will fade out the white DIV that covers the website.
	$("body").delay(333);
});

SetDefaultHeaders();

if (localStorage.getItem(TOKEN)) {
	SetAuthToken(localStorage.getItem(TOKEN));
}

const App = () => {
	let { loadUser } = useGenFetcher(),
		{ isAuth } = useAuthStore(),
		{ getCourse } = useCourseStore();

	useEffect(() => {
		loadUser();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (isAuth) return;
		apiCall("get", `/api/v1/course`, null, getCourse);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isAuth]);

	return (
		<Provider store={store}>
			<DataProvider>
				<Router>
					<Routers />
				</Router>
			</DataProvider>
		</Provider>
	);
};

export default App;
