import MainContainer from "../components/maincontainer";
import MainHeader from "../components/mainheader";
import { SessionHeader, SessionsMainTable } from "../instructor/dashboard";

export const MainDashboardSessions = () => {
	return (
		<div>
			<MainContainer>
				<MainHeader text={"Sessions"} />
				<div className="tw-w-full tw-mt-8 tw-bg-white tw-rounded-3xl tw-p-4">
					<SessionHeader />
					<SessionsMainTable />
				</div>
			</MainContainer>
		</div>
	);
};

const DashboardSessions = () => <MainDashboardSessions />;

export default DashboardSessions;
