import React, { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import Img from "../../assets/certificatemain.png";
import Cap from "../../assets/cap.svg";
import { useNavigate } from "react-router";
import { MainBtn } from "../button";

const CertModal = ({ handleClose }) => {
  const [modal, setModal] = useState();
  return (
		<div className="tw-fixed tw-inset-0 tw-bg-skyblue tw-bg-opacity-20 tw-z-50 tw-flex tw-justify-center tw-items-center tw-w-full tw-h-screen">
			<div className="lg:tw-w-1/3 tw-bg-white tw-rounded-xl tw-p-5">
				<div className="tw-flex tw-justify-end">
					<AiOutlineClose onClick={handleClose} />
				</div>
				<img src={Img} alt="" className="tw-mt-6" />
				<div className="tw-mt-5">
					<MainBtn onClick={() => setModal("downloaded")} text={"Download"} />
				</div>
			</div>
			{modal === "downloaded" && <ThanksModal />}
		</div>
	);
};

export const ThanksModal = ({ handleClose }) => {
  let navigate = useNavigate();
  return (
    <div className="tw-fixed tw-inset-0 tw-bg-skyblue tw-bg-opacity-20 tw-z-50 tw-flex tw-justify-center tw-items-center tw-w-full tw-h-screen">
      <div className="lg:tw-w-1/4 tw-p-5 tw-bg-white tw-rounded-xl">
        <div className="tw-flex tw-justify-end">
          <AiOutlineClose onClick={handleClose} />
        </div>
        <img src={Cap} alt="" className="tw-mx-auto" />
        <h6 className="tw-text-base tw-text-secondary tw-py-4 tw-text-center">
          Certificate downloaded
        </h6>
        <p className="tw-text-sm tw-text-main tw-text-center">
          You have successful downloaded your <br /> certificate in your local
          drive
        </p>
        <div className="tw-mt-4 tw-flex tw-justify-center">
          <MainBtn onClick={() => navigate("/")} text={"Tnanks"} />
        </div>
      </div>
    </div>
  );
};

export default CertModal;
