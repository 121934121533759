import React, { useState } from "react";
import MainContainer from "../../components/maincontainer";
import MainHeader from "../../components/mainheader";
import { MainBtn } from "../../components/button";
import Total from "../../assets/totalcourse.svg";
import Ongoing from "../../assets/ongoing.svg";
import Progresstable from "../../components/tables/progresstable";
import CompletionTable from "../../components/tables/completiontable";
import { CourseDashTable } from "../../components/tables/userstable";

const Progress = () => {
  const [tab, setTab] = useState("progress");
  return (
    <div>
      <MainContainer>
        <MainHeader text={"Reports"} />
        <div className="tw-w-full tw-bg-white tw-rounded-3xl tw-p-4">
          <h1 className="tw-text-xl satoshi tw-font-bold tw-text-secondary">
            Overview
          </h1>
          <div className="tw-flex tw-justify-between tw-flex-col lg:tw-flex-row tw-items-center">
            <div className="tw-mt-6 tw-flex tw-flex-col lg:tw-flex-row tw-gap-6">
              <div
                onClick={() => setTab("progress")}
                className={`${
                  tab === "progress" && "tw-bg-[#D9ECFF]"
                } tw-h-10 tw-cursor-pointer tw-rounded-3xl tw-px-5 tw-flex tw-justify-center tw-items-center`}
              >
                <small className="tw-text-base satoshi tw-font-medium tw-text-[#0269D0]">
                  Progress Tracking
                </small>
              </div>
              <div
                onClick={() => setTab("completion")}
                className={`${
                  tab === "completion" && "tw-bg-[#D9ECFF]"
                } tw-h-10 tw-cursor-pointer tw-rounded-3xl tw-px-5 tw-flex tw-justify-center tw-items-center`}
              >
                <small className="tw-text-base satoshi tw-font-medium tw-text-[#0269D0]">
                  Completion Rate
                </small>
              </div>
            </div>
            <div className="tw-flex tw-gap-4">
              <MainBtn text={"Filter"} />
              <MainBtn text={"Export"} />
            </div>
          </div>
          {tab === "progress" && <ProgressTab />}
          {tab === "completion" && <CompletionTab />}
        </div>
      </MainContainer>
    </div>
  );
};

const ProgressTab = () => {
  const Arr = [
		{
			title: "Total Registered Course",
			icon: Total,
			value: <CourseDashTable type={"total"} />,
			bg: "#006A0B14",
		},
		{
			title: "Ongoing Course",
			icon: Ongoing,
			value: <CourseDashTable type={""} />,
			bg: "#0269D014",
		},
		{
			title: "Locked Course",
			icon: Ongoing,
			value: <CourseDashTable type={"lock-out"} />,
			bg: "#FF373914",
		},
	];
  return (
    <>
      <div
        style={{
          border: "1px solid rgba(1, 52, 103, 0.30)",
          borderRadius: "12px",
        }}
        className="tw-mt-8 lg:tw-h-40 tw-py-6 lg:tw-py-0 tw-px-6 tw-grid lg:tw-grid-cols-3 tw-gap-6 tw-items-center"
      >
        {Arr?.map((item, i) => (
          <div
            key={i}
            style={{
              background: item?.bg,
            }}
            className="tw-h-24 tw-rounded tw-px-5 tw-flex tw-gap-6 tw-items-center"
          >
            <div className="tw-h-10 tw-w-10 tw-rounded-full tw-bg-white tw-flex tw-justify-center tw-items-center tw-shadow-md">
              {" "}
              <img src={item?.icon} alt="" className="" />
            </div>
            <div>
              <h5 className="tw-text-sm tw-font-medium satoshi tw-capitalize tw-text-main">
                {item?.title}
              </h5>
              <h2 className="tw-text-3xl tw-font-medium tw-text-main satoshi">
                {item?.value}
              </h2>
            </div>
          </div>
        ))}
      </div>
      <div className="tw-mt-10">
        <Progresstable />
      </div>
    </>
  );
};

const CompletionTab = () => {
  const Arr = [
		{
			title: "Total Registered Course",
			icon: Total,
			value: <CourseDashTable type={"total"} />,
			bg: "#006A0B14",
		},
		{
			title: "Completed",
			icon: Ongoing,
			value: <CourseDashTable type={"completed"} />,
			bg: "#0269D014",
		},
		{
			title: "Not Completed",
			icon: Ongoing,
			value: <CourseDashTable type={"not-completed"} />,
			bg: "#FF373914",
		},
	];
  return (
    <>
      <div
        style={{
          border: "1px solid rgba(1, 52, 103, 0.30)",
          borderRadius: "12px",
        }}
        className="tw-mt-8 lg:tw-h-40 tw-py-6 lg:tw-py-0 tw-px-6 tw-grid lg:tw-grid-cols-3 tw-gap-6 tw-items-center"
      >
        {Arr?.map((item, i) => (
          <div
            key={i}
            style={{
              background: item?.bg,
            }}
            className="tw-h-24 tw-rounded tw-px-5 tw-flex tw-gap-6 tw-items-center"
          >
            <div className="tw-h-10 tw-w-10 tw-rounded-full tw-bg-white tw-flex tw-justify-center tw-items-center tw-shadow-md">
              {" "}
              <img src={item?.icon} alt="" className="" />
            </div>
            <div>
              <h5 className="tw-text-sm tw-font-medium satoshi tw-capitalize tw-text-main">
                {item?.title}
              </h5>
              <h2 className="tw-text-3xl tw-font-medium tw-text-main satoshi">
                {item?.value}
              </h2>
            </div>
          </div>
        ))}
      </div>
      <div className="tw-mt-10">
        <CompletionTable />
      </div>
    </>
  );
};
export default Progress;
