// quizStore.js
import { create } from "zustand";
import { persist, devtools } from "zustand/middleware";

const QuizStore = (set) => ({
  sections: [
    {
      quizData: [
        {
          question: "What is the name of your company?",
          answers: [
            "Businesscoded",
            "ICSOusourcing",
            "None",
            "Security and commerce",
          ],
          correctanswer: "None",
        },
        {
          question: "Where do you stay in lagos?",
          answers: [
            "Businesscoded",
            "ICSOusourcing",
            "None",
            "Security and commerce",
          ],
          correctanswer: "None",
        },
        {
          question: "What your Designation?",
          answers: [
            "Businesscoded",
            "ICSOusourcing",
            "None",
            "Security and commerce",
          ],
          correctanswer: "None",
        },
      ],
      userAnswers: [],
    },
  ],
  currentSectionIndex: 0,
  setCurrentSectionIndex: (index) => set({ currentSectionIndex: index }),
  selectAnswer: (sectionIndex, questionIndex, answer) =>
    set((state) => {
      const updatedSections = [...state.sections];
      updatedSections[sectionIndex].userAnswers[questionIndex] = answer;
      return { sections: updatedSections };
    }),
  resetQuiz: () => set({ currentSectionIndex: 0, sections: [] }),
});

const useQuizStore = create(
  devtools(
    persist(QuizStore, {
      name: "ics-quiz-store",
    })
  )
);

export default useQuizStore;
