import MainContainer from "../../components/maincontainer";
import MainHeader from "../../components/mainheader";
import { OngoingCouses } from "../../views/courses";

const Courses = () => {
	return (
		<div>
			<MainContainer>
				<MainHeader text={"Courses"} small={"34 Courses"} />
				<OngoingCouses />
				{/* <EnrollCouses /> */}
			</MainContainer>
		</div>
	);
};

export default Courses;
