import React, { useEffect, useState } from "react";
import MainContainer from "../../components/maincontainer";
import MainHeader from "../../components/mainheader";
import { SearchInput } from "../../components/inputs";
import { Button } from "../../components/button";
import { useNavigate, useLocation } from "react-router-dom";
import useUserStore from "../../data/stores/userstore";
import useAuthStore, { apiCall } from "../../data/stores/authstore";
import { toast } from "react-toastify";
import useErrorStore from "../../data/stores/errorstore";

export const MainAllStaff = () => {
	const navigate = useNavigate(),
		{ state } = useLocation();

	useEffect(() => {
		if (!state) navigate(-1);
	}, [state, navigate]);
	let [datum, setDatum] = useState(null),
		{ getAllUsers, allData, data } = useUserStore(),
		[loading, setLoading] = useState(null),
		{ returnErrors } = useErrorStore(),
		{ userPage } = useAuthStore();

	useEffect(() => {
		let dd = data;
		if (allData) dd = allData;
		if (state?.partakers) {
			let newDD = [];
			for (let d = 0; d < dd?.docs?.length; d++) {
				let element = dd?.docs?.[d],
					findIt = state?.partakers?.find(ij => ij?._id === element?._id);
				if (!findIt) newDD?.push(element);
			}
			// console.log({ newDD, dd, state: state?.partakers });
			dd.docs = newDD;
		}
		setDatum(dd);
	}, [allData, data, state?.partakers]);

	useEffect(() => {
		apiCall(
			"get",
			`/api/v1/user/manage-users?privilege=${
				userPage === "institution" ? "student" : "staff"
			}&pagination=not`,
			null,
			getAllUsers
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	let handleAddStaff = async e => {
		e?.preventDefault();
		setLoading(true);
		let { response, errArr, errMsg } = await apiCall(
			"post",
			`/api/v1/transactions/manage-add-partakers`,
			{
				course: state?._id,
				partakers: datum?.docs?.filter(ii => ii?.isChecked)?.map(ic => ic?._id),
			}
		);
		console?.log({ response, errArr, errMsg });
		if (errArr) {
			setLoading(false);
			return returnErrors(errArr);
		}
		if (errMsg) {
			setLoading(false);
			return toast.error(errMsg);
		}
		setLoading(false);
		if (response) {
			navigate("/courses");
			return toast.success(response?.msg || response?.message);
		}
	};

	return (
		<div>
			<MainContainer>
				<MainHeader text={"Courses"} small={"All courses"} />
				<div className="tw-mt-8 tw-grid lg:tw-grid-cols-2 tw-gap-7">
					<div className="tw-bg-white tw-p-6 tw-rounded-xl">
						<div className="tw-flex tw-items-center tw-gap-6">
							<h2 className="tw-text-skyblue tw-font-bold tw-text-xl satoshi">
								All {userPage === "institution" ? "Student" : "Staff"}
							</h2>
							<SearchInput />
						</div>
						<div className="tw-mt-6 tw-w-full tw-flex tw-justify-between tw-items-center">
							<h5 className="tw-text-base tw-font-medium satoshi tw-text-secondary">
								Name
							</h5>
							<h5 className="tw-text-base tw-font-medium satoshi tw-text-secondary">
								Department
							</h5>
						</div>
						<div className="tw-space-y-4 tw-mt-5">
							{datum?.docs?.map((it, x) => (
								<InsiderDispay x={x} it={it} setDatum={setDatum} />
							))}
						</div>
					</div>
					<div className="tw-bg-white tw-p-6 tw-rounded-xl">
						<div className="tw-flex tw-items-center tw-gap-6">
							<h2 className="tw-text-skyblue tw-whitespace-nowrap tw-font-bold tw-text-xl satoshi">
								{userPage === "institution" ? "Student" : "Staff"} Added
							</h2>
							<SearchInput />
						</div>
						<div className="tw-mt-6 tw-w-full tw-flex tw-justify-between tw-items-center">
							<h5 className="tw-text-base tw-font-medium satoshi tw-text-secondary">
								Name
							</h5>
							<h5 className="tw-text-base tw-font-medium satoshi tw-text-secondary">
								Department
							</h5>
						</div>
						<div className="tw-space-y-4 tw-mt-5">
							{datum?.docs
								?.filter(ii => ii?.isChecked)
								?.map((it, x) => (
									<InsiderDispay x={x} it={it} setDatum={setDatum} />
								))}
						</div>
						{datum?.docs?.filter(ii => ii?.isChecked)?.length > 0 && (
							<div className="tw-mt-6">
								<Button
									onClick={
										state?.partakers
											? handleAddStaff
											: () =>
													navigate("/checkout", {
														state: {
															...state,
															partakers: datum?.docs
																?.filter(ii => ii?.isChecked)
																?.map(ic => ic?._id),
														},
													})
									}
									children={
										state?.partakers
											? `Add ${
													userPage === "institution" ? "Student" : "Staff"
											  } to course`
											: "Proceed to payment"
									}
									loading={loading}
								/>
							</div>
						)}
					</div>
				</div>
			</MainContainer>
		</div>
	);
};

const AllStaff = () => <MainAllStaff />;

export default AllStaff;

export let InsiderDispay = ({ x, it, setDatum }) => {
	return (
		<div
			key={x}
			className="tw-flex tw-border-b tw-pb-2 tw-justify-between tw-items-center">
			<div className="tw-flex tw-gap-4">
				<input
					onChange={e => {
						setDatum(prev => {
							let prevData = { ...prev },
								dd = prevData?.docs,
								current = dd?.find(ix => ix?._id === it?._id);
							if (e?.target?.checked) {
								current.isChecked = true;
							} else {
								current.isChecked = false;
							}
							dd = dd?.map(ic => (ic?._id === it?._id ? current : ic));
							prevData.docs = dd;
							return prevData;
						});
					}}
					type="checkbox"
					checked={it?.isChecked}
					className="tw-h-4 tw-w-4 tw-border"
					id={it?._id}
				/>
				<label
					className="tw-text-sm tw-text-main satoshi tw-font-normal"
					htmlFor={it?._id}>
					{it?.firstName} {it?.lastName}
				</label>
			</div>
			<h6 className="tw-text-sm first-letter:tw-uppercase tw-text-main satoshi tw-font-normal">
				{it?.dept}
			</h6>
		</div>
	);
};