import React, { useState } from "react";
import ChatImg from "../../assets/chat.svg";
import { MainBtn } from "../button";
import { MdClose } from "react-icons/md";

const Chat = () => {
  const [chatVisible, setChatVisible] = useState(false),
    [isSent, setIsSent] = useState(false);

  return (
    <div className="tw-fixed tw-bottom-4 tw-right-8 tw-z-[1000]">
      <div
        style={{
          boxShadow: "0px 19px 33.8px 0px rgba(0, 0, 0, 0.25)",
        }}
        className={`tw-transition-all tw-duration-300 ${
          chatVisible ? "tw-w-96 tw-opacity-100" : "tw-w-0 tw-opacity-0"
        } tw-p-6 tw-rounded-2xl tw-bg-white overflow-hidden`}
      >
        {isSent ? (
          <div className="">
            <h3 className="tw-text-2xl tw-text-secondary tw-font-bold satoshi">
              Great
            </h3>
            <div>
              <p className="tw-text-base tw-font-medium tw-text-main satoshi">
                Thank so much for reaching out to the ICS Academy,
              </p>
              <p className="tw-text-base tw-font-medium tw-text-main satoshi">
                The team will reach out to you in a minutes
              </p>
            </div>
            <div className="tw-mt-6">
              <MainBtn
                text={"Okay"}
                onClick={() => setChatVisible(false) || setIsSent(false)}
              />
            </div>
          </div>
        ) : (
          <div>
            {" "}
            <div className="tw-flex tw-justify-between tw-items-center">
              <h3 className="tw-text-2xl tw-text-secondary tw-font-bold satoshi">
                Get In Touch
              </h3>
              <MdClose
                size={20}
                onClick={() => setChatVisible(false)}
                className="tw-cursor-pointer"
              />
            </div>
            <form action="" className="tw-mt-5">
              <div className="tw-mt-3">
                <p className="tw-text-sm tw-font-medium tw-text-main">
                  Message
                </p>
                <textarea
                  name=""
                  className={`tw-h-28 tw-w-full tw-rounded-lg tw-mt-2 tw-pl-4 tw-pt-4 tw-bg-[#F3F5F5] smallText`}
                  id=""
                />
              </div>
              <div className="tw-mt-6">
                <MainBtn
                  onClick={() => setIsSent(true)}
                  text={"Get in touch"}
                />
              </div>
            </form>
          </div>
        )}
      </div>
      <img
        onClick={() => setChatVisible(!chatVisible)}
        src={ChatImg}
        alt=""
        className="tw-cursor-pointer"
      />
    </div>
  );
};

export default Chat;
