import React, { useState } from "react";
import { LuMonitorPlay } from "react-icons/lu";
import { CiLock } from "react-icons/ci";
import { AiOutlineDown, AiOutlineRight } from "react-icons/ai";
import useAuthStore from "../../data/stores/authstore";

const Modulestab = ({ datum, setActiveSection }) => {
	const [moduleList, setModuleList] = useState(null),
		{ userPage } = useAuthStore();

	const handleModule = i => {
		setModuleList(moduleList === i ? null : i);
	};
	let [thisData] = useState(datum?.module);

	if (!datum) return;

	return (
		<div>
			<div className="tw-w-full tw-space-y-4">
				{userPage === "company"
					? thisData?.map((item, i) => (
							<div
								key={item}
								onClick={() => handleModule(i)}
								style={{
									border: "0.5px solid rgba(37, 37, 37, 0.50)",
								}}
								className="tw-px-4 tw-w-full tw-cursor-pointer tw-rounded-lg">
								<div className="tw-h-12 tw-flex tw-justify-between tw-items-center tw-w-full">
									<small className="tw-text-sm tw-font-medium tw-text-main satoshi">
										{item?.title}
									</small>
									{moduleList === i ? (
										<AiOutlineDown size={10} />
									) : (
										<AiOutlineRight size={10} />
									)}
								</div>
								{moduleList === i && (
									<>
										<ul className="tw-px-2 tw-transition-all tw-ease-linear tw-duration-500 tw-list-decimal">
											{item?.section?.map((lesson, c) => (
												<li
													key={c}
													style={{
														borderBottom: "0.5px solid rgba(37, 37, 37, 0.50)",
													}}
													className="tw-py-2  tw-flex tw-justify-between tw-items-center">
													{" "}
													<small className="tw-text-sm tw-font-medium tw-text-main satoshi">
														{lesson?.title}
													</small>
													<small className="tw-text-sm tw-font-medium tw-text-myblue tw-underline satoshi">
														View
													</small>
												</li>
											))}
										</ul>
									</>
								)}
							</div>
					  ))
					: thisData?.map((item, i) => (
							<div
								key={i}
								className="tw-px-4 tw-py-2 tw-w-full tw-bg-[#F1F8FF] tw-cursor-pointer tw-rounded-lg">
								<div
									onClick={() => handleModule(i)}
									className="tw-h-16 tw-flex tw-justify-between tw-items-center tw-w-full">
									<div>
										<h6 className="tw-text-sm tw-text-main tw-font-medium">
											Module {i + 1}: {item?.title}
										</h6>
									</div>
									{moduleList === i ? (
										<AiOutlineDown size={10} />
									) : (
										<AiOutlineRight size={10} />
									)}
								</div>
								{moduleList === i && (
									<ul className="tw-px-4 tw-transition-all tw-bg-white tw-ease-linear tw-duration-500">
										{item?.section?.map((item, u) => (
											<li
												key={u}
												style={{
													borderBottom: "0.5px solid rgba(37, 37, 37, 0.50)",
												}}
												className="tw-py-2 tw-flex tw-justify-between tw-items-center">
												{" "}
												<div className="tw-flex tw-gap-3">
													<div onClick={() => setActiveSection(item)}>
														<h6 className="tw-text-sm tw-font-normal tw-text-main">
															Section {u + 1}. {item?.title || item?.topic}
														</h6>
														<div className="tw-flex tw-gap-1 tw-items-center">
															<LuMonitorPlay size={15} />
															<small className="tw-text-xs tw-text-main tw-font-light">
																{item?.duration}
															</small>
														</div>
													</div>
												</div>
												<div>{item?.locked ? <CiLock /> : null}</div>
											</li>
										))}
									</ul>
								)}
							</div>
					  ))}
			</div>
		</div>
	);
};

export default Modulestab;
