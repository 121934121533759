import React, { useState, useEffect } from "react";

import { motion } from "framer-motion";

import { Link, useNavigate } from "react-router-dom";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";

// import login_image from "../../assets/image22.png";
import useAuthStore from "../../data/stores/authstore";
// import PageFooter from "../../components/pagefooter";
import { toast } from "react-toastify";
import axios from "axios";
import useGenFetcher from "../../data/useGenFetcher";
import useErrorStore from "../../data/stores/errorstore";
import { Button } from "../../components/button";

const Login = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const init = {
      email: "",
      password: "",
    },
    [state, setState] = useState(init),
    textChange = (e) => {
      let { name, value } = e.target;
      setState({ ...state, [name]: value });
    };
  const [show, setShow] = useState(false),
		{ isAuth, login, setUserFail, setUserPage } = useAuthStore(),
		{ loadUser } = useGenFetcher(),
		{ returnErrors } = useErrorStore(),
		navigate = useNavigate(),
		[loading, setLoading] = useState(false);

	useEffect(() => {
		if (isAuth) {
			navigate("/");
		}
	});
	const handleSubmit = async e => {
		// setLoading(true);
		// const token = "zeno-auth-token";
		// localStorage.setItem("token", token);
		// login(token);
		// setLoading(false);
		e?.preventDefault();
		if (!state?.email || !state?.password)
			return toast.info("Please fill all required fields");
		setLoading(true);
		try {
			let res = await axios.post(`/api/v1/user/login`, { ...state });
			console.log({ resp: res?.data });
			toast.success(res?.data?.message);
			let privi = res?.data?.data?.privilege || res?.data?.privilege;
			setUserPage(
				privi?.includes("instructor")
					? "instructor"
					: privi?.includes("institution")
					? "institution"
					: "student"
			);
			// setUserPage("student");
			login(res?.data);
			loadUser();
			navigate("/");
		} catch (err) {
			if (err?.response?.status === 429 || err?.response?.status === 405)
				toast.error(err?.response?.data ? err?.response?.data : err?.message);
			console.log({ err });
			let error = err.response?.data?.error;
			if (error && error?.length > 1) {
				returnErrors(error);
			} else {
				toast.error(err?.response?.data?.message);
			}
			setUserFail();
		}
		setLoading(false);
	};

  return (
    <div className="">
      <div className="lg:tw-flex tw-hidden tw-justify-center tw-items-center tw-h-screen mx-0 fullHeight">
        <div className="tw-w-96">
          <motion.div
            className="m-auto w-100 h-100"
            style={{
              maxWidth: "550px",
            }}
            initial={{
              x: 250,
            }}
            animate={{
              x: 0,
            }}
            transition={{
              duration: 1,
              delay: 1,
              type: "tween",
            }}
          >
            <div
              style={{
                background: "rgba(255, 255, 255, 0.2)",
                border: "1px solid #0B2239",
                backdropFilter: "blur(7px)",
                borderRadius: "20px",
              }}
              className="border-bottom-4-color  tw-p-10 py-md-5 "
            >
              <div className="">
                <h2 className="text-capitalize fw-bold text-black">
                  Welcome back!
                </h2>
                <p className="text-black pb-4">
                  Sign in to continue learning game
                </p>
              </div>

              <form>
                <div className="tw-w-full">
                  <p className="t tw-text-left tw-font-medium tw-text-sm tw-text-black">
                    Email Address
                  </p>
                  <input
                    type="email"
                    className="form-control py-3 bg-transparent tw-text-black"
                    placeholder="Email"
                    value={state?.email}
                    onChange={textChange}
                    name="email"
                  />
                </div>
                <div className="tw-w-full tw-mt-4 tw-relative">
                  <p className="t tw-text-left tw-font-medium tw-text-sm tw-text-black">
                    Password
                  </p>
                  <input
                    type={show ? "text" : "password"}
                    className="form-control tw-w-full py-3 bg-transparent tw-text-black"
                    placeholder="Password"
                    value={state?.password}
                    onChange={textChange}
                    name="password"
                  />
                  <EyeToggler
                    show={show}
                    toggleShow={() => setShow(!show)}
                    color={"black"}
                  />
                </div>
                <div className="tw-flex tw-justify-between tw-items-center tw-mt-4">
                  <div className="tw-flex tw-items-center tw-gap-2">
                    <input type="checkbox" name="remember" className="" />
                    <label
                      htmlFor="remember"
                      className="tw-text-sm tw-text-black tw-font-medium "
                    >
                      Remember Password
                    </label>
                  </div>
                  <div>
                    <small className="tw-cursor-pointer tw-text-[12px] sansation tw-text-black">
                      Forget password?
                    </small>
                  </div>
                </div>
                <div className="tw-w-full">
                  <Button
                    bg={"#0B2239"}
                    type="submit"
                    onClick={handleSubmit}
                    loading={loading}
                    children="Sign In"
                    // eslint-disable-next-line react/style-prop-object
                    css="tw-bg-[#0B2239] tw-w-full tw-h-14 tw-rounded-3xl tw-mt-7 tw-text-white tw-text-base tw-font-bold satoshi tw-flex tw-justify-center tw-items-center"
                  />
                </div>

                <div className="mx-auto w-75 tw-mt-4 text-black tw-text-center">
                  <span className=" pb-0 mb-0">Don't have an account?</span>
                  <Link
                    to="/register"
                    className=" f tw-font-bold tw-text-sm tw-text-[#404757] tw-underline"
                  >
                    Sign up
                  </Link>
                </div>
              </form>
            </div>
          </motion.div>
        </div>
      </div>

      {/* Mobile Login */}
      <div className="lg:tw-hidden tw-block tw-w-full tw-h-12 tw-bg-white fullHeight">
        <motion.div
          className="m-auto w-100 h-100"
          style={{
            maxWidth: "550px",
          }}
          initial={{
            x: 250,
          }}
          animate={{
            x: 0,
          }}
          transition={{
            duration: 1,
            delay: 1,
            type: "tween",
          }}
        >
          <div className="border-bottom-4-color  p-3 py-md-5 ">
            <div className="mb-4 w-75 mx-auto">
              <h2 className="text-capitalize fw-bold text-black">
                Welcome back!
              </h2>
              <p className="text-black pb-4">
                Sign in to continue learning game
              </p>
            </div>

            <form>
              <div className="mb-4 w-75 mx-auto">
                <p className="t tw-text-left tw-font-medium tw-text-sm tw-text-black">
                  Email Address
                </p>
                <input
                  type="email"
                  className="form-control py-3 bg-transparent tw-text-black"
                  placeholder="Email"
                  value={state?.email}
                  onChange={textChange}
                  name="email"
                />
              </div>
              <div className="mb-4 w-75 mx-auto tw-relative">
                <p className="t tw-text-left tw-font-medium tw-text-sm tw-text-black">
                  Password
                </p>
                <input
                  type={show ? "text" : "password"}
                  className="form-control py-3 bg-transparent tw-text-black"
                  placeholder="Password"
                  value={state?.password}
                  onChange={textChange}
                  name="password"
                />
                <EyeToggler
                  show={show}
                  toggleShow={() => setShow(!show)}
                  color={"black"}
                />
              </div>
              <div className="tw-flex tw-justify-between w-75 tw-mx-auto tw-mt-8  ">
                <div className="tw-flex tw-items-center tw-gap-2">
                  <input type="checkbox" name="remember" className="" />
                  <label
                    htmlFor="remember"
                    className="tw-text-sm tw-text-black tw-font-medium "
                  >
                    Remember Password
                  </label>
                </div>
                <div>
                  <p
                    onClick={() => navigate("/forgetpassword")}
                    className="tw-cursor-pointer tw-text-[12px] sansation tw-text-black"
                  >
                    Forget password?
                  </p>
                </div>
              </div>
              <div className="mb-4 w-75 mx-auto">
                {/* <button
									onClick={handleSubmit}
									className="tw-bg-main tw-w-56 tw-h-14 tw-rounded-3xl tw-mt-7 tw-text-white tw-text-base tw-font-bold satoshi">
									Sign in
								</button> */}
                <Button
                  type="submit"
                  onClick={handleSubmit}
                  loading={loading}
                  children="Sign In"
                  // eslint-disable-next-line react/style-prop-object
                  css="tw-bg-main tw-w-56 tw-h-14 tw-rounded-3xl tw-mt-7 tw-text-white tw-text-base tw-font-bold satoshi tw-flex tw-justify-center tw-items-center"
                />
              </div>

              <div className="mx-auto w-75 tw-mt-4 text-black tw-text-center">
                <span className=" pb-0 mb-0">Don't have an account?</span>
                <Link
                  to="/register"
                  className=" f tw-font-bold tw-text-sm tw-underline"
                >
                  Sign up
                </Link>
              </div>
              <div className="mx-auto w-75 tw-mt-4 text-black tw-text-center">
                <span className=" pb-0 mb-0">Want to verify?</span>
                <Link
                  to="/activate"
                  className=" f tw-font-bold tw-text-sm tw-underline"
                >
                  Activate here
                </Link>
              </div>
            </form>
          </div>
        </motion.div>
      </div>
      {/* <PageFooter /> */}
    </div>
  );
};

export default Login;

export const EyeToggler = ({ toggleShow, show, color, css }) => {
  return (
    <span
      className={`tw-absolute tw-right-5 !tw-top-[55px] tw-cursor-pointer tw-text-mainShade ${
        css || ""
      }`}
      onClick={toggleShow}
    >
      {!show ? (
        <AiFillEye size={"20px"} color={color || "black"} />
      ) : (
        <AiFillEyeInvisible size={"20px"} color={color || "black"} />
      )}
    </span>
  );
};
