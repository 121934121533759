import React, { useState } from "react";
import MainContainer from "../../components/maincontainer";
import MainHeader from "../../components/mainheader";
import {
  OptionsInput,
  TextArea,
  TextInput,
  VideoInput,
} from "../../components/inputs";
import { MainBtn } from "../../components/button";

const AddCourse = () => {
  const [step, setStep] = useState(1),
    [moduleData, setModuleData] = useState({
      moduleName: "",
      courses: [],
    }),
    [courseData, setCourseData] = useState({
      courseName: "",
      quizzes: [],
    }),
    [quizData, setQuizData] = useState({
      quizName: "",
    }),
    handleModuleSubmit = (e) => {
      e.preventDefault();
      setStep(2);
    },
    handleCourseSubmit = (e) => {
      e.preventDefault();
      setCourseData({
        ...courseData,
        modules: [...courseData?.quizzes, moduleData],
      });
      setModuleData({
        moduleName: "",
        courses: [],
      });
      setStep(3);
    },
    handleQuizSubmit = (e) => {
      e.preventDefault();
      setModuleData({
        ...moduleData,
        courses: [...moduleData?.courses, quizData],
      });
      setQuizData({
        quizName: "",
      });
    };
  return (
    <div>
      <MainContainer>
        <MainHeader text="Courses" small={"Add a new course"} />
        <div className="tw-w-full tw-bg-white tw-rounded-3xl tw-p-4">
          {step === 1 && (
            <div>
              <h3 className="tw-text-base tw-font-bold tw-text-myblue satoshi">
                Add New Course<span className="tw-font-medium"> </span>
              </h3>
              <form action="" className="tw-mt-10 lg:tw-w-1/2">
                <div className="tw-space-y-4">
                  <TextInput label={"Name of Course"} />
                  <TextArea label={"Course overview"} />
                </div>
                <div className="tw-mt-8 tw-flex tw-gap-5 tw-items-center">
                  <MainBtn onClick={handleModuleSubmit} text={"Continue"} />
                  <h6 className="tw-text-base satoshi tw-font-medium tw-text-myblue tw-underline tw-cursor-pointer">
                    Add Another Course
                  </h6>
                </div>
              </form>
            </div>
          )}
          {step === 2 && (
            <div>
              <h3 className="tw-text-base tw-font-bold tw-text-myblue satoshi">
                Add New Course ~<span className="tw-font-medium"> Module</span>
              </h3>
              <form action="" className="tw-mt-10 lg:tw-w-1/2">
                <div className="tw-space-y-4">
                  <TextInput label={"Name of Course"} />
                  <TextArea label={"Course overview"} />
                  <VideoInput />
                </div>
                <div className="tw-mt-8 tw-flex tw-gap-5 tw-items-center">
                  <MainBtn onClick={handleCourseSubmit} text={"Continue"} />
                </div>
              </form>
            </div>
          )}
          {step === 3 && (
            <div>
              <h3 className="tw-text-base tw-font-bold tw-text-myblue satoshi">
                Add New Course ~
                <span className="tw-font-medium"> Module ~ Quiz </span>
              </h3>
              <form action="" className="tw-mt-10 lg:tw-w-1/2">
                <div className="tw-space-y-4">
                  <TextInput label={"Name of Course"} />
                  <div>
                    {" "}
                    <p className="tw-text-base tw-font-medium satoshi tw-text-secondary">
                      {"Answer Options"}
                    </p>
                    <div className="tw-flex tw-gap-2">
                      <div
                        style={{
                          border: "0.4px solid rgba(255, 255, 255, 0.20)",
                        }}
                        className="tw-flex tw-justify-between tw-rounded-xl tw-bg-[#E9EEF2] tw-items-center tw-px-2 tw-h-10 tw-w-36"
                      >
                        <small className="tw-text-secondary tw-text-sm">
                          Multiple answer
                        </small>
                        <input type="radio" name="" className="t" id="" />
                      </div>
                      <div
                        style={{
                          border: "0.4px solid rgba(255, 255, 255, 0.20)",
                        }}
                        className="tw-flex tw-justify-between tw-rounded-xl tw-bg-[#E9EEF2] tw-items-center tw-px-2 tw-h-10 tw-w-36"
                      >
                        <small className="tw-text-secondary tw-text-sm">
                          Multiple answer
                        </small>
                        <input type="radio" name="" className="t" id="" />
                      </div>
                    </div>
                    <div className="tw-mt-6 tw-space-y-4">
                      <OptionsInput label={"Option 1"} />
                      <OptionsInput label={"Option 2"} />
                      <OptionsInput label={"Option 3"} />
                      <OptionsInput label={"Option 4"} />
                    </div>
                  </div>
                </div>
                <div className="tw-mt-8 tw-flex tw-gap-5 tw-items-center">
                  <MainBtn onClick={handleQuizSubmit} text={"Upload Course"} />
                </div>
              </form>
            </div>
          )}
        </div>
      </MainContainer>
    </div>
  );
};

export default AddCourse;
