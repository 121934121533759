import React, { useEffect } from "react";
import MainContainer from "../../components/maincontainer";
import MainHeader from "../../components/mainheader";
import Img from "../../assets/details.png";
import { MainBtn } from "../../components/button";
import { useLocation, useNavigate } from "react-router-dom";
import { useContext } from "react";
import { GlobalState } from "../../data/Context";
import { useState } from "react";
import useErrorStore from "../../data/stores/errorstore";
import { apiCall } from "../../data/stores/authstore";
import { toast } from "react-toastify";
import { ContentWriteup } from "./[id]";

const Details = () => {
	const navigate = useNavigate(),
		{ state } = useLocation(),
		{ numberWithCommas } = useContext(GlobalState);

	useEffect(() => {
		if (!state) navigate(-1);
	}, [state, navigate]);

	let [loading, setLoading] = useState(null),
		{ returnErrors } = useErrorStore();

	let handleAddStudent = async e => {
		e?.preventDefault();
		setLoading(true);
		let { response, errArr, errMsg } = await apiCall(
			"put",
			`/api/v1/transactions/manage-partakers`,
			{
				course: state?._id,
			}
		);
		console?.log({ response, errArr, errMsg });
		if (errArr) {
			setLoading(false);
			return returnErrors(errArr);
		}
		if (errMsg) {
			setLoading(false);
			return toast.error(errMsg);
		}
		setLoading(false);
		if (response) {
			navigate("/courses");
			return toast.success(response?.msg || response?.message);
		}
	};

	return (
		<div>
			<MainContainer>
				<MainHeader />
				<>
					{" "}
					<div className="tw-flex tw-flex-col lg:tw-flex-row tw-gap-8 tw-mt-8 tw-w-full">
						<div className="lg:tw-w-[60%] tw-flex-none">
							<img
								src={
									state?.image?.url ||
									state?.module?.[0]?.image?.url ||
									state?.module?.[0]?.section?.[0]?.image?.url ||
									Img
								}
								alt=""
								className="tw-rounded-2xl"
							/>
							<div className="tw-mt-6">
								<h5 className="tw-text-base tw-font-normal satoshi tw-text-secondary">
									Learning Duration:{" "}
									<span className="tw-font-bold">
										{state?.courseDuration} week(s)
									</span>
								</h5>
								{!state?.organisation && (
									<h5 className="tw-text-base tw-font-normal satoshi tw-text-secondary">
										Starting fee:{" "}
										<span className="tw-font-bold">
											NGN{" "}
											{numberWithCommas(Number(state?.amount || 0).toFixed(2))}
										</span>
									</h5>
								)}
								{state?.isFree && (
									<p className="tw-text-base tw-text-secondary tw-font-normal satoshi">
										This course is absolutely free, however you won’t be able to
										obtain the certificate unless you made a payment to acquire
										the certificate.
									</p>
								)}
								<div className="tw-mt-10">
									<MainBtn
										text={"Get Started"}
										onClick={
											state?.organisation
												? handleAddStudent
												: () => navigate("/checkout", { state })
										}
										loading={loading}
									/>
								</div>
							</div>
						</div>
						<div
							style={{
								border: "0.5px solid rgba(2, 105, 208, 0.50)",
							}}
							className="lg:tw-w-[40%] tw-flex-1 tw-p-6">
							<h2 className="tw-text-2xl tw-font-extrabold tw-text-secondary satoshi">
								{state?.title}
							</h2>
							{/* <p className="tw-text-base tw-text-secondary tw-font-normal">
								{state?.description}{" "}
							</p> */}
							{ContentWriteup(
								state?.description,
								"tw-text-base tw-text-secondary tw-font-normal"
							)}
							<h2 className="tw-text-2xl tw-font-extrabold tw-text-secondary satoshi">
								What You will Learn
							</h2>
							<p className="tw-text-base tw-text-secondary tw-font-normal tw-hidden">
								HRM students learn how to motivate and enhance employees’
								contribution in order to maximise the productivity of an
								organisation. Courses focus on organisational behaviour, and how
								it can be used to create a positive atmosphere within companies.
								An important skill in this field is managing people according to
								different workplace cultures. This involves flexibility and
								understanding in order to adapt to social and work environments,
								while also strengthening the
							</p>
						</div>
					</div>
				</>
			</MainContainer>
		</div>
	);
};

export default Details;
