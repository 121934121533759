import React from "react";
import { ClipLoader } from "react-spinners";
import ReactPaginate from "react-paginate";

export const MainPaginate = ({ handlePageClick, pageCount }) => (
  <div className="tw-flex tw-justify-center tw-items-center tw-py-10">
    <ReactPaginate
      breakLabel="..."
      onPageChange={handlePageClick}
      pageRangeDisplayed={5}
      pageCount={pageCount}
      renderOnZeroPageCount={null}
      pageClassName="tw-h-10 tw-w-10 tw-flex tw-items-center tw-justify-center tw-rounded-full tw-text-xl tw-font-medium manrope tw-bg-white tw-border tw-border-main tw-text-main tw-mx-2 tw-cursor-pointer"
      className="tw-flex tw-items-center tw-justify-center"
      previousClassName="tw-hidden"
      nextClassName="tw-hidden"
      activeClassName="tw-bg-[#021C2f] tw-text-white"
    />
  </div>
);

const BUTTON_TYPES = {
  primary: "tw-bg-skyblue tw-text-white",
  gray: "tw-bg-gray-300 tw-text-black",
  secondary:
    "tw-border tw-border-skyblue tw-text-skyblue hover:tw-bg-skyblue hover:tw-text-white",
  white: "tw-bg-white tw-text-skyblue",
};

export const Button = ({
  buttonType,
  bg,
  children,
  css,
  icon,
  ...restProps
}) => {
  return (
    <button
      type={restProps?.type || "button"}
      disabled={restProps?.loading || restProps?.disabled}
      className={`tw-h-10 tw-px-4 tw-bg-[#0B2239] tw-rounded-3xl tw-text-base satoshi tw-font-medium tw-text-white tw-flex tw-items-center ${
        icon || restProps?.loading ? "tw-gap-2" : ""
      } ${css || "tw-h-10"} ${BUTTON_TYPES[buttonType]}`}
      style={{
        background: `${bg} !important`,
        boxShadow: "0px 5px 7.3px 0px rgba(5, 76, 166, 0.20)",
        ...restProps?.style,
      }}
      {...restProps}
    >
      <span>{icon}</span>
      <small
        className={
          restProps?.cssChild ||
          "tw-text-white tw-font-semibold manrope tw-text-sm"
        }
      >
        {children}
      </small>
      {restProps?.loading && (
        <ClipLoader color={restProps?.loadCss || "white"} size={16} />
      )}
    </button>
  );
};

const Buttons = () => {
  return <div></div>;
};

export const MainBtn = ({ onClick, text,...restProps }) => {
  return (
		<div>
			<button
				type={restProps?.type || "button"}
				disabled={restProps?.loading || restProps?.disabled}
				style={{
					boxShadow: "0px 5px 7.3px 0px rgba(5, 76, 166, 0.20)",
				}}
				onClick={onClick}
				className={`tw-h-10 tw-px-4 tw-bg-skyblue tw-rounded-3xl tw-text-base satoshi tw-font-medium tw-text-white ${
					restProps?.loading ? "tw-gap-2" : ""
				}`}
				{...restProps}>
				{text}
				{restProps?.loading && (
					<ClipLoader color={restProps?.loadCss || "white"} size={16} />
				)}
			</button>
		</div>
	);
};

export const Btn = ({ onClick, text }) => {
  return (
    <>
      <button
        onClick={onClick}
        style={{
          background: "rgba(2, 105, 208, 0.10)",
        }}
        className="tw-w-36 tw-h-10 tw-rounded-3xl tw-text-[#0269D0] tw-text-base tw-font-medium"
      >
        {text}
      </button>
    </>
  );
};

export default Buttons;

export const Tablebtn = ({ onClick, text }) => {
  return (
    <div>
      <button
        style={{
          boxShadow: "0px 5px 7.3px 0px rgba(5, 76, 166, 0.20)",
        }}
        onClick={onClick}
        className="tw-h-6 tw-px-4 tw-bg-skyblue tw-rounded-3xl tw-text-xs satoshi tw-font-medium tw-text-white"
      >
        {text}
      </button>
    </div>
  );
};

export const DeleteBtn = ({ onClick, text, ...restProps }) => {
	return (
		<div>
			<button
				onClick={onClick}
				className={`tw-h-10 tw-px-4 tw-bg-[#E3403333] tw-rounded-3xl tw-capitalize tw-text-base satoshi tw-font-medium tw-text-[#E34033] ${
					restProps?.loading ? "tw-flex tw-items-center tw-gap-2" : ""
				}`}
				type={restProps?.type || "button"}
				disabled={restProps?.loading || restProps?.disabled}
				{...restProps}>
				{text}
				{restProps?.loading && (
					<ClipLoader color={restProps?.loadCss || "white"} size={16} />
				)}
			</button>
		</div>
	);
};

export const Addbutton = ({ onClick, text, space, icon }) => {
	// const navigate = useNavigate();
	return (
		<div>
			<button
				style={{
					background: "black !important",
				}}
				className={`tw-bg-black tw-btn tw-h-8 tw-shadow-xl tw-px-3 hover:tw-scale-110 hover:tw-transition hover:tw-transform hover:tw-ease-out hover:tw-duration-800 hover:tw-delay-400 tw-flex tw-justify-center tw-items-center tw-gap-3 tw-rounded-md tw-text-white tw-whitespace-nowrap tw-text-sm ${
					space ? "tw-mx-3" : ""
				}`}
				onClick={onClick}>
				<div>{icon || null}</div>
				<p className="tw-text-white tw-text-xs manrope tw-animate-pulse tw-my-auto tw-py-0">
					{text}
				</p>
			</button>
		</div>
	);
};

export const LoadMore = ({ handleLoadMore, next, loading }) => {
	return (
		<>
			{!next ? (
				""
			) : (
				<MainBtn
					onClick={handleLoadMore}
					text={loading ? "Loading..." : "Load More"}
					loading={loading}
					type={"button"}
				/>
			)}
		</>
	);
};