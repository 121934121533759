import React from "react";
import Sent from "../../assets/sent.svg";
import { AiOutlineClose } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { MainBtn } from "../button";

const SentModal = ({ handleClose, user, privilege }) => {
	const navigate = useNavigate("/");
	return (
		<div className="tw-fixed tw-z-50 tw-inset-0 tw-bg-myblue tw-bg-opacity-30 tw-flex tw-justify-center tw-h-screen tw-w-full tw-items-center">
			<div className="tw-bg-white tw-rounded-xl tw-w-72 tw-p-4">
				<AiOutlineClose className="tw-cursor-pointer" onClick={handleClose} />
				<img src={Sent} alt="" className="tw-mt-4" />
				<h6 className="tw-text-2xl tw-font-extrabold tw-pt-2 tw-text-main satosho">
					Invite Sent
				</h6>
				<p className="tw-text-base satoshi tw-font-normal tw-text-main">
					An invite has been sent to{" "}
					<span className="tw-font-bold">
						({user?.lastName || "Henry"} {user?.firstName || "Ajayi"} ){" "}
					</span>
					to be{" "}
					{privilege
						? privilege?.includes("admin")
							? "Admin"
							: privilege
						: "Staff"}
				</p>
				<div className="tw-mt-6 tw-float-right">
					<MainBtn
						onClick={privilege ? handleClose : () => navigate("/")}
						text={"Okay"}
					/>
				</div>
			</div>
		</div>
	);
};

export default SentModal;
