import React from "react";
import MainContainer from "../../components/maincontainer";
import MainHeader from "../../components/mainheader";
import { Rating } from "react-simple-star-rating";
import { useNavigate } from "react-router";

const Certificate = () => {
  const navigate = useNavigate();
  const Arr = [
    {
      name: "Leadership",
      amount: "₦4,000",
      date1: "23 May, 2020",
      date2: "23 May, 2023",
      rating: 4,
    },
    {
      name: "Leadership",
      amount: "₦4,000",
      date1: "23 May, 2020",
      date2: "23 May, 2023",
      rating: 4,
    },
    {
      name: "Leadership",
      amount: "₦4,000",
      date1: "23 May, 2020",
      date2: "23 May, 2023",
      rating: 4,
    },
    {
      name: "Leadership",
      amount: "₦4,000",
      date1: "23 May, 2020",
      date2: "23 May, 2023",
      rating: 4,
    },
    {
      name: "Leadership",
      amount: "₦4,000",
      date1: "23 May, 2020",
      date2: "23 May, 2023",
      rating: 4,
    },
    {
      name: "Leadership",
      amount: "₦4,000",
      date1: "23 May, 2020",
      date2: "23 May, 2023",
      rating: 4,
    },
    {
      name: "Leadership",
      amount: "₦4,000",
      date1: "23 May, 2020",
      date2: "23 May, 2023",
      rating: 4,
    },
    {
      name: "Leadership",
      amount: "₦4,000",
      date1: "23 May, 2020",
      date2: "23 May, 2023",
      rating: 4,
    },
  ];
  return (
		<div>
			<MainContainer>
				<MainHeader />
				<div className="tw-mt-8 tw-bg-white tw-p-8 tw-rounded-xl">
					<h2 className="tw-text-secondary tw-font-extrabold tw-text-xl">
						Certificates{" "}
						<span className="tw-text-sm tw-font-normal tw-hidden">
							({Arr.length})
						</span>
					</h2>
					<div className="tw-mt-8 tw-overflow-x-auto">
						<table className="tw-table-auto tw-min-w-full">
							<tbody className="tw-hidden">
								{Arr?.map((item, i) => (
									<tr
										style={{
											borderBottom: "1px solid rgba(1, 52, 103, 0.30)",
										}}
										key={i}
										className="tw-h-14">
										<td className="tw-text-sm tw-text-main">{item?.name}</td>
										<td className="tw-text-sm tw-text-main">{item?.amount}</td>
										<td className="tw-text-sm tw-text-main">{item?.date1}</td>
										<td className="tw-text-sm tw-text-main">{item?.date2}</td>
										<td className="">
											<Rating
												initialValue={item?.rating}
												onClick={() => null}
												fillColor="#fdcb53"
												size={16}
												readonly
											/>
										</td>
										<td className="tw-flex tw-justify-center tw-pt-3">
											<button
												onClick={() => navigate(`/certificates/${i}`)}
												className="tw-w-24 tw-h-8 tw-rounded-3xl tw-bg-[#0269D0] tw-text-base tw-font-medium tw-text-white">
												View
											</button>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</MainContainer>
		</div>
	);
};

export default Certificate;
