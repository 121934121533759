import React, { useState, useEffect, useCallback } from "react";
import MainContainer from "../../../components/maincontainer";
import MainHeader from "../../../components/mainheader";
import { Btn, Button, MainBtn } from "../../../components/button";
import CertModal from "../../../components/modal/certificate";
import Wrong from "../../../assets/close-square.svg";
import Right from "../../../assets/tick-square.svg";
import { useNavigate, useLocation } from "react-router-dom";
import useCourseStore from "../../../data/stores/coursestore";
import { toast } from "react-toastify";
import moment from "moment";
import useErrorStore from "../../../data/stores/errorstore";
import { apiCall } from "../../../data/stores/authstore";

const Quiz = () => {
	// const QuizData = [
	// 	{
	// 		question: "What is the name of your company?",
	// 		answers: [
	// 			"Businesscoded",
	// 			"ICSOusourcing",
	// 			"None",
	// 			"Security and commerce",
	// 		],
	// 		correctanswer: "None",
	// 	},
	// 	{
	// 		question: "Where do you stay in lagos?",
	// 		answers: [
	// 			"Businesscoded",
	// 			"ICSOusourcing",
	// 			"None",
	// 			"Security and commerce",
	// 		],
	// 		correctanswer: "None",
	// 	},
	// 	{
	// 		question: "What your Designation?",
	// 		answers: [
	// 			"Businesscoded",
	// 			"ICSOusourcing",
	// 			"None",
	// 			"Security and commerce",
	// 		],
	// 		correctanswer: "None",
	// 	},
	// ];

	const navigate = useNavigate(),
		{ state } = useLocation(),
		[datum, setDatum] = useState(null),
		{ data } = useCourseStore(),
		{ returnErrors } = useErrorStore(),
		[quizzer, setQuizzer] = useState(null);

	useEffect(() => {
		let findIt = data?.docs?.find(it => it?._id === state?._id);
		if (state?.quizType) {
			let findOne = findIt?.module?.find(ic => ic?._id === state?.quizType);
			if (findOne) {
				setDatum(findOne?.quiz?.questions);
				setQuizzer(findOne?.quiz);
			}
		} else {
			setQuizzer(findIt?.quiz);
			setDatum(findIt?.quiz?.questions);
		}
	}, [data, state]);
	// console.log({ state });
	if (!state) navigate(-1);

	const [currentQuest, setCurrentQuest] = useState(0),
		[answerArr, setAnswerArr] = useState(null),
		[isSelected, setIsSelected] = useState(null),
		[loading, setLoading] = useState(null),
		handleIsAnswer = it => {
			setIsSelected({ answer: it?._id, option: it?.option });
		},
		question = datum?.[currentQuest];

	const handleNext = useCallback(() => {
		if (isSelected) {
			let d = datum?.[currentQuest];
			let findQue = [
				...answerArr?.map(item =>
					item?.question === d?._id
						? {
								...item,
								option: isSelected?.answer,
								isVisited: true,
						  }
						: item
				),
			];
			setAnswerArr(findQue);
			if (currentQuest !== datum?.length - 1) {
				setIsSelected(null);
				setCurrentQuest(currentQuest + 1);
			} else {
				mainHandleSubmit();
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSelected, currentQuest, datum, answerArr]);
	const handlePrev = () => {
			if (currentQuest > 0) {
				setCurrentQuest(currentQuest - 1);
				setIsSelected(null);
			}
		},
		mainHandleSubmit = async e => {
			let d = datum?.[currentQuest];
			let findQue = [
				...answerArr?.map(item =>
					item?.question === d?._id
						? {
								...item,
								option: isSelected?.answer,
								isVisited: true,
						  }
						: item
				),
			];
			setLoading(true);

			let { response, errArr, errMsg } = await apiCall(
				"post",
				`/api/v1/course/manage-quiz-answer`,
				{
					answers: findQue,
					quiz: quizzer?._id,
					timeSpent: moment().diff(null, "minutes"),
				}
			);
			console?.log({ response, errArr, errMsg });
			if (errArr) {
				setLoading(false);
				return returnErrors(errArr);
			}
			if (errMsg) {
				setLoading(false);
				return toast.error(errMsg);
			}
			setLoading(false);
			if (response) {
				setCurrentQuest(currentQuest + 1);
				return;
			}
			setLoading(false);
		};

	useEffect(() => {
		if (isSelected && datum?.length - 1 !== currentQuest) {
			const timer = setTimeout(() => handleNext(), 1000);
			return () => clearTimeout(timer);
		}
	}, [isSelected, handleNext, currentQuest, datum]);

	useEffect(() => {
		if (datum) {
			setAnswerArr([
				...datum?.map(item => {
					return { question: item?._id };
				}),
			]);
		}
	}, [datum]);

	if (!datum) return <></>;

	return (
		<div>
			<MainContainer>
				<MainHeader />
				<div className="tw-bg-white tw-rounded-xl tw-h-screen tw-p-6">
					{currentQuest < datum?.length ? (
						<div>
							<h1 className="tw-text-base tw-border-b-2 tw-pb-3 tw-font-bold tw-text-secondary">
								Question {currentQuest + 1} out of {datum?.length}
							</h1>
							<div className="tw-mt-5">
								<h5 className="tw-text-base tw-font-medium tw-text-secondary">
									{question?.question}
								</h5>
								<div className="tw-mt-4 tw-space-y-">
									{question?.options?.map((answer, o) => (
										<div className="tw-flex tw-gap-2 tw-items-center" key={o}>
											<input
												type="radio"
												name="answer"
												value={"answer"}
												checked={
													isSelected?.answer === answer?._id &&
													isSelected?.option === answer?.option
												}
												onChange={() => handleIsAnswer(answer)}
												className="tw-h-4 tw-w-4 tw-border-secondary"
												id=""
											/>
											<p className="tw-text-secondary tw-pt-3 tw-text-sm tw-font-normal">
												{answer?.option || answer}
											</p>
										</div>
									))}
								</div>
								<div className="tw-flex tw-mt-4 tw-gap-4">
									{currentQuest > 0 && (
										<Btn onClick={handlePrev} text={"Previous"} />
									)}

									<Button
										loading={loading}
										onClick={handleNext}
										children={
											currentQuest === datum?.length - 1 ? "Finish" : "Next"
										}
									/>
								</div>
							</div>
						</div>
					) : (
						<div className="">
							<AnswerBoard datum={{ questions: datum, answer: answerArr }} />
						</div>
					)}
				</div>
			</MainContainer>
		</div>
	);
};

const AnswerBoard = ({ datum }) => {
	// console.log({ datum });
	const [modal, setModal] = useState(""),
		navigate = useNavigate(),
		[score, setScore] = useState(0);

	useEffect(() => {
		if (datum?.questions && datum?.answer) {
			let answer = 0;
			for (let fc = 0; fc < datum?.questions?.length; fc++) {
				let element = datum?.questions?.[fc];

				let opt = element?.options?.find(it => {
					let findIn = datum?.answer?.find(cc => cc?.option === it?._id);
					// console.log({ findIn });
					return findIn?.option === it?._id;
				});

				if (opt?.isCorrect) answer += 1;
			}
			setScore((answer / datum?.questions?.length) * 100);
		}
	}, [datum?.questions, datum?.answer]);

	useEffect(() => {
		if (!datum?.answer?.[0]?.option) {
			navigate("/courses");
		}
	}, [datum?.answer, navigate]);

	return (
		<>
			<h1 className="tw-text-base tw-border-b-2 tw-pb-3 tw-font-bold tw-text-secondary">
				Answers
			</h1>
			<div className="tw-flex tw-justify-between tw-w-full">
				<div className="tw-mt-5 tw-space-y-6 lg:tw-w-3/4">
					{datum?.questions?.map((answer, c) => (
						<div className="tw-w-full" key={c}>
							<h5 className="tw-text-xs tw-text-main tw-font-normal">
								Question {c + 1}
							</h5>
							<h3 className="tw-text-sm tw-font-medium tw-text-main">
								{answer?.question}
							</h3>
							<AnswerChecker
								option={answer?.options?.find(it => {
									let findIn = datum?.answer?.find(
										cc => cc?.option === it?._id
									);
									// console.log({ findIn });
									return findIn?.option === it?._id;
								})}
							/>
						</div>
					))}
					<div className="tw-mt-5">
						<MainBtn onClick={() => setModal("cert")} text={"Okay"} />
					</div>
				</div>
				<div
					style={{
						boxShadow: "0px 1px 24.2px 0px rgba(0, 0, 0, 0.15)",
					}}
					className="tw-h-40 tw-rounded-xl tw-w-52 tw-bg-[#00227A] tw-flex tw-justify-center tw-items-center">
					<div>
						<h5 className="tw-text-base tw-text-white tw-font-medium satoshi tw-text-center">
							Your Score
						</h5>
						<h1 className="tw-text-6xl tw-font-bold tw-text-white satoshi tw-text-center">
							{Number(score).toFixed(0)}%
						</h1>
					</div>
				</div>
			</div>
			{modal === "cert" && <CertModal handleClose={() => setModal("")} />}
		</>
	);
};
export default Quiz;

export const AnswerChecker = ({ option }) => {
	// console.log({ option });

	return (
		<>
			<div className="tw-flex tw-gap-2 tw-items-center tw-w-full">
				<div className=" tw-my-0 tw-py-0">
					{" "}
					{!option?.isCorrect ? (
						<img src={Wrong} alt="" className="tw-h-8" />
					) : (
						<img src={Right} alt="" className="tw-h-8" />
					)}
				</div>
				<p className="tw-text-main tw-text-sm tw-font-normal tw-w-full tw-my-0 tw-py-0">
					{option?.option}
				</p>
			</div>
		</>
	);
};
