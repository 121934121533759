import React from "react";
import MainContainer from "../../components/maincontainer";
import MainHeader from "../../components/mainheader";
import { SearchInput } from "../../components/inputs";
import GroupsTable from "../../components/tables/groupstable";

export const MainGroups = () => {
	return (
		<div>
			<MainContainer>
				<MainHeader text={"Groups"} />
				<div className="tw-w-full tw-mt-8 tw-bg-white tw-rounded-3xl tw-p-6">
					<div className="tw-flex tw-gap-8 tw-items-center">
						<strong className="tw-text-xl tw-font-bold tw-text-secondary satoshi">
							All Groups
						</strong>
						<SearchInput />
					</div>
					<div className="tw-mt-8">
						<GroupsTable />
					</div>
				</div>
			</MainContainer>
		</div>
	);
};

const Groups = () => <MainGroups />;

export default Groups;
