import React from "react";

import PageFooter from "../../components/pagefooter";
import PageHeader from "../../components/pageheader";

const leadership = () => {
  return (
    <div>
      <PageHeader />
      <Details />

      <PageFooter />
    </div>
  );
};

const Details = () => {
  return (
    <div>
      <div
        style={{
          backgroundImage: `url(${require("../../assets/bluebg.png")})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "100% 100%",
        }}
        className="tw-py-20"
      >
        <div className="container tw-grid lg:tw-grid-cols-2 tw-gap-6 ">
          <div className="tw-flex tw-justify-center tw-items-center">
            <img
              src={require("../../assets/courseslady.png")}
              className="tw-w-[40vh] lg:tw-w-full"
              alt=""
            />
          </div>
          <div className="tw-h-full tw-p-2 lg:tw-p-8">
            <p className="tw-text-3xl tw-font-bold tw-text-white mont tw-capitalize">
              Human Resources Management
            </p>
            <p className="tw-text-xl tw-font-thin tw-text-white tw-mt-8">
              Human Resource Management is a subdiscipline of Business &
              Management studies. HRM is an essential part of any business and
              deals with recruiting the right people for the right positions, as
              well as managing and offering guidance for employees during their
              stay in a company. As a future Human Resource Manager, it will be
              your job to make sure that the people you hire stay happy,
              motivated, continue to develop and advance professionally in the
              roles that fit them.
            </p>
            <p className="tw-text-xl tw-text-black lg:tw-text-white tw-mt-8">
              {" "}
              Learning Duration:{" "}
              <span className="tw-font-bold tw-text-2xl">8 week(s)</span>
            </p>
            <p className="tw-text-xl tw-text-black lg:tw-text-white tw-mb-8 tw-mt-6">
              {" "}
              Starting Fee:{" "}
              <span className="tw-font-bold tw-text-2xl">NGN 150,000,000</span>
            </p>
            <p className="tw-bg-[#0B2239] lg:tw-bg-white tw-text-xl tw-rounded-[70px] tw-text-white lg:tw-text-[#0B2239] tw-px-5 tw-py-3">
              Get Started
            </p>
          </div>
        </div>
      </div>

      <div className="container">
        <p className="tw-mt-20 tw-text-4xl tw-font-bold tw-text-[#0B2239]">
          What You will Learn
        </p>
        <p className="tw-mt-10 tw-text-lg tw-text-[#0B2239] tw-leading-loose">
          HRM students learn how to motivate and enhance employees’ contribution
          in order to maximise the productivity of an organisation. Courses
          focus on organisational behaviour, and how it can be used to create a
          positive atmosphere within companies. An important skill in this field
          is managing people according to different workplace cultures. This
          involves flexibility and understanding in order to adapt to social and
          work environments, while also strengthening the company culture
          values. <br />
          It’s also important to be aware and predict how decisions made by the
          company influence employees’ behaviour. It may also be your
          responsibility to offer counselling on how to minimise the negative
          impact of less popular decisions within the company you work for.
          Students who graduate Human Resource Management can engage in the
          following careers: human resource officer, office manager,
          occupational psychologist, career adviser, recruitment consultant,
          training and development officer, and others.
        </p>

        <p className="tw-mt-20 tw-mb-20 tw-text-4xl tw-font-bold tw-text-[#0B2239]">
          Other Courses
        </p>
        <div className="tw-gap-3 tw-flex tw-justify-between tw-flex-wrap">
          <div className=" tw-border-[1px] tw-border-[#0B2239] tw-py-7 tw-px-9 tw-rounded-2xl tw-mb-8">
            <p className="tw-text-2xl">LeaderShip</p>
          </div>
          <div className=" tw-border-[1px] tw-border-[#0B2239] tw-py-7 tw-px-9 tw-rounded-2xl tw-mb-8">
            <p className="tw-text-2xl">Administration</p>
          </div>
          <div className=" tw-border-[1px] tw-border-[#0B2239] tw-py-7 tw-px-9 tw-rounded-2xl tw-mb-8">
            <p className="tw-text-2xl">Legal and Law</p>
          </div>
          <div className=" tw-border-[1px] tw-border-[#0B2239] tw-py-7 tw-px-9 tw-rounded-2xl tw-mb-8">
            <p className="tw-text-2xl">Customer Psychology</p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default leadership;
