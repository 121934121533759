import Uiux from "../../assets/uiux.png";
import Marketing from "../../assets/marketing.png";
import Developers from "../../assets/developer.png";
import Tech from "../../assets/tech.png";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import PageFooter from "../../components/pagefooter";
import PageHeader from "../../components/pageheader";


const Blog = () => {
	const navigate = useNavigate();
	const [modal, setModal] = useState(false);
	const BlogArr = [
		{
			id: 1,
			image: Uiux,
			title: "Looking into your Future",
			description:
				"You can invest into your future by studying whenever and wherever you want.",
			path: "/uiux",
		},
		{
			id: 2,
			image: Marketing,
			title: "No Code Career Paths: Digital Marketing",
			description:
				"No-code platforms have revolutionized the digital marketing industry, offering marketers and entrepreneurs with no coding experience the ability to build websites, landing pages, and marketing automation workflows with ease. This...",
			path: "/marketing",
		},
		{
			id: 3,
			image: Developers,
			title: "Understanding the Roles of Front End and Back End Developers",
			description:
				"As the field of user interface (UI) and user experience (UX) design continues to evolve, a new career path is gaining momentum - the No-Code UI/UX career. With the rise of no-code tools and platforms, designers and developers are...",
			path: "/developers",
		},
		{
			id: 4,
			image: Tech,
			title: "How to Break into Tech in 3 months",
			description:
				"Quick Answer: Enrol in BCT Academy Here, you will receive the best training in highly sought-after tech skills for 2-3 months, as well as an immediate internship which will lead to your first tech job. Transitioning into...",
			path: "/tech",
		},
	];
	return (
		<div>
			<PageHeader	 handleCourses={() => setModal(true)} />
			<div className="tw-w-full">
				<div className="tw-py-10 tw-items-start lg:tw-items-center container  tw-flex tw-flex-col lg:tw-flex-row lg:tw-justify-between tw-justify-center">
					<div className="">
						<h6 className="tw-text-lg  tw-text-[#204E9B]  tw-tracking-[-0.022em]">
							Education: October 09, 2023
						</h6>
						<div className="tw-text-[#0B2239] ">
							<p className="tw-text-4xl tw-font-bold sansation tw-text-[#0B2239] ">
								Why Learning is the
							</p>
							<p className="tw-text-4xl tw-font-bold sansation tw-text-[#0B2239]">
								Major Pillar for the Future.
							</p>
						</div>
					</div>
					<div>
						<p className="tw-text-2xl tw-mb-5">You can invest in your future by studying with us <br/> whenever and wherever you want.</p>

						<Link to='/blogReadMore' className="tw-text-[#204E9B] tw-underline tw-text-2xl tw-font-bold">Read More</Link>
					</div>
				</div>
			</div>
			<div className="tw-w-full container">
				<img src={require('../../assets/blogBig.png')} alt="" />
			</div>

			<p className="tw-text-2xl container tw-mt-20 tw-font-bold">Other Stories</p>

			<div className="container tw-mt-8 ">
				<div className="tw-flex tw-flex-col lg:tw-flex-row tw-justify-between">
					<div className="tw-border-solid tw-border-[1px] tw-flex tw-border-[#7B8794] tw-p-4 tw-rounded-[70px] tw-w-full lg:tw-w-[500px]">
						<img alt="" src={require('../../assets/search-normal.png')} className="tw-w-[20px] tw-h-[20px]" />
						<input type='text' placeholder="Search" className="tw-border-none tw-ml-3 tw-text-lg" />
					</div>
					<div className="tw-flex tw-flex-wrap tw-mt-3 lg:tw-mt-0">
						<p className="tw-text-2xl tw-text-white tw-bg-[#204E9B] tw-flex tw-items-center tw-justify-center tw-px-6 tw-rounded-[70px] tw-mr-1 md:tw-mr-5">All</p>
						<p className="tw-text-2xl tw-text-[#204E9B] tw-bg-[#F2F8FF] tw-flex tw-items-center tw-justify-center tw-px-6 tw-rounded-[70px] tw-mr-1 md:tw-mr-5">Education</p>
						<p className="tw-text-2xl tw-text-[#204E9B] tw-bg-[#F2F8FF] tw-flex tw-items-center tw-justify-center tw-px-6 tw-rounded-[70px] tw-mr-1 md:tw-mr-5">Security</p>
						<p className="tw-text-2xl tw-text-[#204E9B] tw-bg-[#F2F8FF] tw-flex tw-items-center tw-justify-center tw-px-6 tw-rounded-[70px] ">Data</p>
						
					</div>
				</div>
			</div>
			<div className="tw-my-20 tw-grid lg:tw-grid-cols-3 tw-gap-8 container">
				{BlogArr?.map(item => (
					<div
						
						className="tw-rounded-3xl tw-relative"
						key={item.id}>
						<img src={item.image} alt="" className="tw-rounded-t-3xl tw-rounded-b-3xl" />
						<p className="tw-text-sm tw-mt-6">EDUCATION: October 09, 2023</p>
						<div className="tw-bg-white tw-py-3">
							<h3 className="tw-text-3xl tw-font-extrabold segoe tw-text-[#0B2239] tw-pr-10">
								{item.title}
							</h3>
							<p className="tw-text-lg tw-font-normal segoe tw-pt-6 tw-pr-14">
								{item.description}
							</p>
						</div>
						<div className="">
							<button
								onClick={() => navigate(item.path)}
								className="tw-h-10 tw-mt-10  tw-text-[#204E9B] tw-underline tw-text-lg tw-font-bold Nunito">
								Read More
							</button>
						</div>
					</div>
				))}
			</div>
			{/* {modal && <CourseModal handleclose={() => setModal(false)} />} */}
      <PageFooter />
		</div>
	);
};
export const PageHeader2 = ({ handleCourses }) => {
  return (
    <div>
      <div className="tw-bg-[#DBECFF] lg:tw-h-36 tw-h-16 tw-flex tw-items-center">
        <div className="container">
          <PageHeader handleCourses={handleCourses} />
        </div>
      </div>
    </div>
  );
};

export default Blog;
