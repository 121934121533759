import React, { useEffect, useRef, useState } from "react";
import Notification from "../assets/notification.svg";
import Avatar from "../assets/avatar.svg";
import { AiOutlineClose, AiOutlineDown } from "react-icons/ai";
import { SearchInput } from "./inputs";
import Profile from "../assets/profile.svg";
import Edit from "../assets/edit.svg";
import { MdLogout } from "react-icons/md";
import useAuthStore, { apiCall } from "../data/stores/authstore";
import { useNavigate } from "react-router";
import Upload from "../assets/upload.svg";
import { Button } from "./button";
import { toast } from "react-toastify";
import useErrorStore from "../data/stores/errorstore";
import { EyeToggler } from "../screens/login";
import { IoPencil } from "react-icons/io5";
import { AdminLogin } from "./modal/adminmodal";
import usePaymentStore from "../data/stores/paymentstore";
import moment from "moment";

const MainHeader = ({ text, small }) => {
	const [notification, setNotification] = useState(false),
		[profile, setProfile] = useState(false),
		[edit, setEdit] = useState(false),
		handleEdit = type => {
			setProfile(false);
			setEdit(type || "profile");
		},
		{ userPage, setUserPage, user } = useAuthStore(),
		navigate = useNavigate();

	return (
		<div>
			<div className="tw-flex tw-justify-between tw-items-center tw-h-16 tw-bg-transparent">
				<div>
					<strong className="lg:tw-text-2xl tw-text-sm tw-font-bold satoshi tw-text-[#0269D0] tw-capitalize">
						{text}
					</strong>
					<div>
						<small className="lg:tw-text-xs tw-text-[10px] tw-font-normal satoshi tw-text-[#0B2239]">
							{small}
						</small>
					</div>
				</div>
				<div className="tw-flex tw-gap-8 tw-items-center">
					<div className="tw-hidden lg:tw-block">
						<SearchInput />
					</div>
					{["company", "institution", "institution-admin"]?.includes(
						user?.privilege
					) && (
						<div
							style={{
								border: "0.5px solid rgba(1, 52, 103, 0.50)",
							}}
							className={`tw-h-8 tw-w-32 tw-rounded-lg tw-p-1 tw-grid ${
								user?.privilege === "company" ? "tw-grid-cols-2" : ""
							} tw-gap-3`}>
							{user?.privilege === "company" && (
								<button
									onClick={() => {
										navigate("/");
										setUserPage("student");
									}}
									className={`tw-text-xs satoshi tw-font-normal  tw-h-full tw-w-full ${
										userPage === "student"
											? "tw-bg-main tw-text-white tw-rounded"
											: "tw-text-main"
									}`}>
									Student
								</button>
							)}
							<button
								onClick={
									user?.privilege === "company"
										? () => {
												setEdit("add-admin");
										  }
										: () => {}
								}
								className={`tw-text-xs satoshi tw-font-normal  tw-h-full tw-w-full ${
									["company", "institution"]?.includes(userPage)
										? "tw-bg-main tw-text-white tw-rounded"
										: "tw-text-main"
								}`}>
								Admin
							</button>
						</div>
					)}
					<div className="tw-flex tw-gap-2">
						<img
							onClick={() => setNotification(!notification)}
							src={Notification}
							alt=""
							className="tw-cursor-pointer"
						/>
						<div
							onClick={() => setProfile(true)}
							className="tw-flex tw-cursor-pointer tw-items-center">
							<img src={Avatar} alt="" className="" />
							<AiOutlineDown />
						</div>
					</div>
				</div>
			</div>
			{notification && (
				<NotificationSection handleClose={() => setNotification(false)} />
			)}
			{profile && (
				<ProfileSection
					handleClose={() => setProfile(false)}
					handleEdit={handleEdit}
				/>
			)}
			{edit === "profile" && <EditSection handleClose={() => setEdit("")} />}
			{edit === "password" && (
				<EditPasswordSection handleClose={() => setEdit("")} />
			)}
			{edit === "add-admin" && (
				<AdminLogin
					handleModal={() => {
						setEdit("");
					}}
					handleClose={() => {
						setEdit("");
					}}
				/>
			)}
		</div>
	);
};

const NotificationSection = ({ handleClose }) => {
	// const Arr = [
	// 	{
	// 		title: "Coming Soon",
	// 		desc: "et, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea co",
	// 		date: "14th May, 2023",
	// 		time: "2:00PM",
	// 	},
	// 	{
	// 		title: "Coming Soon",
	// 		desc: "et, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea co",
	// 		date: "14th May, 2023",
	// 		time: "2:00PM",
	// 	},
	// 	{
	// 		title: "Coming Soon",
	// 		desc: "et, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea co",
	// 		date: "14th May, 2023",
	// 		time: "2:00PM",
	// 	},
	// 	{
	// 		title: "Coming Soon",
	// 		desc: "et, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea co",
	// 		date: "14th May, 2023",
	// 		time: "2:00PM",
	// 	},
	// 	{
	// 		title: "Coming Soon",
	// 		desc: "et, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea co",
	// 		date: "14th May, 2023",
	// 		time: "2:00PM",
	// 	},
	// ];

	let { getNotifications, notifications } = usePaymentStore();

	let [datum, setDatum] = useState(null);

	useEffect(() => {
		if (notifications?.docs) setDatum(notifications?.docs);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [notifications]);

	useEffect(() => {
		apiCall(
			"get",
			"/api/v1/notification?type=incoming",
			null,
			getNotifications
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="lg:tw-absolute tw-transition-transform  tw-duration-500 lg:tw-p-5 tw-p-2 lg:tw-right-2 tw-z-50  tw-rounded-xl tw-shadow-lg tw-bg-white lg:tw-w-1/3 tw-w-11/12 tw-mx-auto lg:tw-mx-0 tw-h-screen tw-overflow-y-scroll noScroll">
			<div className="tw-min-h-full">
				<div className="tw-mb-10 tw-float-right">
					<AiOutlineClose className="tw-cursor-pointer" onClick={handleClose} />
				</div>
				<h3 className="tw-text-2xl tw-font-bold tw-text-main">Notifications</h3>
				<div className="tw-mt-8 tw-space-y-4">
					{datum?.map((item, i) => (
						<div
							key={i}
							style={{
								border: " 0.5px solid var(--Stokee, rgba(78, 83, 94, 0.50))",
							}}
							className="tw-rounded-lg tw-p-3">
							<h5 className="tw-text-base tw-font-bold tw-text-main tw-capitalize">
								{item?.title}
							</h5>
							<p className="tw-text-sm tw-text-main">{item?.message}</p>
							<div className="tw-flex tw-justify-between tw-w-full tw-items-center">
								<small className="tw-text-xs tw-text-main tw-font-medium">
									{moment(item?.createdAt).format("HH:mm A")}
								</small>
								<small className="tw-text-xs tw-text-main tw-font-medium">
									{moment(item?.createdAt).format("Do MMMM, YYYY")}
								</small>
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

const ProfileSection = ({ handleClose, handleEdit }) => {
	const { logout, user } = useAuthStore(),
		navigate = useNavigate(),
		handleLogout = () => {
			logout();
			navigate("/");
		};
	return (
		<div className="lg:tw-absolute tw-relative tw-transition-transform  tw-duration-500 lg:tw-p-5 tw-p-2 lg:tw-right-2 tw-rounded-xl tw-shadow-lg tw-bg-white lg:tw-w-1/3 tw-w-11/12 tw-mx-auto lg:tw-mx-0 tw-z-[1000]">
			<div className="tw-float-right">
				<AiOutlineClose className="tw-cursor-pointer" onClick={handleClose} />
			</div>
			<div className="tw-flex tw-mt-8 tw-gap-4 tw-items-center">
				<img src={user?.image?.url || Profile} alt="" className="tw-h-28" />
				<div>
					<h5 className="tw-text-2xl tw-text-main tw-font-extrabold">
						{user?.firstName} {user?.lastName}
					</h5>
					<div className="tw-flex tw-gap-3 tw-items-center">
						<img
							onClick={() => handleEdit("profile")}
							src={Edit}
							alt=""
							className="tw-cursor-pointer"
						/>
						<span
							onClick={() => handleEdit("password")}
							className="tw-cursor-pointer tw-flex tw-items-center tw-bg-blue-100">
							Edit Password <IoPencil />
						</span>
					</div>
				</div>
			</div>
			<div
				onClick={handleLogout}
				className="tw-absolute tw-cursor-pointer tw-right-8 tw-bottom-5 tw-flex tw-items-center tw-gap-3">
				<MdLogout />
				<small className="tw-text-sm satoshi tw-capitalize tw-font-normal tw-text-secondary">
					Logout
				</small>
			</div>
		</div>
	);
};

const EditSection = ({ handleClose }) => {
	// const navigate = useNavigate();

	let { user, setUser } = useAuthStore(),
		[state, setState] = useState({}),
		textChange = e => {
			let { value, name } = e?.target;
			setState({ ...state, [name]: value });
		},
		[loading, setLoading] = useState(false),
		{ returnErrors } = useErrorStore();

	let handleChangeImage = e => {
		const file = e.target.files[0];
		let err = "";

		if (!file) return (err = `File, ${file?.name} does not exist`);
		if (!file.type.includes("image"))
			return (err = `File, ${file?.name} format not supported`);

		if (err) {
			return toast.error(err);
		} else {
			// setLogo(file);

			setState(prevRows => {
				let newRows = { ...prevRows };
				newRows.image = file;
				return newRows;
			});
		}
	};

	let handleSubmit = async e => {
		setLoading(true);

		let newState = { ...state };

		if (state?.image) {
			let { response, errArr, errMsg } = await apiCall("file", `/api/v1/file`, {
				logo: state?.image,
			});
			// console.log({ response, errArr, errMsg });
			if (errArr) {
				setLoading(false);
				return returnErrors(errArr);
			}
			if (errMsg) {
				setLoading(false);
				return toast.error(errMsg);
			}
			setLoading(false);
			if (response) {
				newState.image = response?.data?.[0];
			}
			setLoading(false);
		}

		let { response, errArr, errMsg } = await apiCall(
			"put",
			`/api/v1/user`,
			newState
		);
		// console.log({ response, errArr, errMsg });
		if (errArr) {
			setLoading(false);
			return returnErrors(errArr);
		}
		if (errMsg) {
			setLoading(false);
			return toast.error(errMsg);
		}
		setLoading(false);
		if (response) {
			setUser(response);
			setState({});
			handleClose();
			return;
		}
		setLoading(false);
	};

	return (
		<div className="lg:tw-absolute tw-relative tw-transition-transform  tw-duration-500 lg:tw-p-5 tw-p-2 lg:tw-right-2 tw-z-50  tw-rounded-xl tw-shadow-lg tw-bg-white lg:tw-w-80 tw-w-11/12 tw-mx-auto lg:tw-mx-0">
			<div className="tw-float-right">
				<AiOutlineClose className="tw-cursor-pointer" onClick={handleClose} />
			</div>
			<div className="tw-mt-8">
				<h5 className="tw-text-base tw-text-secondary tw-font-bold">
					Edit profile
				</h5>
				<form action="" className="tw-mt-4">
					<div>
						<p className="tw-text-sm tw-text-secondary tw-font-medium">
							First name
						</p>
						<input
							type="text"
							name="firstName"
							value={state?.firstName || user?.firstName}
							onChange={textChange}
							className="tw-w-full placeholder:tw-text-gray-300 tw-pl-4 tw-h-10 tw-rounded-lg tw-text-sm tw-text-secondary tw-font-normal"
							placeholder="Henry"
							style={{
								border: "0.5px solid var(--Stokee, rgba(78, 83, 94, 0.50))",
							}}
							id=""
						/>
					</div>
					<div>
						<p className="tw-text-sm tw-text-secondary tw-font-medium">
							Last name
						</p>
						<input
							name="lastName"
							value={state?.lastName || user?.lastName}
							onChange={textChange}
							type="text"
							className="tw-w-full placeholder:tw-text-gray-300 tw-pl-4 tw-h-10 tw-rounded-lg tw-text-sm tw-text-secondary tw-font-normal"
							placeholder="Henry"
							style={{
								border: "0.5px solid var(--Stokee, rgba(78, 83, 94, 0.50))",
							}}
							id=""
						/>
					</div>
					{/* <div>
						<p className="tw-text-sm tw-text-secondary tw-font-medium">
							Telephone
						</p>
						<input
							name="telephone"
							value={state?.telephone || user?.telephone}
							onChange={textChange}
							type="tel"
							className="tw-w-full placeholder:tw-text-gray-300 tw-pl-4 tw-h-10 tw-rounded-lg tw-text-sm tw-text-secondary tw-font-normal"
							placeholder="Henry"
							style={{
								border: "0.5px solid var(--Stokee, rgba(78, 83, 94, 0.50))",
							}}
							id=""
						/>
					</div> */}
					<div className="tw-mt-5">
						<ImageInput
							name={"image"}
							state={state?.image}
							onChange={handleChangeImage}
						/>
					</div>
					<div className="tw-mt-5">
						{/* <MainBtn onClick={() => navigate("/")} text={"Update Profile"} /> */}
						<Button
							onClick={handleSubmit}
							loading={loading}
							children={"Update Profile"}
							css="tw-h-10 tw-w-60 tw-bg-skyblue tw-rounded-3xl tw-text-base satoshi tw-font-medium tw-text-white tw-flex tw-justify-center tw-capitalize tw-mb-3"
						/>
					</div>
				</form>
			</div>
		</div>
	);
};

export const ImageInput = ({ name, onChange, state }) => {
	const ref = useRef();
	const handleClick = () => {
		ref.current?.click();
	};
	return (
		<div>
			<p className="tw-text-base tw-font-medium satoshi tw-text-secondary">
				Profile Picture
			</p>
			<div
				onClick={handleClick}
				className="tw-h-24 tw-cursor-pointer tw-rounded-xl tw-border-secondary tw-border tw-w-full tw-flex tw-justify-center tw-items-center">
				<div className="">
					{state ? (
						<>
							<img
								src={URL.createObjectURL(state)}
								alt=""
								className="tw-mx-auto tw-max-h-24"
							/>
						</>
					) : (
						<>
							<img src={Upload} alt="" className="tw-mx-auto" />
							<small className="tw-text-sm tw-text-[#275A7F] tw-font-medium satoshi tw-text-center">
								Click to upload
							</small>
							<h6 className="tw-text-xs tw-text-[#275A7F] tw-font-medium satoshi tw-text-center">
								(jpg, png)
							</h6>
						</>
					)}
					<input
						type="file"
						name={name}
						onChange={onChange}
						ref={ref}
						accept=".png, .jpg, .png"
						id=""
						className="tw-hidden"
					/>
				</div>
			</div>
		</div>
	);
};

const EditPasswordSection = ({ handleClose }) => {
	// const navigate = useNavigate();

	let [show, setShow] = useState(false),
		[state, setState] = useState({}),
		textChange = e => {
			let { value, name } = e?.target;
			setState({ ...state, [name]: value });
		},
		[loading, setLoading] = useState(false),
		{ returnErrors } = useErrorStore();

	let handleSubmit = async e => {
		setLoading(true);

		let newState = { ...state };

		let { response, errArr, errMsg } = await apiCall(
			"put",
			`/api/v1/user/update-password`,
			newState
		);
		// console.log({ response, errArr, errMsg });
		if (errArr) {
			setLoading(false);
			return returnErrors(errArr);
		}
		if (errMsg) {
			setLoading(false);
			return toast.error(errMsg);
		}
		setLoading(false);
		if (response) {
			setState({});
			handleClose();
			return;
		}
		setLoading(false);
	};

	return (
		<div className="lg:tw-absolute tw-relative tw-transition-transform  tw-duration-500 lg:tw-p-5 tw-p-2 lg:tw-right-2 tw-z-50  tw-rounded-xl tw-shadow-lg tw-bg-white lg:tw-w-80 tw-w-11/12 tw-mx-auto lg:tw-mx-0">
			<div className="tw-float-right">
				<AiOutlineClose className="tw-cursor-pointer" onClick={handleClose} />
			</div>
			<div className="tw-mt-8">
				<h5 className="tw-text-base tw-text-secondary tw-font-bold">
					Edit password
				</h5>
				<form className="tw-mt-4">
					<div className="mb-4 w-75 mx-auto tw-relative">
						<p className="t tw-text-left tw-font-medium tw-text-sm tw-text-black">
							Old Password
						</p>
						<input
							type={show ? "text" : "password"}
							className="form-control py-3 bg-transparent tw-text-black"
							placeholder="Password"
							value={state?.oldPassword}
							onChange={textChange}
							name="oldPassword"
						/>
						<EyeToggler
							show={show}
							toggleShow={() => setShow(!show)}
							color={"black"}
						/>
					</div>
					<div className="mb-4 w-75 mx-auto tw-relative">
						<p className="t tw-text-left tw-font-medium tw-text-sm tw-text-black">
							New Password
						</p>
						<input
							type={show ? "text" : "password"}
							className="form-control py-3 bg-transparent tw-text-black"
							placeholder="Password"
							value={state?.newPassword}
							onChange={textChange}
							name="newPassword"
						/>
						<EyeToggler
							show={show}
							toggleShow={() => setShow(!show)}
							color={"black"}
						/>
					</div>
					<div className="tw-mt-5">
						{/* <MainBtn onClick={() => navigate("/")} text={"Update Profile"} /> */}
						<Button
							onClick={handleSubmit}
							loading={loading}
							children={"Update Password"}
							css="tw-h-10 tw-w-60 tw-bg-skyblue tw-rounded-3xl tw-text-base satoshi tw-font-medium tw-text-white tw-flex tw-justify-center tw-capitalize tw-mb-3"
						/>
					</div>
				</form>
			</div>
		</div>
	);
};

export default MainHeader;
