import MainContainer from "../../components/maincontainer";
import React, {useState} from "react";
import MainHeader from "../../components/mainheader";
import {MainBtn} from "../../components/button";
import Total from "../../assets/totalcourse.svg";
import Student from "../../assets/totalstudent.svg";
import Sessiontable, {PastSessionTable} from "../../components/tables/sessiontable";
import AddSession from "../../components/modal/addsession";
import useCourseStore from "../../data/stores/coursestore";
import useSessionStore from "../../data/stores/sessionstore";
import useAuthStore from "../../data/stores/authstore";

const DashboardInstructors = () => {
	const [modal, setModal] = useState(""),
		courseStore = useCourseStore(),
		sessionStore = useSessionStore(),
		users = useAuthStore(),
		Arr = [
			{
				name: "total course",
				bg: "rgba(2, 105, 208, 0.08)",
				value: courseStore?.data?.totalDocs || 0,
				icon: Total,
			},
			{
				name: "Total live session",
				bg: "#F182D914",
				value: sessionStore?.institutionPending?.totalDocs || 0,
				icon: Student,
			},
		];

	return (
		<div>
			<MainContainer>
				<MainHeader
					text={`Welcome ${users?.user?.privilege || ""} ${
						users?.user?.firstName || ""
					} ${users?.user?.lastName || ""}`}
					small={"Dashboard"}
				/>
				<div className="tw-w-full tw-mt-8 tw-bg-white tw-rounded-3xl tw-p-4">
					<div className="tw-flex tw-justify-between tw-items-center">
						<h3 className="tw-text-xl tw-text-[#013467] tw-font-bold satoshi">
							Overview
						</h3>
						<MainBtn
							onClick={() => setModal("add")}
							text={"Schedule a Session"}
						/>
					</div>
					<div className={"tw-mt-6 tw-grid lg:tw-grid-cols-2 tw-gap-6"}>
						{Arr?.map((item, i) => (
							<div
								style={{
									background: item?.bg,
								}}
								key={i}
								className="tw-h-24 tw-rounded-lg tw-flex tw-items-center tw-gap-3 tw-pl-6">
								<div className="tw-h-10 tw-w-10 tw-rounded-full tw-bg-white tw-flex tw-justify-center tw-items-center tw-shadow-md">
									{" "}
									<img src={item?.icon} alt="" className="" />
								</div>
								<div>
									<h5 className="tw-text-main tw-pt-2 tw-capitalize tw-text-sm satoshi tw-font-medium">
										{item?.name}
									</h5>
									<h5 className="tw-text-main satoshi tw-font-medium tw-text-[32px]">
										{item?.value}
									</h5>
								</div>
							</div>
						))}
					</div>
					<SessionsMainTable />
				</div>
				{modal === "add" && <AddSession handleClose={() => setModal("")} />}
			</MainContainer>
		</div>
	);
};

export default DashboardInstructors;

export const SessionsMainTable = () => {
	return (
		<>
			<div
				style={{
					border: "1px solid #0134674D",
				}}
				className={"tw-mt-8 tw-p-6 tw-w-full"}>
				<h4 className={"tw-text-xl tw-font-bold tw-text-ics-blue satoshi"}>
					Upcoming Session
				</h4>
				<div className={"tw-mt-6"}>
					<Sessiontable />
				</div>
			</div>
			<div
				style={{
					border: "1px solid #0134674D",
				}}
				className={"tw-mt-8 tw-p-6 tw-w-full"}>
				<h4 className={"tw-text-xl tw-font-bold tw-text-ics-blue satoshi"}>
					Past Session
				</h4>
				<div className={"tw-mt-6"}>
					<PastSessionTable />
				</div>
			</div>
		</>
	);
};

export const SessionHeader = () => {
	const [modal, setModal] = useState(""),
		sessionStore = useSessionStore(),
		{ userPage } = useAuthStore();

	const Arr = [
		{
			name: "Total live session",
			bg: "rgba(2, 105, 208, 0.08)",
			value: sessionStore?.institutionPending?.totalDocs || 0,
			icon: Student,
		},
		{
			name: "Total past session",
			bg: "#F182D914",
			value: sessionStore?.institutionPast?.totalDocs || 0,
			icon: Student,
		},
	];
	return (
		<>
			<div className="tw-flex tw-justify-between tw-items-center">
				<h3 className="tw-text-xl tw-text-[#013467] tw-font-bold satoshi">
					Overview
				</h3>
				{userPage === "institution" && (
					<MainBtn
						onClick={() => setModal("add")}
						text={"Schedule a Session"}
					/>
				)}
			</div>
			<div className={"tw-mt-6 tw-grid lg:tw-grid-cols-2 tw-gap-6"}>
				{Arr?.map((item, i) => (
					<div
						style={{
							background: item?.bg,
						}}
						key={i}
						className="tw-h-24 tw-rounded-lg tw-flex tw-items-center tw-gap-3 tw-pl-6">
						<div className="tw-h-10 tw-w-10 tw-rounded-full tw-bg-white tw-flex tw-justify-center tw-items-center tw-shadow-md">
							{" "}
							<img src={item?.icon} alt="" className="" />
						</div>
						<div>
							<h5 className="tw-text-main tw-pt-2 tw-capitalize tw-text-sm satoshi tw-font-medium">
								{item?.name}
							</h5>
							<h5 className="tw-text-main satoshi tw-font-medium tw-text-[32px]">
								{item?.value}
							</h5>
						</div>
					</div>
				))}
			</div>
			{userPage === "institution" && modal === "add" && (
				<AddSession handleClose={() => setModal("")} />
			)}
		</>
	);
};