import React, { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
import useUserStore from "../../data/stores/userstore";
// import useCourseStore from "../../data/stores/coursestore";
import 
// useAuthStore,
 { apiCall } from "../../data/stores/authstore";
import { MainPaginate } from "../button";
import moment from "moment";

const Adminstable = () => {
	// const navigate = useNavigate();
	const Headers = [
			"name",
			"email",
			"Status",
			"date added",
			"Added by",
			"action",
		],
		data1 = [
			{
				name: "John Doe",
				email: "example@mail.com",
				department: "Fleet Management",
				date_added: "07 May, 2023",
				added_by: "Paul Kolapo",
			},
			{
				name: "John Doe",
				email: "example@mail.com",
				department: "Fleet Management",
				date_added: "07 May, 2023",
				added_by: "Paul Kolapo",
			},
			{
				name: "John Doe",
				email: "example@mail.com",
				department: "Fleet Management",
				date_added: "07 May, 2023",
				added_by: "Paul Kolapo",
			},
		];

	let [datum, setDatum] = useState({ docs: data1 }),
		{ isFound, mainSearch, admins, getAdmins } = useUserStore()
		// ,
		// { getAllCourse } = useCourseStore(),
		// { userPage } = useAuthStore();

	useEffect(() => {
		if (isFound) setDatum(mainSearch);
		else setDatum(admins);
	}, [admins, isFound, mainSearch]);

	useEffect(() => {
		apiCall(
			"get",
			`/api/v1/user/manage-users?privilege=institution-admin`,
			null,
			getAdmins
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// useEffect(() => {
	// 	apiCall(
	// 		"get",
	// 		`/api/v1/course?pagination=not${
	// 			userPage !== "student" ? `&company=${userPage}` : ""
	// 		}`,
	// 		null,
	// 		getAllCourse
	// 	);
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, []);

	const [itemOffset, setItemOffset] = useState(0);
	let [range] = useState(10);
	const endOffset = itemOffset + range;

	// if (!datum && !data) return <PageLoader />;
	if (!datum) return;

	const currentItems = datum?.docs?.slice(itemOffset, endOffset);
	const pageCount = Math.ceil(datum?.docs?.length / range);

	const handlePageClick = event => {
		const newOffset = (event.selected * range) % datum?.docs?.length;
		setItemOffset(newOffset);
	};

	return (
		<div className={"tw-overflow-x-auto"}>
			<table className="tw-min-w-full tw-min-h-full tw-table-auto">
				<thead>
					<tr>
						{Headers?.map(item => (
							<th className="tw-text-secondary tw-p-3 tw-font-medium satoshi tw-text-sm tw-capitalize">
								{item}
							</th>
						))}
					</tr>
				</thead>
				<tbody>
					{currentItems?.map((item, i) => (
						<tr
							style={{
								borderBottom: "1px solid rgba(1, 52, 103, 0.30)",
							}}
							key={i}>
							<td className="tableText">
								{item?.firstName} {item?.lastName}
							</td>
							<td className="tableText">{item?.email}</td>
							<td className="tableText tw-capitalize">{item?.statusText}</td>
							<td className="tableText">
								{moment(item?.createdAt).format("Do MMMM, YYYY")}
							</td>
							<td className="tableText">
								{item?.addedBy?.firstName} {item?.addedBy?.lastName}
							</td>
							<td>
								<div className="tw-h-8 tw-bg-[#FFECEB] tw-px-3 tw-rounded-full tw-flex tw-justify-center tw-items-center">
									<span
										className={
											"tw-text-sm tw-font-medium tw-text-[#E34033] satoshi"
										}>
										Remove
									</span>
								</div>
							</td>
						</tr>
					))}
				</tbody>
				{pageCount > 1 && (
					<MainPaginate
						pageCount={pageCount}
						handlePageClick={handlePageClick}
					/>
				)}
			</table>
		</div>
	);
};
export default Adminstable;
