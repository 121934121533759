import React from "react";
import { CourseDashTable, useCourseData } from "./userstable";

const Progresstable = () => {
	const Headers = [
		"course name",
		"no. of participants",
		"no. of ongoing",
		"no. of locked",
	];
	// data = [
	//   {
	//     course: "Leadership",
	//     participants: "49",
	//     ongoing: "56",
	//     locked: "89",
	//   },
	//   {
	//     course: "Leadership",
	//     participants: "49",
	//     ongoing: "56",
	//     locked: "89",
	//   },
	//   {
	//     course: "Leadership",
	//     participants: "49",
	//     ongoing: "56",
	//     locked: "89",
	//   },
	//   {
	//     course: "Leadership",
	//     participants: "49",
	//     ongoing: "56",
	//     locked: "89",
	//   },
	//   {
	//     course: "Leadership",
	//     participants: "49",
	//     ongoing: "56",
	//     locked: "89",
	//   },
	//   {
	//     course: "Leadership",
	//     participants: "49",
	//     ongoing: "56",
	//     locked: "89",
	//   },
	// ];
	let { courses2 } = useCourseData();

	if (!courses2) return <></>;

	return (
		<div>
			<div>
				<div>
					<div className=" tw-overflow-x-scroll">
						<table className="tw-min-w-full tw-min-h-full tw-table-auto">
							<thead>
								<tr>
									{Headers?.map(item => (
										<th className="tw-text-skyblue  tw-p-3 tw-font-medium satoshi tw-text-sm tw-capitalize">
											{item}
										</th>
									))}
								</tr>
							</thead>
							<tbody>
								{courses2?.map((item, i) => (
									<tr
										style={{
											borderBottom: "1px solid rgba(1, 52, 103, 0.30)",
										}}
										key={i}>
										<td className="tableText ">{item?.title}</td>
										<td className="tableText  tw-capitalize">
											{item?.partakers?.length || 0}
										</td>
										<td className="tableText  tw-capitalize">
											<CourseDashTable course={item?._id} />
										</td>{" "}
										<td className="tableText  tw-capitalize">
											<CourseDashTable course={item?._id} type={"lock-out"} />
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Progresstable;
