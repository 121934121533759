import React, { useEffect, useContext } from "react";
import MainContainer from "../../components/maincontainer";
import MainHeader from "../../components/mainheader";
import Img from "../../assets/details.png";
import { MainBtn } from "../../components/button";
import { useNavigate, useLocation } from "react-router-dom";
import { GlobalState } from "../../data/Context";
import { ContentWriteup } from "../../pages/courses/[id]";

const Details = () => {
	const navigate = useNavigate(),
		{ state } = useLocation(),
		{ numberWithCommas } = useContext(GlobalState);

	useEffect(() => {
		if (!state) navigate(-1);
	}, [state, navigate]);

	return (
		<div>
			<MainContainer>
				<MainHeader />
				<>
					{" "}
					<div className="tw-flex tw-flex-col lg:tw-flex-row tw-gap-8 tw-mt-8 tw-w-full">
						<div className="lg:tw-w-[60%] tw-flex-none">
							<img
								src={
									state?.image?.url ||
									state?.module?.[0]?.image?.url ||
									state?.module?.[0]?.section?.[0]?.image?.url ||
									Img
								}
								alt=""
								className="tw-rounded-2xl"
							/>
							<div className="tw-mt-6">
								<h5 className="tw-text-base tw-font-normal satoshi tw-text-secondary">
									Learning Duration:{" "}
									<span className="tw-font-bold">
										{state?.courseDuration} week(s)
									</span>
								</h5>
								<h5 className="tw-text-base tw-font-normal satoshi tw-text-secondary">
									Starting fee:{" "}
									<span className="tw-font-bold">
										NGN{" "}
										{numberWithCommas(Number(state?.amount || 0).toFixed(2))}
									</span>
								</h5>
								<div className="tw-mt-10">
									<MainBtn
										text={"Get Started"}
										onClick={() => navigate("/pricing", { state })}
									/>
								</div>
							</div>
						</div>
						<div
							style={{
								border: "0.5px solid rgba(2, 105, 208, 0.50)",
							}}
							className="lg:tw-w-[40%] tw-flex-1 tw-p-6">
							<h2 className="tw-text-2xl tw-font-extrabold tw-text-secondary satoshi">
								{state?.title}
							</h2>
							{/* <p className="tw-text-base tw-text-secondary tw-font-normal">
								{state?.description}{" "}
							</p> */}
							{ContentWriteup(
								state?.description,
								"tw-text-base tw-text-secondary tw-font-normal"
							)}
							<h2 className="tw-text-2xl tw-font-extrabold tw-text-secondary satoshi">
								What You will Learn
							</h2>
							<p className="tw-text-base tw-text-secondary tw-font-normal">
								HRM students learn how to motivate and enhance employees’
								contribution in order to maximise the productivity of an
								organisation. Courses focus on organisational behaviour, and how
								it can be used to create a positive atmosphere within companies.
								An important skill in this field is managing people according to
								different workplace cultures. This involves flexibility and
								understanding in order to adapt to social and work environments,
								while also strengthening the
							</p>
						</div>
					</div>
				</>
			</MainContainer>
		</div>
	);
};

export default Details;
