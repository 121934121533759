import React, { useState } from "react";
import MainContainer from "../../components/maincontainer";
import MainHeader from "../../components/mainheader";
import Total from "../../assets/totalcourse.svg";
import Student from "../../assets/totalstudent.svg";
import Ongoing from "../../assets/ongoing.svg";
import Completed from "../../assets/completed.svg";

import UserTable, { CourseDashTable } from "../../components/tables/userstable";
import { MainBtn } from "../../components/button";
import Adminmodal from "../../components/modal/adminmodal";
import SentModal from "../../components/modal/sentmodal";
import useCourseStore from "../../data/stores/coursestore";
import useUserStore from "../../data/stores/userstore";
import { useEffect } from "react";
import useAuthStore, { apiCall } from "../../data/stores/authstore";

export const MainDashboard = () => {
	const [modal, setModal] = useState("");
	const [user, setUser] = useState(null);
	let courseStore = useCourseStore();
	let staffStore = useUserStore(),
		users = useAuthStore();
	const Arr = [
		{
			name: "total courses",
			bg: "rgba(2, 105, 208, 0.08)",
			value:
				users?.userPage === "institution"
					? (courseStore?.data?.totalDocs || 0) +
							(courseStore?.institution?.totalDocs || 0) || "0"
					: courseStore?.data?.totalDocs || "0",
			icon: Total,
		},
		{
			name: "Total Staffs",
			bg: "rgba(2, 105, 208, 0.08)",
			value:
				users?.user?.privilege === "company"
					? staffStore?.data?.totalDocs || "0"
					: staffStore?.instructors?.totalDocs,
			icon: Student,
		},
		{
			name: "Ongoing courses",
			bg: "rgba(4, 167, 167, 0.08)",
			value: <CourseDashTable type={""} />,
			icon: Ongoing,
		},
		{
			name: "completed course",
			bg: "rgba(98, 18, 177, 0.08)",
			value: <CourseDashTable type={"completed"} />,
			icon: Completed,
		},
	];

	let [datum, setDatum] = useState({ docs: [] }),
		{ admins, data } = useUserStore();

	useEffect(() => {
		if (users?.user?.privilege === "company") setDatum(data);
		else setDatum(admins);
	}, [admins, data, users?.user]);

	useEffect(() => {
		apiCall(
			"get",
			"/api/v1/course?company=company",
			null,
			courseStore?.getCourse
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div>
			<MainContainer>
				<MainHeader
					text={`Welcome ${users?.user?.privilege || ""} ${
						users?.user?.firstName || ""
					} ${users?.user?.lastName || ""}`}
					small={"Dashboard"}
				/>
				<div className="tw-w-full tw-bg-white tw-rounded-3xl tw-p-4">
					<div className="tw-flex tw-justify-between tw-items-center">
						<h3 className="tw-text-base tw-text-skyblue tw-font-bold satoshi">
							Overview
						</h3>
					</div>
					<div className="tw-mt-8 tw-grid lg:tw-grid-cols-4 tw-gap-5">
						{Arr?.map((item, i) => (
							<div
								style={{
									background: item?.bg,
								}}
								key={i}
								className="tw-h-24 tw-rounded-lg tw-flex tw-items-center tw-gap-3 tw-pl-6">
								<div className="tw-h-10 tw-w-10 tw-rounded-full tw-bg-white tw-flex tw-justify-center tw-items-center tw-shadow-md">
									{" "}
									<img src={item?.icon} alt="" className="" />
								</div>
								<div>
									<h5 className="tw-text-main tw-pt-2 tw-capitalize tw-text-sm satoshi tw-font-medium">
										{item?.name}
									</h5>
									<h5 className="tw-text-main satoshi tw-font-medium tw-text-[32px]">
										{item?.value}
									</h5>
								</div>
							</div>
						))}
					</div>
					<div className="tw-mt-10 tw-flex tw-flex-col lg:tw-flex-row tw-gap-6">
						<div
							style={{
								border: "1px solid rgba(1, 52, 103, 0.30)",
							}}
							className="lg:tw-w-[75%] tw-overflow-y-scroll tw-rounded-2xl tw-p-4">
							<div className="tw-mt-5">
								<UserTable />
							</div>
						</div>
						<div className="lg:tw-w-[25%]">
							<div
								style={{
									border: "0.5px solid rgba(2, 105, 208, 0.50)",
								}}
								className="tw-h-72 tw-p-5 tw-rounded-xl">
								<h5 className="tw-text-sm satoshi tw-font-medium tw-text-secondary">
									{users?.user?.privilege === "company" ? "Staff" : "Admin"}
								</h5>
								{datum?.docs?.slice(0, 3)?.map((item, i) => (
									<div
										className="tw-flex tw-mt-4 tw-justify-between tw-items-center tw-border-b tw-pb-2"
										key={i}>
										<h6 className="tw-text-sm tw-text-main tw-font-normal satoshi">
											{item?.firstName} {item?.lastName}
										</h6>
										<h6 className="tw-text-sm tw-text-[#E34033] tw-font-normal satoshi">
											Remove
										</h6>
									</div>
								))}
								<div className="tw-mt-4 tw-float-right">
									{["institution", "company"]?.includes(
										users?.user?.privilege
									) && (
										<MainBtn
											onClick={() => setModal("add-admin")}
											text={
												users?.user?.privilege === "company"
													? "Add New Staff"
													: "Add New Admin"
											}
										/>
									)}
								</div>
							</div>
							<div
								style={{
									border: "0.5px solid rgba(2, 105, 208, 0.50)",
								}}
								className="tw-mt-5 tw-h-40 tw-bg-main tw-w-full tw-rounded-xl tw-p-6">
								<h4 className="tw-text-sm tw-text-white tw-font-medium tw-text-center satoshi">
									Copy Generated Registration Link
								</h4>
								<div className="tw-mt-5 tw-flex tw-justify-center">
									<button className="tw-h-10 tw-px-4 tw-bg-white tw-rounded-3xl tw-text-base satoshi tw-font-medium tw-text-skyblue">
										Copy
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</MainContainer>
			{modal === "add-admin" && (
				<Adminmodal
					handleModal={d => {
						setUser(d);
						setModal("sent");
					}}
					handleClose={() => {
						setUser(null);
						setModal("");
					}}
					privilege={
						users?.user?.privilege === "company" ? "staff" : "institution-admin"
					}
				/>
			)}
			{modal === "sent" && (
				<SentModal
					handleClose={() => {
						setUser(null);
						setModal("");
					}}
					user={user}
					privilege={
						users?.user?.privilege === "company" ? "staff" : "institution-admin"
					}
				/>
			)}
		</div>
	);
};

const Dashboard = () => <MainDashboard />;

export default Dashboard;
