import MainContainer from "../../components/maincontainer";
import MainHeader from "../../components/mainheader";
import {SearchInput} from "../../components/inputs";
import React, {useState} from "react";
import {MainBtn} from "../../components/button";
import Instructorstable from "../../components/tables/instructorstable";
import Addinstructormodal from "../../components/modal/addinstructormodal";
import SentModal from "../../components/modal/sentmodal";
import Adminmodal from "../../components/modal/adminmodal";

const Instructors = () => {
	const [modal, setModal] = useState("");
	const [user, setUser] = useState(null);

	return (
		<MainContainer>
			<MainHeader text={"Instructors"} small={"All Instructors"} />
			<div className="tw-bg-white tw-p-6 tw-rounded-xl">
				<div className={"tw-flex tw-justify-between tw-items-center"}>
					<div className="tw-flex tw-items-center tw-gap-6">
						<h2 className="tw-text-skyblue tw-font-bold tw-text-xl satoshi">
							All Instructors
						</h2>
						<SearchInput />
					</div>
					<MainBtn
						text={"Add Instructor"}
						onClick={() => setModal("add-admin")}
					/>
				</div>
				<div className={"tw-mt-10"}>
					<Instructorstable />
				</div>
			</div>
			{modal === "add" && (
				<Addinstructormodal handleClose={() => setModal("")} />
			)}
			{modal === "add-admin" && (
				<Adminmodal
					handleModal={d => {
						setUser(d);
						setModal("sent");
					}}
					handleClose={() => {
						setUser(null);
						setModal("");
					}}
					privilege="instructor"
				/>
			)}
			{modal === "sent" && (
				<SentModal
					handleClose={() => {
						setUser(null);
						setModal("");
					}}
					user={user}
					privilege="instructor"
				/>
			)}
		</MainContainer>
	);
};

export default Instructors