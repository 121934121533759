import React, { useEffect } from "react";
import MainContainer from "../../components/maincontainer";
import MainHeader from "../../components/mainheader";
import { SearchInput } from "../../components/inputs";
import PaymentTable from "../../components/tables/paymentstable";
import usePaymentStore from "../../data/stores/paymentstore";
import { apiCall } from "../../data/stores/authstore";

const Payments = () => {
	let paymentStore = usePaymentStore();

	useEffect(() => {
		apiCall(
			"get",
			"/api/v1/transactions?company=company",
			null,
			paymentStore?.getPayment
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div>
			<MainContainer>
				<MainHeader text={"Payments"} small={"234 Transactions"} />
				<div className="tw-w-full tw-bg-white tw-rounded-3xl tw-mt-16 tw-p-4">
					<div className="tw-flex tw-gap-4 tw-items-center">
						<h5 className="tw-text-base tw-text-skyblue tw-font-bold satoshi">
							All Staffs
						</h5>
						<SearchInput />
					</div>
					<div className="tw-mt-8">
						<PaymentTable />
					</div>
				</div>
			</MainContainer>
		</div>
	);
};

export default Payments;
