import React, { useEffect, useState } from "react";
import { MainPaginate, Tablebtn } from "../button";
import { useNavigate } from "react-router-dom";
import useUserStore from "../../data/stores/userstore";
import useAuthStore, { apiCall } from "../../data/stores/authstore";
import moment from "moment";
import useCourseStore from "../../data/stores/coursestore";

const UserTable = () => {
	const navigate = useNavigate();
	const Headers = [
			"name",
			"date registered",
			"Latest course",
			"no. of courses",
			"actions",
		],
		data1 = [
			{
				name: "John Doe",
				department: "Fleet Management",
				date_registered: "07 May, 2023",
				no_of_courses: "4",
			},
			{
				name: "John Doe",
				department: "Fleet Management",
				date_registered: "07 May, 2023",
				no_of_courses: "4",
			},
			{
				name: "John Doe",
				department: "Fleet Management",
				date_registered: "07 May, 2023",
				no_of_courses: "4",
			},
			{
				name: "John Doe",
				department: "Fleet Management",
				date_registered: "07 May, 2023",
				no_of_courses: "4",
			},
			{
				name: "John Doe",
				department: "Fleet Management",
				date_registered: "07 May, 2023",
				no_of_courses: "4",
			},
			{
				name: "John Doe",
				department: "Fleet Management",
				date_registered: "07 May, 2023",
				no_of_courses: "4",
			},
			{
				name: "John Doe",
				department: "Fleet Management",
				date_registered: "07 May, 2023",
				no_of_courses: "4",
			},
		];

	let [datum, setDatum] = useState({ docs: data1 }),
		{ isFound, mainSearch, data, getUsers } = useUserStore(),
		{ getAllCourse } = useCourseStore(),
		{ userPage, user } = useAuthStore();

	useEffect(() => {
		if (isFound) setDatum(mainSearch);
		else setDatum(data);
	}, [data, isFound, mainSearch]);

	useEffect(() => {
		apiCall(
			"get",
			`/api/v1/user/manage-users?privilege=${
				user?.privilege === "company" ? "staff" : "student"
			}`,
			null,
			getUsers
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		apiCall(
			"get",
			`/api/v1/course?pagination=not${
				userPage !== "student" ? `&company=${userPage}` : ""
			}${
				userPage === "institution" ? `&institution=${user?.organisation}` : ""
			}`,
			null,
			getAllCourse
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const [itemOffset, setItemOffset] = useState(0);
	let [range] = useState(10);
	const endOffset = itemOffset + range;

	// if (!datum && !data) return <PageLoader />;
	if (!datum) return;

	const currentItems = datum?.docs?.slice(itemOffset, endOffset);
	const pageCount = Math.ceil(datum?.docs?.length / range);

	const handlePageClick = event => {
		const newOffset = (event.selected * range) % datum?.docs?.length;
		setItemOffset(newOffset);
	};

	return (
		<div className=" tw-overflow-x-auto">
			<table className="tw-min-w-full tw-min-h-full tw-table-auto">
				<thead>
					<tr>
						{Headers?.map(item => (
							<th className="tw-text-secondary tw-p-3 tw-font-medium satoshi tw-text-sm tw-capitalize">
								{item}
							</th>
						))}
					</tr>
				</thead>
				<tbody>
					{currentItems?.map((item, i) => (
						<tr
							style={{
								borderBottom: "1px solid rgba(1, 52, 103, 0.30)",
							}}
							key={i}>
							<td className="tableText">
								{item?.firstName} {item?.lastName}
							</td>
							<td className="tableText">
								{moment(item?.createdAt).format("DD MMMM, YYYY")}
							</td>
							<td className="tableText">
								<UserCourseTable datum={item} />
							</td>
							<td className="tableText">
								<UserCourseTable type={"length"} datum={item} />
							</td>

							<td>
								<div className="">
									<Tablebtn
										onClick={() =>
											navigate(`/staffs/${item?.firstName || i}`, {
												state: item,
											})
										}
										text={"View"}
									/>
								</div>
							</td>
						</tr>
					))}
				</tbody>
			</table>
			{pageCount > 1 && (
				<MainPaginate pageCount={pageCount} handlePageClick={handlePageClick} />
			)}
		</div>
	);
};

export default UserTable;

export const UserCourseTable = ({ type, datum, userType }) => {
	let { allCourse } = useCourseStore(),
		[courses, setCourses] = useState(null);
	useEffect(() => {
		if (datum) {
			let findCourses = allCourse?.docs?.filter(it => {
				let findP = it?.[userType || "partakers"]?.find(
					ij => ij?._id === datum?._id
				);

				return findP;
			});
			setCourses(findCourses);
		}
	}, [datum, allCourse, userType]);

	return (
		<>{type === "length" ? courses?.length || 0 : courses?.[0]?.title || ""}</>
	);
};

export const CourseDashTable = ({ type, course }) => {
	let { courses, courses2 } = useCourseData();
	// console.log({
	// 	courses: courses?.reduce(
	// 		(ac, i) => (ac += Number(i?.progress?.score || 0)),
	// 		0
	// 	),
	// 	length: courses?.length,
	// });
	// console.log({ courses });
	if (!courses) return;

	return (
		<>
			{Number(
				type === "completed"
					? courses?.filter(it => it?.progress?.status === "completed")
							?.length || 0
					: type === "not-completed"
					? courses?.filter(it => it?.progress?.status !== "completed")
							?.length || 0
					: type === "lock-out" && course
					? courses
							?.filter(it => it?.progress?.status === "lock-out")
							?.filter(it => it?.course?._id === course)?.length || 0
					: type === "lock-out"
					? courses?.filter(it => it?.progress?.status === "lock-out")
							?.length || 0
					: type === "certificate"
					? courses?.filter(it => it?.progress?.certificate)?.length || 0
					: type === "not-certificate"
					? courses?.filter(it => !it?.progress?.certificate)?.length || 0
					: type === "total"
					? courses?.length || 0
					: type === "higher"
					? courses?.sort(
							(a, b) => b?.progress?.score - a?.progress?.score
					  )?.[0]?.progress?.score || 0
					: type === "lower"
					? courses?.sort((a, b) => b?.progress?.score - a?.progress?.score)?.[
							courses?.length - 1
					  ]?.progress?.score || 0
					: type === "average"
					? courses?.reduce(
							(ac, i) => (ac += Number(i?.progress?.score || 0)),
							0
					  ) / courses?.length || 0
					: type === "most-taken"
					? courses2?.sort(
							(a, b) => b?.partakers?.length - a?.partakers?.length
					  )?.[0]?.partakers?.length || 0
					: type === "lowest-taken"
					? courses2?.sort(
							(a, b) => b?.partakers?.length - a?.partakers?.length
					  )?.[courses?.length - 1]?.partakers?.length || 0
					: type === "average-taken"
					? courses2?.reduce(
							(ac, i) => (ac += Number(i?.partakers?.length || 0)),
							0
					  ) / courses?.length || 0
					: course
					? courses
							?.filter(
								it => !["completed", "lock-out"]?.includes(it?.progress?.status)
							)
							?.filter(it => it?.course?._id === course)?.length || 0
					: courses?.filter(
							it => !["completed", "lock-out"]?.includes(it?.progress?.status)
					  )?.length || 0
			).toFixed(0)}
		</>
	);
};

export const useCourseData = () => {
	let {
			// allCourse,
			data,
		} = useCourseStore(),
		{ userPage, user } = useAuthStore(),
		[courses, setCourses] = useState(null),
		[courses2, setCourses2] = useState(null);
	useEffect(() => {
		let dd = data;
		// if (allCourse) dd = allCourse;
		if (dd) {
			let newCo = [];
			if (
				!["student", "instructor"]?.includes(userPage) &&
				!["student", "instructor"]?.includes(user?.privilege)
			)
				for (let co = 0; co < dd?.docs.length; co++) {
					const element = dd?.docs[co];

					newCo = element?.partakers
						? [
								...newCo,
								...element?.partakers?.map(it => {
									return {
										...it,
										course: { title: element?.title, _id: element?._id },
									};
								}),
						  ]
						: newCo;
				}
			setCourses(
				!["student", "instructor"]?.includes(userPage) &&
					!["student", "instructor"]?.includes(user?.privilege)
					? newCo
					: dd?.docs
			);
		}
		setCourses2(data?.docs);
	}, [data, userPage, user]);

	return { courses, courses2 };
};