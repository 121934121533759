import { Link, useNavigate } from "react-router-dom";

import img1 from "../../assets/homepageGuy.png";
import { motion } from "framer-motion";
import pop1 from "../../assets/Rectangle1492.png";
import pop5 from "../../assets/Rectangle1485.png";
import pop6 from "../../assets/Rectangle1486.png";
import pop7 from "../../assets/Rectangle1487.png";
import pop8 from "../../assets/Rectangle1488.png";
import { BsAward, BsCameraVideo, BsClock } from "react-icons/bs";
import { FaVideo } from "react-icons/fa";
import { useState } from "react";
import Image from "../../assets/female.png";
import Microsoft from "../../assets/microsoft.png";
import Paypal from "../../assets/paypal.png";
import Google from "../../assets/google.png";
import { Rating } from "react-simple-star-rating";
import { IconContext } from "react-icons";
import { IoMdClose } from "react-icons/io";
import Quote from "../../assets/quote.png";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Mousewheel, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import Pace from "../../assets/pace.png";
import Plain from "../../assets/plain.png";
import Later from "../../assets/later.png";
import PageHeader from "../../components/pageheader";
import PageFooter from "../../components/pagefooter";
import { CiSquareMinus, CiSquarePlus } from "react-icons/ci";
import Gmail from "../../assets/gmail.svg";
import Phone from "../../assets/phone.png";
import Address from "../../assets/address.svg";
import Frame from "../../assets/frame.svg";
import useCourseStore from "../../data/stores/coursestore";
import { useEffect } from "react";
import { AiOutlineClockCircle } from "react-icons/ai";
import { SiMicrosoftoutlook } from "react-icons/si";
import Female from "../../assets/avatar-svgrepo-com.svg";
import Male from "../../assets/avatar-svgrepo-com (1).svg";

const Home = () => {
  const ContactArr = [
    {
      icon: <SiMicrosoftoutlook size={30} color="white" />,
      value: "learning@icsoutsourcing.com",
    },
    {
      icon: <img src={Phone} alt="" />,
      value: "+234 (0)9087398551",
    },
    {
      icon: <img src={Address} alt="" />,
      value: "6, Olusoji Idowu Street Ilupeju Lagos",
    },
  ];
  return (
    <>
      <PageHeader />
      <HomeBanner />
      {/*<TopCourses />*/}
      <HowItWorks />
      <AboutUs />
      <Why />
      {/* <LearningPlan /> */}
      {/* <Popular /> */}
      <Testimonial />
      <FAQ />

      <div className="container tw-mb-10 tw-grid tw-grid-cols-2 rounded-xl">
        <img
          alt=""
          src={require("../../assets/signupHome.png")}
          className=" tw-w-full tw-col-span-2 lg:tw-col-span-1"
        />
        <div className="tw-col-span-2 lg:tw-col-span-1 tw-p-6  tw-bg-[#0B2239] container">
          <div className="tw-p-6 tw-bg-white tw-rounded-xl tw-h-full">
            <p className="tw-text-2xl tw-font-bold  tw-text-[#0B2239] tw-mb-5">
              Get in Touch
            </p>
            <p className="tw-text-[#0B2239] tw-mt-7 tw-mb-0"> Full Name</p>
            <input
              type="text"
              className="tw-border-none mt-2 tw-bg-[#F3F5F5] tw-p-3 tw-w-full"
            />
            <p className="tw-text-[#0B2239] tw-mt-4 tw-mb-0"> Email Address</p>
            <input
              type="text"
              className="tw-border-none mt-2 tw-bg-[#F3F5F5] tw-p-3 tw-w-full"
            />
            <p className="tw-text-[#0B2239] tw-mt-4 tw-mb-0"> Phone Number</p>
            <input
              type="text"
              className="tw-border-none mt-2 tw-bg-[#F3F5F5] tw-p-3 tw-w-full"
            />
            <p className="tw-text-[#0B2239] tw-mt-4 tw-mb-0"> Message</p>
            <textarea
              type="text"
              className="tw-border-none mt-2 tw-min-h-[100px] tw-bg-[#F3F5F5] tw-p-3 tw-w-full"
            />
            <div className="tw-flex tw-justify-end tw-mt-3">
              <p className="tw-text-white tw-bg-[#0B2239] tw-rounded-3xl tw-text-xl tw-py-3 tw-px-5">
                Send
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="container tw-relative tw-rounded-2xl tw-mb-20  tw-py-8 tw-bg-[#0B2239]">
        <div className="tw-w-11/12 tw-mx-auto">
          <h4 className="lg:tw-text-2xl tw-text-base tw-font-bold tw-text-white rowdies">
            Rather Contact Us Another Way ?
          </h4>
          <small className="tw-text-sm tw-font-medium satoshi tw-text-white">
            Try us via phone or email
          </small>
          <div className="tw-mt-4 tw-w-full tw-grid tw-gap-y-3 lg:tw-grid-cols-3">
            {ContactArr?.map((it) => (
              <div className="tw-flex tw-gap-3 tw-items-center">
                <div>{it?.icon}</div>
                <strong className=" lg:tw-text-xl tw-text-sm tw-font-bold satoshi tw-text-white">
                  {it?.value}
                </strong>
              </div>
            ))}
          </div>
        </div>
        <img src={Frame} alt="" className="tw-absolute tw-right-0 tw-top-0" />
      </div>
      <PageFooter />
    </>
  );
};

export default Home;

const HomeBanner = () => {
  const [modal, setModal] = useState(false);
  return (
    <div className="tw-relatives">
      {/* <div className="tw-asbsolute tw-inset-0 tw-bg-black tw-bg-opacity-30 tw-flex tw-justify-center tw-z-50"></div> */}
      <div className="tw-bg-white  fullHeight">
        <div
          className=""
          style={{
            // background: `url(${bg})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
        >
          <div className="">
            {" "}
            <motion.section
              initial={{
                x: -250,
              }}
              animate={{
                x: 0,
              }}
              transition={{
                duration: 2,
                delay: 0.5,
              }}
              className="fullHeight  tw-flex text-white bg-transparent"
            >
              <div className=" tw-w-[90%] tw-mt-[20px] md:tw-mt-0 tw-ml-[5%] md:tw-ml-0 md:tw-w-full tw-relative tw-flex tw-top-0 bg-transparent">
                <section className="sm:tw-relative tw-pb-5 md:tw-pb-0 md:tw-absolute md:tw-pl-[30px] lg:tw-pl-[110px] sm:tw-w-[90%] md:tw-w-[55%] tw-top-[160px] md:tw-top-[130px] lg:tw-top-20 tw-z-20 tw-bg-[#ffffff80] tw-border-solid tw-border-r-[1px] tw-border-b-[1px] tw-rounded-3xl tw-border-[#0B2239] md:tw-h-[320px] lg:tw-h-[80vh] my-auto">
                  <div
                    className="my-auto bg-transparent tw-flex tw-items-start tw-h-full tw-flex-col tw-justify-center"
                    data-aos="fade-up-right"
                  >
                    <p className="lg:tw-text-[64px] tw-opacity-81 tw-text-4xl tw-font-bold tw-text-[#0B2239] lg:tw-leading-[70px]">
                      Empower Your
                      <br /> Future with Flexible <br /> Learning
                    </p>
                    <p className="w-75 mb-5 tw-text-[#1b1b1bcc] tw-opacity-80 fontReduce fontReduceMaxhead">
                      You can invest in your future by studying with us whenever
                      and wherever you want.
                    </p>
                    <div className="tw-relative  tw-opacity-80">
                      <MainButton />
                    </div>
                  </div>
                </section>
                <div
                  className="md:tw-w-[100%] tw-hidden md:tw-block mt-auto bg-transparent"
                  //                 data-aos="fade-up-left"
                >
                  <img
                    src={img1}
                    alt="Banner"
                    className="img-fluid tw-relative  md:tw-left-[50%] md:tw-w-[50%] tw-h-[90vh] tw-mt-20 lg:tw-mt-0"
                  />
                </div>
              </div>
            </motion.section>
          </div>
        </div>
      </div>
      {modal ? (
        <CourseModal
          handleclose={() => {
            console.log(modal);
            setModal(false);
          }}
        />
      ) : null}
    </div>
  );
};

export const MainButton = () => {
  const navigate = useNavigate();
  return (
    <div className="tw-flex">
      <button
        onClick={() => navigate("/register")}
        className="text-decoration-none !tw-bg-[#0B2239] tw-rounded-3xl tw-text-white text-capitalize tw-h-14 tw-w-32"
      >
        Get Started
      </button>
    </div>
  );
};

export const MainButton2 = () => {
  return (
    <div>
      <Link
        to={
          "https://docs.google.com/forms/d/e/1FAIpQLScojgWruo6gcmAHN2UCnF_HRkzdAVL0zkvjFIKVNva-7b1Yaw/viewform"
        }
        className="text-decoration-none -tw-ml-4 tw-bg-white tw-text-[#1b1b1b] text-capitalize px-4 py-3 rounded-0 "
      >
        Get started
      </Link>
      <div className="tw-h-12 tw-w-32 tw-hidden lg:tw-block -tw-mt-6 tw-bg-[#FBB100]"></div>
    </div>
  );
};

export const Popular = () => {
  let coursesDetails = [
    {
      image: pop5,
      description: "Databases and SQL for Data Science with Python",
      videos: "52",
      duration: "4",
    },
    {
      image: pop6,
      description: "Intro to UX: Fundamentals of Usability",
      videos: "8",
      duration: "5",
    },
    {
      image: pop7,
      description: "Pyschology in Web Design: How to Create an Hero Section",
      videos: "18",
      duration: "1",
    },
    {
      image: pop8,
      description: "Sentiment Analysis with Deep Learning using BERT",
      videos: "24",
      duration: "2",
    },
  ];

  let catTab = [
    "All categories",
    "development",
    "IT & Software",
    "Design",
    "Business",
    "Lifestyle",
    "Marketing",
  ];
  let [active, setActive] = useState(0);
  return (
    <div
      className="tw-pt-5"
      style={{
        background: "#EBF1FF",
      }}
    >
      <div className="container py-3 py-md-5">
        <h3 className="tw-text-3xl tw-text-[#1b1b1b] tw-font-normal">
          Discover Top Course
        </h3>

        <div className="lg:tw-flex tw-hidden mx-0 tw-gap-2 Inter">
          {catTab?.map((item, i) => (
            <button
              onClick={() => setActive(i)}
              className={`${
                active === i
                  ? "border-width-4-color tw-mt-4  tw-font-normal tw-text-gray-900 tw-text-[15px] "
                  : ""
              } btn text-capitalize tw-font-normal tw-text-gray-900 tw-text-[15px] border-width-4 rounded-0 tw-mt-4`}
              key={i}
            >
              {item}
            </button>
          ))}
        </div>
        <div className="lg:tw-mt-3">
          <CoursesOutline coursesDetails={coursesDetails} noLink />
        </div>
      </div>
    </div>
  );
};

export const TopCourses = () => {
  let [datum, setDatum] = useState(null),
    courses = useCourseStore(),
    navigate = useNavigate();

  let data = [
    {
      image: require("../../assets/Leadership.png"),
      title: "Leadership",
    },
    {
      image: require("../../assets/Girlwithglassy.png"),
      title: "Human Resource Management",
    },
    {
      image: require("../../assets/Yellowteacher.png"),
      title: "Administration",
    },
    {
      image: require("../../assets/mama.png"),
      title: "Legal & Law",
    },
    {
      image: require("../../assets/Theman.png"),
      title: "Customer Psycology",
    },
    {
      image: require("../../assets/Yellowteacher.png"),
      title: "Administration",
    },
  ];

  useEffect(() => {
    if (courses?.data?.docs)
      setDatum(
        courses?.data?.docs
          // ?.sort((a, b) => a?.module?.length > b?.module?.length)
          ?.slice(0, 6)
      );
    else setDatum(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courses?.data]);

  // if (!state) return;
  return (
    <div className="tw-p-4">
      <div className=" py-3 py-md-5 tw-mt-6 tw-bg-[#0B2239] tw-rounded-3xl">
        <div className="container py-4">
          <div className="tw-flex tw-flex-col md:tw-flex-row tw-gap-6 tw-justify-between tw-items-center ">
            <h3 className="lg:tw-text-4xl tw-text-left tw-text-2xl tw-font-bold tw-text-white">
              Browse Our List Of <br />{" "}
              <span className="tw-text-4xl tw-mt-4 tw-font-bold tw-text-white">
                Our Courses
              </span>
            </h3>
            <p className="text-white tw-w-[350px] tw-text-xl">
              You can invest in your future by studying with us whenever and
              wherever you want.
            </p>
            {/* <img src={Arrow} alt="" className="tw-h-12" /> */}
          </div>
        </div>
        {/* <CoursesOutline coursesDetails={state} /> */}
        <div className="tw-grid container tw-grid-cols-1 md:tw-grid-cols-2 lg:tw-grid-cols-3 tw-gap-8 tw-my-6">
          {datum?.map((each, index) => (
            <div key={index}>
              <img
                src={
                  each?.image?.url ||
                  each?.module?.[0]?.image?.url ||
                  data?.[index % data?.length]?.image
                }
                alt=""
              />
              <p className="mt-4 text-white lg:tw-text-2xl tw-text-xl">
                {each?.title}
              </p>
              <p className="tw-font-250 lg:mt-4 text-white lg:tw-text-lg tw-text-sm">
                {courses?.data
                  ? each?.description
                  : `You can invest in your future by studying with us whenever and
                wherever you want.`}
              </p>
              <p className="tw-font-250 mt-4 mb-4 text-white lg:tw-text-lg tw-text-sm tw-flex tw-items-center">
                <BsCameraVideo className="text-white tw-mr-3" />
                <span className="tw-mr-3">
                  {each?.module?.reduce?.(
                    (ac, i) => (ac += i?.section?.length),
                    0
                  )}{" "}
                  Videos
                </span>
                <AiOutlineClockCircle className="tw-mr-3" />
                <span className="tw-mr-3">
                  {each?.duration ? each?.duration % 60 : 0} Hours
                </span>
              </p>
              <p
                className="tw-text-[#0B2239] tw-bg-white tw-w-[150px] py-2 tw-flex tw-justify-center tw-text-lg tw-rounded-3xl tw-cursor-pointer"
                onClick={() =>
                  navigate(`/courses/${each?.title}`, { state: each })
                }
              >
                Learn More
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const CoursesOutline = ({ coursesDetails, noLink }) => {
  const navigate = useNavigate();
  return (
    <>
      <div className="tw-grid lg:tw-grid-cols-3 tw-gap-8 tw-mt-14">
        {coursesDetails?.map((item, i) => (
          <div
            data-aos-duration="3000"
            data-aos="zoom-in-up"
            className="tw-p-4 tw-bg-[#dbecff] tw-rounded-tl-3xl tw-rounded-br-3xl"
            key={i}
          >
            <div className="">
              <img
                src={item?.image?.url || item?.module?.[0]?.image?.url || pop1}
                className="img-fluid tw-w-full tw-h-96 tw-rounded-tl-3xl tw-rounded-br-3xl"
                alt={item?.description}
              />
              <div className="p-2 tw-flex tw-flex-col tw-justify-between tw-h-42">
                <div className="">
                  <h6 className="segoe tw-text-lg tw-font-bold tw-text-[#1b1b1b]">
                    {item?.title}
                  </h6>
                  <p className="tw-mb-3 Nunito first-letter:tw-uppercase tw-text-[#5E616A] tw-font-medium tw-text-base textTrunc textTrunc3">
                    {item?.description}
                  </p>
                </div>
                <div>
                  <div className="tw-flex tw-justify-between tw-items-center tw-gap-1 py-2 ">
                    <div className="tw-flex tw-gap-2 tw-items-center">
                      <Rating
                        initialValue={item?.rating}
                        onClick={() => {}}
                        fillColor="#FFD700"
                        size={16}
                        readonly
                      />
                      <div>
                        <p className="tw-text-[10px] tw-text-[#1b1b1bee] tw-font-normal Nunito">
                          ({item?.rating || 0})
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="tw-flex tw-gap-1 tw-items-center mt-auto">
                    <FaVideo />
                    <div className="tw-text-[10.5px] tw-text-[#1b1b1bcc] tw-font-boldtw-capitalize">
                      {item?.module?.reduce(
                        (ac, i) => (ac += i?.quest?.length),
                        0
                      ) || 0}{" "}
                      Videos
                    </div>
                    <div className=" tw-flex tw-gap-1 tw-items-center">
                      <BsClock />
                      <div className="tw-text-[10.5px] tw-text-[#1b1b1bcc] tw-font-bold  tw-capitalize">
                        {" "}
                        {Math.ceil(item?.duration / 60)} hours
                      </div>
                    </div>
                    <div>
                      <BsAward />
                    </div>
                  </div>
                  {!noLink && (
                    <button
                      onClick={() =>
                        navigate(
                          `/courses?course=${item?._id}&title=${item?.title}`
                        )
                      }
                      className="tw-bg-[#1A10C5] tw-h-8 tw-w-28 tw-rounded-2xl tw-text-white tw-font-bold tw-text-[12px] tw-mt-4 text-capitalize"
                    >
                      learn more
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export const HowItWorks = () => {
  let data1 = [
    {
      title: "View Courses",
      text: "Take a look at our courses to explore the available  options and gain insight into the various learning opportunities we offer.",
    },
    {
      title: "Pick A Course",
      text: "Choose a course of your choice from our courses and embark  on a transformative learning journey to enhance your skill and knowledge.",
    },
    {
      title: "Choose A Plan ",
      text: "Pick a plan that aligns effectively with your unique needs.",
    },
  ];

  return (
    <div className="tw-mt-20">
      <div className="container py-3 py-md-5">
        <div className=" text-white">
          <h1 className="lg:tw-text-4xl text-center tw-text-2xl tw-text-black tw-font-black sansation">
            How it works
          </h1>

          <div className="container tw-grid tw-grid-cols-8 tw-gap-8 tw-mt-12">
            <div className="tw-col-span-8 lg:tw-col-span-3">
              {data1?.map((each, index) => (
                <div
                  key={index}
                  className="tw-border-solid tw-px-8 tw-py-4 tw-border-b-[1px] tw-h-[160px] tw-border-[#0B2239] tw-flex tw-items-start tw-flex-col"
                >
                  <p className="tw-text-[#0B2239] tw-text-2xl tw-my-0 tw-font-bold">
                    {each?.title}
                  </p>
                  <p className="tw-text-[#0B2239] tw-my-3">{each?.text}</p>
                </div>
              ))}
            </div>
            <img
              src={require("../../assets/Maskgroup.png")}
              className="tw-w-[350px] tw-hidden lg:tw-block tw-h-full tw-col-span-2"
              alt=""
            />
            <div className="tw-col-span-8 lg:tw-col-span-3">
              {data1?.map((each, index) => (
                <div
                  key={index}
                  className="tw-border-solid tw-px-8 tw-py-4 tw-border-b-[1px] tw-h-[160px] tw-border-[#0B2239] tw-flex tw-items-start tw-flex-col"
                >
                  <p className="tw-text-[#0B2239] tw-text-2xl tw-my-0 tw-font-bold">
                    {each?.title}
                  </p>
                  <p className="tw-text-[#0B2239] tw-my-3">{each?.text}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const LearningPlan = () => {
  // let {
  //   plan,
  //   // numberWithCommas
  // } = useContext(GlobalState);
  const [plan] = useState([]);
  let techList = [
    {
      id: 1,

      title: "tech lite",
      image: Pace,
      color: "#EF11BC",
      bg: "#FEDBF6",
      price: "00,000",
    },
    {
      id: 2,
      image: Plain,
      title: "tech prime",
      color: "#0C8209",
      bg: "#DCFEDB",
      price: "00,000",
    },
    {
      id: 3,
      image: Later,
      title: "tech premium",
      color: "#7311EF",
      bg: "#F3DBFE",
      price: "00,000",
    },
  ];
  // if (!plan?.data) return;

  return (
    <div className="">
      <div className="container py-3 py-md-5">
        <div className="py-3">
          <h1 className="text-center tw-text-4xl tw-font-bold sansation tw-text-[#070565]">
            Our Learning Mode
          </h1>
        </div>
        <div className="tw-grid lg:tw-grid-cols-3 mx-0 g-3 mb-4">
          {plan?.data?.map((item, i) => (
            <div
              data-aos-duration="3000"
              data-aos="zoom-in-up"
              key={i}
              className="p-3"
            >
              <div
                className="py-5 p-3 text-center"
                style={{
                  boxShadow: "0px 0px 100px rgba(0, 0, 0, 0.07)",
                  borderRadius: "20px",
                }}
              >
                <div
                  className="rounded-circle d-flex align-items-center justify-content-center mx-auto mb-3"
                  style={{
                    height: "3.5rem",
                    width: "3.5rem",
                    background: techList?.[i]?.bg,
                  }}
                >
                  <img src={techList?.[i].image} alt="" className="" />
                  {/* <FaGraduationCap size={24} color={techList?.[i]?.color} /> */}
                </div>
                <h1
                  style={{
                    color: "rgba(29, 26, 173, 0.8)",
                  }}
                  className="fw-bold text-center text-uppercase my-3"
                >
                  {item?.name}
                </h1>

                {item?.benefits?.map((list, i) => (
                  <p
                    className="text-center"
                    style={{
                      lineHeight: "150%",
                      textAlign: "center",
                      color: "rgba(27, 27, 27, 0.8)",
                    }}
                    key={i}
                  >
                    {list}
                  </p>
                ))}
                {/* <p className="tw-text-2xl tw-font-bold tw-text-[#1b1b1b] sansation">
                    NGN {numberWithCommas(Number(item?.amount).toFixed(2))}
                  </p> */}
              </div>
            </div>
          ))}
        </div>
        <div data-aos-duration="3000" data-aos="zoom-in-up" className="py-4">
          <div
            className=" py-3 py-md-5 container tw-rounded-3xl d-flex tw-items-center tw-justify-around"
            style={{
              background:
                "linear-gradient(90.18deg, #2A5AFF -52.19%, #2E49A7 81.92%)",
            }}
          >
            <p className="Nunito tw-font-bold lg:tw-text-4xl tw-text-xl tw-text-white">
              Are you ready to learn now <br /> and pay later ?
            </p>
            <MainButton2 />
          </div>
        </div>
      </div>
    </div>
  );
};

const FAQ = () => {
  const [active, setActive] = useState(null);
  let arr = [
    {
      id: 1,
      question:
        "How does the self-paced, virtual, and learn now pay later learning plan work?",
      answer: [
        "The self-paced learning plan allows you to set your own schedule and progress through the material at a speed that suits your learning style. You have the flexibility to study when it's convenient for you, making it easier to balance other commitments such as work or personal obligations. It also provides an opportunity for personalized learning, allowing you to spend more time on challenging topics or quickly grasp concepts that come easily to you.",
        "The Virtual learning plan provides a one-on-one remote learning experience with our instructors from anywhere with an internet connection. Virtual training offers several benefits, including the ability to learn from the comfort of your own home or any location of your choice. It eliminates the need for commuting and reduces associated costs. ",
        "The Learn Now Pay Later learning plan provides the opportunity to acquire knowledge now and defer payment to a later time. This allows for face-to-face interaction with instructors and fellow students, fostering a collaborative learning environment. It provides immediate feedback and the opportunity to ask questions in real-time.",
      ],
    },
    {
      id: 2,
      question: "What kind of tech training programs do you offer? ",
      answer: [
        "We offer a wide range of tech training programs cover such as Front-End development, Back-End Development, Data science, Digital Marketing, Data Analysis, and more. Our programs are specifically tailored for beginners and aim to equip you with the essential knowledge and skills required to thrive in the rapidly evolving tech industry",
      ],
    },
    {
      id: 3,
      question:
        "Will certificate be given after the program is completed successfully?",
      answer: [
        "Absolutely! Upon successfully completing our programs, you will be awarded a certificate",
      ],
    },
    {
      id: 4,
      question:
        "Can I pay in instalment for any of the program or learning plan?",
      answer: [
        "Unfortunately, we do not offer installment payment options for our programs. However, we have carefully structured our program fees to be affordable.",
      ],
    },
    {
      id: 5,
      question:
        "Is a job guaranteed for me after any of the program or learning plan?",
      answer: [
        "Our commitment is to provide you with an immersive experience that will significantly enhance your employability.",
      ],
    },
  ];
  const handleActive = (i) => {
    setActive(active === i ? null : i);
  };
  return (
    <div
      style={{
        background: `#EEF6FE`,
        backgroudSize: "cover",
        backgroundPosition: "center",
      }}
      className="tw-w-full tw-mb-20 tw-py-20"
    >
      <div className="container">
        <h1 className="tw-text-center tw-font-bold lg:tw-text-4xl tw-text-3xl Dmsans tw-text-[#0B2239]">
          Frequently Asked{" "}
          <span className="tw-text-4xl tw-text-[#0B2239] Dmsans">
            Questions
          </span>
        </h1>
        <p className="tw-text-[#0B2239] tw-text-xl tw-font-medium tw-text-center Dmsans tw-pt-3">
          Everything you need to know about the ICS Sourcing Academy .
        </p>
        <div className="tw-w-full tw-mt-8">
          {arr?.map((item, i) => (
            <div
              data-aos-duration="3000"
              data-aos="zoom-in-up"
              className="tw-border-solid tw-p-3 tw-bg-white tw-mb-3 tw-border-[0.5px] tw-rounded-3xl tw-border-[#0B223980]"
              key={i}
            >
              <div
                onClick={() => handleActive(i)}
                className="tw-flex tw-justify-between tw-h-full tw-items-center tw-bg-white container tw-rounded-3xl tw-cursor-pointer"
              >
                <p className=" lg:tw-text-xl tw-text-lg tw-text-[#0B2239] tw-font-bold Dmsans">
                  {item?.question}
                </p>
                <div>
                  <IconContext.Provider
                    value={{
                      color: "#0B2239",
                    }}
                  >
                    {active === i ? (
                      <CiSquareMinus size={20} />
                    ) : (
                      <CiSquarePlus size={20} />
                    )}
                  </IconContext.Provider>
                </div>
              </div>
              {active === i && (
                <div className="tw-space-y-2">
                  {item.answer.map((ans, i) => (
                    <p
                      key={i}
                      className={`lg:tw-text-base tw-text-sm tw-font-medium sansation Dmsans tw-text-[#0B2239]`}
                    >
                      {ans}
                    </p>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
const Testimonial = () => {
  const TestimonialArr = [
    {
      id: 1,
      text: "This LMS is a game-changer for our company!  Finally, a platform with relevant courses on business and HR practices tailored for the African context.  Our staff love the flexibility of learning at their own pace, and I've already seen a significant improvement in employee performance.",
      image: "",
      name: " Amina Mohammed",
      course: "HR Manager",
      gender: "female",
    },
    {
      id: 2,
      text: "As a recent graduate in Nigeria, this LMS has been a great resource for me to develop my professional skills.  The platform offers a variety of soft skills and business courses that have helped me prepare for the job market.  I highly recommend this platform to anyone looking to advance their career.",
      name: "David Oyekanmi",
      course: "Recent Graduate",
    },
    {
      id: 3,
      text: "This platform is amazing for young professionals like me!  The variety of courses, from business basics to management styles, has given me a solid foundation for my career.  The user interface is user-friendly, and I love being able to access the courses on my phone.",
      image: "",
      name: "Aisha Diallo",
      course: "Marketing Intern",
      gender: "female",
    },
    {
      id: 4,
      text: "This LMS has transformed our team training process. No more scrambling to find resources - everything we need is on this platform.  The management courses have helped me become a more effective leader, and my team is more engaged and productive.",
      image: "",
      name: "John Zulu",
      course: "Team Lead:",
    },
    {
      id: 5,
      text: "As a small business owner in Ethiopia, I need to wear many hats.  This LMS has been a lifesaver! I've taken courses on everything from financial management to marketing strategies, all specifically designed for African entrepreneurs.  It's affordable and has helped me grow my business.",
      name: "Miriam Abebe",
      course: "Business Owner",
      gender: "female",
    },
    {
      id: 6,
      text: "I'm impressed with the depth and quality of the courses on this platform.  The project management modules have been particularly helpful, with practical tools and techniques I can apply immediately.  The platform is intuitive and well-organized, making it easy to find the courses I need.",
      image: "",
      name: "Hassan Idriss",
      course: "Project Manager",
    },
    {
      id: 7,
      text: "The customer service training on this LMS has been a huge benefit for our team.  We've learned valuable communication skills and conflict resolution techniques that have led to happier customers and a more positive work environment.",
      image: "",
      name: "Nneka Opara",
      course: "Customer Service Representative",
      gender: "female",
    },
    {
      id: 8,
      text: "As an accountant in Ghana, staying up-to-date on regulations and best practices is crucial.  This LMS offers a wide range of relevant accounting courses that are clear, concise, and easy to understand.  The platform is a valuable resource for anyone in the finance industry.",
      name: "Kwame Yeboah",
      course: "Accountant",
    },
    {
      id: 9,
      text: "This LMS has made my job as an HR Officer so much easier!  The platform offers a comprehensive library of HR courses, from recruitment and onboarding to performance management.  It's a cost-effective way to train and develop our staff, and the platform allows me to track their progress easily",
      name: "Fatima Binta Jallow",
      course: "Human Resources Officer",
      gender: "female",
    },
  ];
  return (
    <div className="tw-mb-32 tw-mt-16 container">
      <div className="">
        <div className="">
          <div data-aos-duration="3000" data-aos="zoom-in-up" className="">
            <h3 className="lg:tw-text-4xl tw-text-xl tw-font-extrabold sansation ">
              What Our Students are Saying
            </h3>
          </div>
          <>
            <Swiper
              breakpoints={{
                320: {
                  slidesPerView: 1,
                },
                480: {
                  slidesPerView: 1,
                },
                768: {
                  slidesPerView: 1,
                },
                1200: {
                  slidesPerView: 3,
                  spaceBetween: 50,
                },
              }}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              className="mySwiper tw-mt-12 tw-pb-32"
              pagination={{
                clickable: true,
                dynamicBullets: true,
              }}
              modules={[Pagination, Autoplay, Mousewheel]}
            >
              {TestimonialArr?.map((item, i) => (
                <SwiperSlide className="tw-mb-20" key={i}>
                  <div
                    data-aos-duration="3000"
                    data-aos="zoom-in-up"
                    className="tw-p-5 tw-h-[420px] tw-border-solid tw-border-[1px]  tw-border-[#0B2239] tw-rounded-2xl"
                    key={item.id}
                  >
                    <div className="tw-flex tw-gap-5 tw-items-center tw-justify-start">
                      <div className="tw-size-14 tw-bg-pink-200 tw-flex tw-items-center tw-justify-center tw-rounded-full">
                        {item?.gender === "female" ? (
                          <img src={Female} alt="" className="tw-size-10" />
                        ) : (
                          <img src={Male} alt="" className="tw-size-10" />
                        )}
                      </div>
                      <div>
                        <h6 className="tw-text-xl tw-capitalize tw-mb-0 segoe tw-text-[#1b1b1bcc] tw-font-bold tw-pt-2">
                          {item.name}
                        </h6>

                        <p className="tw-text-sm tw-mb-0 segoe tw-text-[#1b1b1bee] tw-font-normal">
                          {item.course}
                        </p>
                      </div>
                    </div>
                    <p className="tw-text-[#14213D] tw-leading-8 tw-pt-8 tw-text-bae segoe tw-font-medium">
                      {item.text}
                    </p>
                    <div className="tw-mt-4 lg:tw-mb-2 tw-flex tw-justify-center"></div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </>
        </div>
      </div>
    </div>
  );
};
export const Trusted = () => {
  return (
    <div>
      <div className="tw-bg-[#EBF1FF] tw-py-12 tw-w-full">
        <h4 className="tw-text-4xl tw-font-bold tw-text-[#1b1b1b] sansation tw-text-center">
          Trusted by the Best
        </h4>
        <p className="tw-text-xl tw-text-center tw-text-black sansation tw-font-normal tw-pt-2">
          Lorem ipsum dolor sit amet consectetur. Diam nunc mi commodo porta.
        </p>
        <div className="tw-mt-8 tw-flex tw-flex-col lg:tw-flex-row tw-justify-center lg:tw-h-14 tw-items-center tw-gap-12">
          <img src={Microsoft} alt="microsoft" className="" />
          <img src={Microsoft} alt="microsoft" className="" />
          <img src={Paypal} alt="microsoft" className="" />
          <img src={Paypal} alt="microsoft" className="" />
          <img src={Google} alt="microsoft" className="" />
        </div>
      </div>
    </div>
  );
};
export const CourseModal = ({ handleclose }) => {
  let course = {
    data: ["Leadership"],
  };
  const [active, setActive] = useState(null);
  const navigate = useNavigate();

  if (!course?.data) return;

  return (
    <div>
      <div className="tw-absolute tw-inset-0 tw-bg-white tw-bg-opacity-30 tw-flex tw-justify-center tw-z-50">
        <div
          style={{
            overflowY: "scroll",
          }}
          data-aos="zoom-up"
          data-aos-duration="1000"
          className="tw-bg-white tw-py-6 tw-px-6 tw-w-96 tw-mt-20 tw-h-96 noScroll"
        >
          <div className="tw-flex tw-justify-between tw-items-center tw-px-4">
            <h6 className="sansation tw-font-bold tw-text-lg tw-text-black tw-uppercase">
              All Categories
            </h6>
            <IconContext.Provider value={{ color: "#000" }}>
              <div
                onClick={handleclose}
                className="tw-cursor-pointer relative tw-z-50"
              >
                <IoMdClose size={20} />
              </div>
            </IconContext.Provider>
          </div>
          <div className="tw-space-y-3 tw-mt-6">
            {course?.data?.map((item) => (
              <div key={item._id}>
                <div
                  onClick={() => {
                    setActive(item._id);
                    navigate(
                      `/courses?course=${item?._id}&title=${item?.title}`
                    );
                  }}
                  className={
                    active === item._id
                      ? "tw-flex tw-items-center tw-justify-between tw-bg-transparent tw-px-4 tw-py-2 tw-cursor-pointer tw-rounded-lg"
                      : "tw-flex tw-items-center tw-justify-between tw-bg-transparent tw-px-4 tw-py-2 tw-rounded-lg tw-cursor-pointer"
                  }
                >
                  <p
                    className={`${
                      active === item._id ? "tw-text-black" : "tw-text-black"
                    } tw-text-[13px] tw-font-normal Nunito`}
                  >
                    {item?.title}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export const Junks = () => {
  const TestimonialArr = [
    {
      id: 1,
      text: "I came to BCT Academy after graduating from university to be trained in Front-End Development. The learning was fun and intensive, with plenty of hands-onpractice. I had the opportuni to wokrk on real-world projects. I applied for and was hired by BCT as a junior web developer, and I am also a facilitator in the Academy.",
      image: "",
      name: "Hussain Babatunde",
      course: "Frontend Developer",
    },
    {
      id: 2,
      text: "BCT has a conducive environment for learning, I felt welcomed from the moment I walked through the door.  I had young and vibrant motivational tutors who really helped to support me along my journey With the practicality of the training and my steadfastness, I gained an employment into one of the top financial service company as a Data Analyst.",
      image: "",
      name: "Okafor Chimezie Kingsley",
      course: "Data Analysit",
    },
    {
      id: 3,
      text: "Everyday at BCT Academy was very beneficial. I learned new things daily and had the opportunity to practice on real projects, you don't get that kind of experience at universities. I really recommend others take the chance of entering the tech world through BCT Academy. Learning here isej interesting and promising.",
      image: "",
      name: "Kemisola Adediwura",
      course: "Backend Developer",
    },
  ];
  return (
    <>
      <div className="tw-w-full lg:tw-grid tw-grid-cols-3 lg:tw-space-y-0 tw-space-y-11 lg:tw-gap-10 tw-mt-10">
        {TestimonialArr?.map((item) => (
          <Swiper
            spaceBetween={30}
            slidesPerView={3}
            onSlideChange={() => console.log("i am working")}
          >
            <SwiperSlide>
              <div>
                <div
                  style={{
                    boxShadow: "4px 4px 20px rgba(0, 0, 0, 0.25)",
                  }}
                  data-aos-duration="3000"
                  data-aos="zoom-in-up"
                  className="lg:tw-pt-8 tw-py-4 lg:tw-py-0 overflow-visible tw-px-10 tw-bg-[#dbecff] tw-rounded-2xl"
                  key={item.id}
                >
                  <img src={Quote} alt="" className="tw-mx-auto" />
                  <p className="tw-text-[#14213D] tw-leading-8 tw-pt-8 tw-text-bae segoe tw-text-center tw-font-medium">
                    {item.text}
                  </p>
                  <div className="tw-mt-4 lg:tw-mb-2 tw-flex tw-justify-center">
                    <Rating size={20} fillColor="yellow" initialValue={5} />
                  </div>
                  <div className="tw-bg-w-full tw-mt-4 lg:tw-mt-0 lg:-tw-mb-10">
                    <img src={Image} alt="" className="tw-mx-auto" />
                    <h6 className="tw-text-xl segoe tw-text-[#1b1b1bcc] tw-font-bold tw-text-center tw-pt-2">
                      {item.name}
                    </h6>

                    <p className="tw-text-base tw-text-center segoe tw-text-[#1b1b1bee] tw-font-normal">
                      {item.course}
                    </p>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        ))}
      </div>
    </>
  );
};

const AboutUs = () => {
  return (
    <div>
      <div className="w-full tw-my-20 tw-py-10">
        <div className="container tw-bg-transparent">
          <h4 className="tw-text-4xl tw-text-center tw-font-bold">About Us</h4>

          <div className="tw-grid lg:tw-grid-cols-9 tw-items-center tw-gap-3 tw-mt-10">
            <div className="tw-col-span-9 lg:tw-col-span-1 ">
              <div className="tw-bg-[#0B2239] tw-text-white tw-pt-6 lg:tw-pt-20 tw-pb-6 tw-items-center tw-flex tw-justify-center tw-flex-col  lg:tw-rounded-tl-full">
                <p className=" tw-text-2xl tw-mb-0 tw-font-500">45+</p>
                <p className=" tw-text-xl tw-mb-0">Courses</p>
              </div>
              <div className="tw-bg-[#0B2239] tw-text-white tw-pt-6 tw-pb-6 tw-items-center tw-flex tw-my-[1px] tw-justify-center tw-flex-col">
                <p className=" tw-text-2xl tw-mb-0 tw-font-500">100+</p>
                <p className=" tw-text-xl tw-mb-0">Videos</p>
              </div>
              <div className="tw-bg-[#0B2239] tw-text-white tw-pt-6 tw-pb-6 tw-items-center tw-flex tw-my-[1px] tw-justify-center tw-flex-col">
                <p className=" tw-text-2xl tw-mb-0 tw-font-500">500+</p>
                <p className=" tw-text-xl tw-mb-0">Hours</p>
              </div>
              <div className="tw-bg-[#0B2239] tw-text-white tw-pt-6 tw-pb-6 lg:tw-pb-20 tw-items-center tw-flex tw-justify-center tw-flex-col lg:tw-rounded-bl-full">
                <p className=" tw-text-2xl tw-mb-0 tw-font-500">120+</p>
                <p className=" tw-text-xl tw-mb-0">Students</p>
              </div>
            </div>

            <div className="tw-col-span-9 lg:tw-col-span-4">
              <img
                src={require("../../assets/fineladylaptop.png")}
                className=" tw:-h-[50vh] md:tw-h-[90vh]"
                alt=""
              />
            </div>

            <div className="tw-col-span-9 md:tw-col-span-4">
              <p className="tw-leading-loose lg:tw-text-base tw-text-sm">
                Welcome to ICS Sourcing Academy where learning meets limitless
                possibilities. Our platform is designed to empower individuals
                from diverse backgrounds to pursue their educational goals with
                ease. It is our goal to make learning accessible, engaging, and
                impactful for all. We believe that education is the key to
                personal growth, professional success, and societal advancement.{" "}
              </p>
              <h4 className="tw-text-[#0B2239] tw-font-semibold tw-text-2xl tw-py-3">
                What We Offer
              </h4>

              <p className="tw-leading-loose lg:tw-text-base tw-text-sm">
                Wide Range of Subjects: Explore a rich variety of courses in
                Business, Management, Personal Development and more. Whether you
                are passionate about self development eager to learn a skill, or
                interested in mastering business dynamics, we have something for
                everyone.
              </p>

              <button className="tw-text-white tw-bg-[#0B2239] tw-w-40 tw-text-xl tw-py-2 tw-px-5 tw-rounded-3xl">
                Get Started
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const Why = () => {
  return (
    <div>
      {/* <div className="tw-my-28 container tw-flex tw-flex-col lg:tw-flex-row tw-gap-12 lg:tw-gap-0 tw-justify-between"> */}
      <div
        data-aos="zoom-in-up"
        data-aos-duration="1500"
        className="tw-mt-20 container tw-grid tw-grid-cols-7 tw-gap-5"
      >
        <div className="tw-col-span-7 lg:tw-col-span-3">
          <p className="lg:tw-text-4xl tw-text-2xl tw-font-extrabold">
            Why Choose Us For <br />{" "}
            <p className="lg:tw-text-4xl tw-text-2xl tw-font-extrabold"></p>
          </p>

          <p className="lg:tw-text-xl tw-mt-8">
            Start building your career by registering for any of our courses
          </p>

          <div className="tw-flex tw-mt-8 tw-bg-[#C9CED2] tw-p-3 tw-rounded tw-items-center">
            <img
              alt=""
              src={require("../../assets/whyperson.png")}
              className="tw-w-[40px] tw-h-[40px] tw-mr-3"
            />
            <p className="tw-text-xl tw-mb-0">Flexible Learning Options</p>
          </div>

          <div className="tw-flex tw-mt-8 tw-bg-[#C9CED2] tw-p-3 tw-rounded tw-items-center">
            <img
              alt=""
              src={require("../../assets/whypeople.png")}
              className="tw-w-[40px] tw-h-[40px] tw-mr-3"
            />
            <p className="tw-text-xl tw-mb-0">Supportive Community</p>
          </div>

          <div className="tw-flex tw-mt-8 tw-bg-[#C9CED2] tw-p-3 tw-rounded tw-items-center">
            <img
              alt=""
              src={require("../../assets/whytag.png")}
              className="tw-w-[40px] tw-h-[40px] tw-mr-3"
            />
            <p className="tw-text-xl tw-mb-0">Continuous Improvement</p>
          </div>
        </div>
        <div className=" tw-col-span-4">
          <img src={require("../../assets/whyman.png")} alt="" />
        </div>
      </div>
    </div>
  );
};
