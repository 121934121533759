import React, { useEffect, useState, useRef } from "react";
import MainContainer from "../../components/maincontainer";
import MainHeader from "../../components/mainheader";
import {
	OptionsInput,
	SelectInput,
	TextArea,
	TextInput,
	VideoInput,
} from "../../components/inputs";
import { Button } from "../../components/button";
import { toast } from "react-toastify";
import useCourseStore from "../../data/stores/coursestore";
import useErrorStore from "../../data/stores/errorstore";
import { apiCall } from "../../data/stores/authstore";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { HiTrash, HiPlus } from "react-icons/hi";
// import ReactQuill from "react-quill";

const AddCourse = () => {
	const [step, setStep] = useState(1),
		[moduleData, setModuleData] = useState({
			moduleName: "",
			courses: [],
		}),
		[courseData, setCourseData] = useState({
			courseName: "",
			quizzes: [],
		}),
		[quizData, setQuizData] = useState({
			quizName: "",
		}),
		handleModuleSubmit = e => {
			e?.preventDefault();
			setStep(2);
		},
		handleCourseSubmit = e => {
			e?.preventDefault();
			setCourseData({
				...courseData,
				modules: [...courseData?.quizzes, moduleData],
			});
			setModuleData({
				moduleName: "",
				courses: [],
			});
			setStep(3);
		},
		handleCourseSection = e => {
			e?.preventDefault();
			setCourseData({
				...courseData,
				modules: [...courseData?.quizzes, moduleData],
			});
			setModuleData({
				moduleName: "",
				courses: [],
			});
			setStep(4);
		},
		handleCourseResource = e => {
			e?.preventDefault();
			setCourseData({
				...courseData,
				modules: [...courseData?.quizzes, moduleData],
			});
			setModuleData({
				moduleName: "",
				courses: [],
			});
			setStep(5);
		},
		handleQuizSubmit = e => {
			e?.preventDefault();
			setModuleData({
				...moduleData,
				courses: [...moduleData?.courses, quizData],
			});
			setQuizData({
				quizName: "",
			});
		},
		[getSearch] = useSearchParams(),
		{ state } = useLocation(),
		[datum, setDatum] = useState(null);

	useEffect(() => {
		let typer = getSearch?.get("type");
		if (typer) {
			if (typer === "module") setStep(2);
			if (typer === "section") setStep(3);
			if (typer === "resource") setStep(4);
			if (typer === "quiz") setStep(5);
		}
	}, [getSearch]);

	useEffect(() => {
		if (state) {
			setDatum(state);
		}
	}, [state]);

	return (
		<div>
			<MainContainer>
				<MainHeader text="Courses" small={"Add a new course"} />
				<div className="tw-w-full tw-bg-white tw-rounded-3xl tw-p-4">
					{step === 1 && (
						<AddCourseMain
							handleNext={handleModuleSubmit}
							datum={getSearch?.get("type") === "course" ? datum : null}
						/>
					)}
					{step === 2 && (
						<AddCourseModule
							handleNext={handleCourseSubmit}
							datum={getSearch?.get("type") === "module" ? datum : null}
						/>
					)}
					{step === 3 && (
						<AddCourseSection
							handleNext={handleCourseSection}
							datum={getSearch?.get("type") === "section" ? datum : null}
						/>
					)}
					{step === 4 && (
						<AddCourseResources
							handleNext={handleCourseResource}
							datum={getSearch?.get("type") === "resource" ? datum : null}
						/>
					)}
					{step === 5 && (
						<AddCourseQuiz
							handleNext={handleQuizSubmit}
							datum={getSearch?.get("type") === "quiz" ? datum : null}
						/>
					)}
				</div>
			</MainContainer>
		</div>
	);
};

export default AddCourse;

export const AddCourseMain = ({ handleNext, datum }) => {
	let init = {
			title: "",
			caption: "",
			duration: "",
			description: "",
			skillLevel: "",
			language: "english",
			softwares: "",
			image: null,
			image2: null,
			courseDuration: "",
			amount: "",
			benefits: [],
			newBenefits: "",
		},
		[state, setState] = useState(init),
		[loading, setLoading] = useState(false),
		// [isEdit, setIsEdit] = useState(false),
		textChange = ({ target: { value, name } }) => {
			setState({ ...state, [name]: value });
		},
		{ addCourse, updateCourse } = useCourseStore(),
		{ returnErrors } = useErrorStore(),
		navigate = useNavigate(),
		[getSearch] = useSearchParams();

	useEffect(() => {
		if (datum) {
			setState({ ...datum, softwares: datum?.softwares?.toString() });
		}
	}, [datum]);

	let handleSubmit = async e => {
		e?.preventDefault();
		if (!state?.title) return toast.info("Product title is required");
		let newState = {
			...state,
			// softwares: state?.softwares?.split(",")?.map(item => item?.trim()),
		};
		delete newState?.students;
		delete newState?.module;
		delete newState?.rating;
		delete newState?.resources;
		setLoading(true);
		let { response, errArr, errMsg } = await apiCall(
			"post",
			`/api/v1/course${datum?._id ? `/${datum?._id}` : "/institution"}`,
			newState
		);
		console?.log({ response, errArr, errMsg });
		if (errArr) {
			setLoading(false);
			return returnErrors(errArr);
		}
		if (errMsg) {
			setLoading(false);
			return toast.error(errMsg);
		}
		setLoading(false);
		if (response) {
			if (datum?._id) updateCourse(response);
			else addCourse(response);
			setState(init);
			if (getSearch?.get("type")) navigate(-1);
			else if (handleNext) handleNext(e);
			return;
		}
		setLoading(false);
	};

	useEffect(() => {
		if (datum) setState({ ...state, ...datum });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [datum]);

	// useEffect(() => {
	// 	if (isEdit) setState({ ...state, newBenefits: isEdit });
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [isEdit]);

	// let handleChangeImage = name => e => {
	// 	const file = e?.target.files[0];
	// 	let err = "";
	// 	if (!file) return (err = `File, ${file?.name} does not exist`);
	// 	if (err) {
	// 		return toast.error(err);
	// 	} else {
	// 		setState({ ...state, [name || "image"]: file });
	// 	}
	// };

	return (
		<div>
			<h3 className="tw-text-base tw-font-bold tw-text-myblue satoshi">
				Add New Course<span className="tw-font-medium"> </span>
			</h3>
			<form onSubmit={handleSubmit} className="tw-mt-10 lg:tw-w-1/2">
				<div className="tw-space-y-4">
					<TextInput
						label={"Name of Course"}
						value={state?.title}
						onChange={textChange}
						name={"title"}
					/>
					{/* <TextInput
						label={"Course Amount"}
						value={state?.amount}
						setState={e => setState({ ...state, amount: e })}
						onChange={textChange}
						name={"amount"}
						type={"tel"}
					/> */}
					<TextInput
						label={"Total Video Play Length(mins)"}
						value={state?.duration}
						setState={e => setState({ ...state, duration: e })}
						onChange={textChange}
						name={"duration"}
						type={"tel"}
						noFormat
					/>
					<TextInput
						label={"Course Duration(weeks)"}
						value={state?.courseDuration}
						setState={e => setState({ ...state, courseDuration: e })}
						onChange={textChange}
						name={"courseDuration"}
						type={"tel"}
						noFormat
					/>
					<TextArea
						label={"Course overview"}
						value={state?.description}
						onChange={textChange}
						name="description"
						setState={e =>
							setState(prev => {
								let newPrev = { ...prev, description: e };
								return newPrev;
							})
						}
					/>
				</div>
				<div className="tw-mt-8 tw-flex tw-gap-5 tw-items-center">
					<Button
						onClick={handleSubmit}
						loading={loading}
						children={"Continue"}
						type="submit"
					/>
					<h6 className="tw-text-base satoshi tw-font-medium tw-text-myblue tw-underline tw-cursor-pointer">
						Add Another Course
					</h6>
				</div>
			</form>
		</div>
	);
};

export const AddCourseModule = ({ handleNext, datum }) => {
	let init = {
			title: "",
			description: "",
		},
		[state, setState] = useState(init),
		[loading, setLoading] = useState(false),
		// [isEdit, setIsEdit] = useState(false),
		textChange = ({ target: { value, name } }) => {
			setState({ ...state, [name]: value });
		},
		{ updateCourse, institution } = useCourseStore(),
		{ returnErrors } = useErrorStore(),
		[getSearch] = useSearchParams(),
		navigate = useNavigate();

	let handleSubmit = async e => {
		e?.preventDefault();
		if (!state?.title) return toast.info("Module title is required");
		let newState = state;
		delete newState?.reviews;
		delete newState?.section;
		newState.course = getSearch?.get("course")
			? getSearch?.get("course")
			: institution?.docs?.[0]?._id;

		setLoading(true);
		let { response, errArr, errMsg } = await apiCall(
			"post",
			`/api/v1/course/manage-module${
				datum?._id ? `/${datum?._id}` : "/institution"
			}`,
			newState
		);
		console?.log({ response, errArr, errMsg });
		if (errArr) {
			setLoading(false);
			return returnErrors(errArr);
		}
		if (errMsg) {
			setLoading(false);
			return toast.error(errMsg);
		}
		setLoading(false);
		if (response) {
			updateCourse(response);
			setState(init);
			if (getSearch?.get("type")) navigate(-1);
			else if (handleNext) handleNext(e);
			return;
		}
		setLoading(false);
	};

	useEffect(() => {
		if (datum) setState({ ...state, ...datum });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [datum]);

	return (
		<div>
			<h3 className="tw-text-base tw-font-bold tw-text-myblue satoshi">
				Add New Course ~<span className="tw-font-medium"> Module</span>
			</h3>
			<form action="" className="tw-mt-10 lg:tw-w-1/2">
				<div className="tw-space-y-4">
					<TextInput
						label={"Title of Module"}
						value={state?.title}
						onChange={textChange}
						name={"title"}
					/>
					<TextArea
						label={"Module overview"}
						value={state?.description}
						onChange={textChange}
						name="description"
						setState={e =>
							setState(prev => {
								let newPrev = { ...prev, description: e };
								return newPrev;
							})
						}
					/>
				</div>
				<div className="tw-mt-8 tw-flex tw-gap-5 tw-items-center">
					<Button
						onClick={handleSubmit}
						loading={loading}
						children={"Continue"}
						type="submit"
					/>
				</div>
			</form>
		</div>
	);
};

export const AddCourseSection = ({ handleNext, datum }) => {
	let init = {
			title: "",
			description: "",
		},
		[state, setState] = useState(init),
		[loading, setLoading] = useState(false),
		// [isEdit, setIsEdit] = useState(false),
		textChange = ({ target: { value, name } }) => {
			setState({ ...state, [name]: value });
		},
		{ updateCourse, institution } = useCourseStore(),
		{ returnErrors } = useErrorStore(),
		[getSearch] = useSearchParams(),
		navigate = useNavigate();

	let handleSubmit = async e => {
		e?.preventDefault();
		if (!state?.title) return toast.info("Module title is required");
		let newState = state;
		newState.module = getSearch?.get("module")
			? getSearch?.get("module")
			: institution?.docs?.[0]?.module?.[
					institution?.docs?.[0]?.module?.length - 1
			  ]?._id;

		setLoading(true);
		console.log({ newState, institution });
		if (
			state?.videoLink &&
			!state?.videoLink?._id &&
			typeof state?.videoLink !== "string"
		) {
			// let { response, errArr, errMsg } = await apiCall("file", "/api/v1/file", {
			// 	logo: state?.videoLink,
			// });
			// if (errArr) {
			// 	setLoading(false);
			// 	return returnErrors(errArr);
			// }
			// if (errMsg) {
			// 	setLoading(false);
			// 	return toast.error(errMsg);
			// }
			// newState.videoLink = response?.data?.[0];
			newState.intendedFile = newState?.videoLink;
			newState.intendedFileName = "videoLink";
		}

		let { response, errArr, errMsg } = await apiCall(
			"post",
			`/api/v1/course/manage-section${
				datum?._id ? `/${datum?._id}` : "/institution"
			}`,
			newState,
			null,
			{
				headers: {
					"Content-Type": "multipart/form-data",
				},
			}
		);
		console?.log({ response, errArr, errMsg });
		if (errArr) {
			setLoading(false);
			return returnErrors(errArr);
		}
		if (errMsg) {
			setLoading(false);
			return toast.error(errMsg);
		}
		setLoading(false);
		if (response) {
			updateCourse(response);
			if (getSearch?.get("type")) navigate(-1);
			else if (handleNext) handleNext(e);
			// setState(init);
			return;
		}
		setLoading(false);
	};

	let handleChangeImage = (e, name, ty) => {
		const file = e?.target.files[0];
		let err = "";
		console.log({ err, file }, "one");
		if (!file) err = `File, ${file?.name} does not exist`;
		if (!file.type.includes(ty || "image"))
			err = `File, ${file?.name} format not supported`;
		console.log({ err, file });
		if (err) {
			return toast.error(err);
		} else {
			setState({ ...state, [name || "image"]: file });
		}
	};

	useEffect(() => {
		if (datum) setState({ ...state, ...datum });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [datum]);

	console.log({ datum });
	return (
		<div>
			<h3 className="tw-text-base tw-font-bold tw-text-myblue satoshi">
				Add New Course Module ~<span className="tw-font-medium"> Section</span>
			</h3>
			<form action="" className="tw-mt-10 lg:tw-w-1/2">
				<div className="tw-space-y-4">
					<TextInput
						label={"Title of Module Section"}
						value={state?.title}
						onChange={textChange}
						name={"title"}
					/>
					<TextArea
						label={"Section overview"}
						value={state?.description}
						onChange={textChange}
						name="description"
						setState={e =>
							setState(prev => {
								let newPrev = { ...prev, description: e };
								return newPrev;
							})
						}
					/>
					<VideoInput
						accept="video/*"
						name="videoLink"
						onChange={e => handleChangeImage(e, "videoLink", "video")}
						ty="video"
						state={state}
						setState={setState}
					/>
					<TextArea
						label={"Section transcript"}
						value={state?.transcript}
						onChange={textChange}
						name="transcript"
						setState={e =>
							setState(prev => {
								let newPrev = { ...prev, transcript: e };
								return newPrev;
							})
						}
					/>
				</div>
				<div className="tw-mt-8 tw-flex tw-gap-5 tw-items-center">
					<Button
						onClick={handleSubmit}
						loading={loading}
						children={"Continue"}
						type="submit"
					/>
				</div>
			</form>
		</div>
	);
};

export const AddCourseResources = ({ handleNext, datum }) => {
	let init = {
			title: "",
			description: "",
		},
		[state, setState] = useState(init),
		[loading, setLoading] = useState(false),
		// [isEdit, setIsEdit] = useState(false),
		textChange = ({ target: { value, name } }) => {
			setState({ ...state, [name]: value });
		},
		{ updateCourse, institution } = useCourseStore(),
		{ returnErrors } = useErrorStore(),
		[getSearch] = useSearchParams(),
		navigate = useNavigate();

	let handleSubmit = async e => {
		e?.preventDefault();
		if (!state?.title) return toast.info("Module title is required");
		let newState = state;
		newState.course = getSearch?.get("course")
			? getSearch?.get("course")
			: institution?.docs?.[0]?._id;

		setLoading(true);
		console.log({ newState, institution });
		if (state?.file && !state?.file?._id && typeof state?.file !== "string") {
			newState.intendedFile = newState?.file;
			newState.intendedFileName = "file";
		}

		let { response, errArr, errMsg } = await apiCall(
			"post",
			`/api/v1/course/manage-resource${
				datum?._id ? `/${datum?._id}` : "/institution"
			}`,
			newState,
			null,
			{
				headers: {
					"Content-Type": "multipart/form-data",
				},
			}
		);
		console?.log({ response, errArr, errMsg });
		if (errArr) {
			setLoading(false);
			return returnErrors(errArr);
		}
		if (errMsg) {
			setLoading(false);
			return toast.error(errMsg);
		}
		setLoading(false);
		if (response) {
			updateCourse(response);
			if (getSearch?.get("type")) navigate(-1);
			else if (handleNext) handleNext(e);
			// setState(init);
			return;
		}
		setLoading(false);
	};

	let handleChangeImage = (e, name, ty) => {
		const file = e?.target.files[0];
		let err = "";
		console.log({ err, file }, "one");
		if (!file) err = `File, ${file?.name} does not exist`;
		if (!file.type.includes(ty || "image"))
			err = `File, ${file?.name} format not supported`;
		console.log({ err, file });
		if (err) {
			return toast.error(err);
		} else {
			setState({ ...state, [name || "image"]: file });
		}
	};

	useEffect(() => {
		if (datum) setState({ ...state, ...datum });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [datum]);

	console.log({ datum });
	return (
		<div>
			<h3 className="tw-text-base tw-font-bold tw-text-myblue satoshi">
				Add New Course ~<span className="tw-font-medium"> Resources</span>
			</h3>
			<form action="" className="tw-mt-10 lg:tw-w-1/2">
				<div className="tw-space-y-4">
					<TextInput
						label={"Title of Resources"}
						value={state?.title}
						onChange={textChange}
						name={"title"}
					/>
					<TextArea
						label={"Resources overview"}
						value={state?.description}
						onChange={textChange}
						name="description"
						setState={e =>
							setState(prev => {
								let newPrev = { ...prev, description: e };
								return newPrev;
							})
						}
					/>
					{!datum?._id && (
						<SelectInput
							label={"Type of Resources"}
							value={state?.type}
							onChange={textChange}
							name={"type"}
							options={[
								{ title: "Documents, e.t.c", _id: "file" },
								{ title: "Links, e.t.c", _id: "link" },
							]}
						/>
					)}
					{state?.type === "file" && (
						<VideoInput
							accept="application/*"
							name="file"
							onChange={e => handleChangeImage(e, "file", "application")}
							ty="application"
							state={state}
							setState={setState}
						/>
					)}
					{state?.type === "link" && (
						<TextInput
							label={"Resources Link"}
							value={state?.link}
							onChange={textChange}
							name={"link"}
							type="url"
						/>
					)}
				</div>
				<div className="tw-mt-8 tw-flex tw-gap-5 tw-items-center">
					<Button
						onClick={handleSubmit}
						loading={loading}
						children={"Continue"}
						type="submit"
					/>
				</div>
			</form>
		</div>
	);
};

export const AddCourseQuiz = ({ handleNext }) => {
	let init = { question: "", answer: "", questionType: "text" },
		[stateData2, setStateData2] = useState({
			deliverySeconds: "",
			hours: "",
			minutes: "",
		}),
		textChange2 =
			name =>
			({ target: { value } }) => {
				setStateData2({ ...stateData2, [name]: value });
			};
	const [questions, setQuestions] = useState([init]);

	let location = useLocation(),
		navigate = useNavigate(),
		[loading, setLoading] = useState(false),
		{ returnErrors } = useErrorStore(),
		[getSearch] = useSearchParams(),
		{ institution } = useCourseStore();

	useEffect(() => {
		if (!location?.state) navigate(-1);
	}, [location?.state, navigate]);

	const handleInputChangeForMutipleItem = (event, index, field, option) => {
		const { value } = event.target;
		let itemValue = field === "answer" ? option : value;

		// if (field === "price") {
		// 	let parsedValue = parseFloat(itemValue);
		// 	if (Number.isInteger(parsedValue)) {
		// 		parsedValue = parsedValue.toFixed(0);
		// 		itemValue = parsedValue;
		// 	}
		// }

		setQuestions(prevRows => {
			const newRows = [...prevRows];
			newRows[index][field] = itemValue;
			return newRows;
		});
	};

	const handleDeleteRowForMutipleItem = index => {
		setQuestions(prevRows => {
			const newRows = [...prevRows];
			newRows.splice(index, 1);
			return newRows;
		});
	};

	let handleChangeImage =
		(index, field, options = "") =>
		e => {
			const file = e.target.files[0];
			let err = "";

			if (!file) return (err = `File, ${file?.name} does not exist`);
			if (!file.type.includes("image"))
				return (err = `File, ${file?.name} format not supported`);

			if (err) {
				return toast.error(err);
			} else {
				// setLogo(file);

				setQuestions(prevRows => {
					const newRows = [...prevRows];
					newRows[index][field] = file;
					newRows[index][`${options ? field : ""}questionType`] = "file";
					return newRows;
				});
			}
		};

	let handleChangeImageRemoved =
		(index, field, options = "") =>
		() => {
			setQuestions(prevRows => {
				const newRows = [...prevRows];
				newRows[index][field] = null;
				newRows[index][`${options ? field : ""}questionType`] = "text";
				return newRows;
			});
		};

	const addRowForMutipleItem = () => {
		const newRow = init;
		setQuestions([...questions, newRow]);
	};
	// console.log({ questions });
	let handleSubmit = async () => {
		let errArr1 = [],
			returnedData = [];
		if (questions?.length === 0) return toast.info("Questions required");

		for (let e = 0; e < questions.length; e++) {
			let element = questions?.[e],
				newOption = [];
			element.options = [];
			element?.options?.push({
				option: element?.option_a || "",
				isCorrect: element?.answer === "option_a",
				file: element?.option_afile,
				optionType: element?.option_afilequestionType || "text",
			});
			element?.options?.push({
				option: element?.option_b || "",
				isCorrect: element?.answer === "option_b",
				file: element?.option_bfile,
				optionType: element?.option_bfilequestionType || "text",
			});
			element?.options?.push({
				option: element?.option_c || "",
				isCorrect: element?.answer === "option_c",
				file: element?.option_cfile,
				optionType: element?.option_cfilequestionType || "text",
			});
			element?.options?.push({
				option: element?.option_d || "",
				isCorrect: element?.answer === "option_d",
				file: element?.option_dfile,
				optionType: element?.option_dfilequestionType || "text",
			});
			if (!element?.question)
				errArr1.push({
					message: `Question ${e + 1} is required`,
					path: "question",
				});
			if (!element?.options)
				errArr1.push({
					message: `Question ${e + 1} options is required`,
					path: "options",
				});
			if (element?.questionType)
				if (element?.questionType !== "text") {
					if (!element?.file)
						errArr1.push({
							message: `Question ${e + 1} file is required`,
							path: "file",
						});
				}

			if (!hasUniqueValue(element?.options, "isCorrect", true)) {
				errArr1.push({
					message: `Question ${e + 1} options requires a single answer`,
					path: "isCorrect",
				});
			}
			// console.log({ element });
			for (let o = 0; o < element?.options.length; o++) {
				let elementOption = element?.options?.[o];
				if (!elementOption?.option && !elementOption?.file)
					errArr1.push({
						message: `Question ${e + 1} Option ${o + 1} is required`,
						path: "option",
					});
				if (elementOption?.optionType)
					if (elementOption?.optionType !== "text") {
						if (!elementOption?.file)
							errArr1.push({
								message: `Question ${e + 1} Option ${o + 1} file is required`,
								path: "file",
							});
					}
				newOption?.push({ ...elementOption });
			}
			returnedData?.push({ ...element, options: newOption });
		}
		// console.log({ returnedData });
		if (errArr1?.length > 0) return returnErrors({ error: errArr1 });
		setLoading(true);
		let newReturnedData = [];
		for (let r = 0; r < returnedData.length; r++) {
			let element = returnedData[r],
				newOption = [];

			if (element?.file) {
				let { response, errArr, errMsg } = await apiCall(
					"file",
					"/api/v1/file",
					{
						logo: element?.file,
					}
				);
				if (errArr) {
					setLoading(false);
					return returnErrors(errArr);
				}
				if (errMsg) {
					setLoading(false);
					return toast.error(errMsg);
				}
				element.file = response?.data?.[0];
			}
			for (let o = 0; o < element?.options.length; o++) {
				let elementOption = element?.options?.[o];
				if (elementOption?.file) {
					let { response, errArr, errMsg } = await apiCall(
						"file",
						"/api/v1/file",
						{
							logo: elementOption?.file,
						}
					);
					if (errArr) {
						setLoading(false);
						return returnErrors(errArr);
					}
					if (errMsg) {
						setLoading(false);
						return toast.error(errMsg);
					}
					elementOption.file = response?.data?.[0];
				}

				newOption?.push({ ...elementOption });
			}
			element.options = newOption;
			newReturnedData?.push(element);
		}
		let newTime;

		let newMinutes = 0,
			newHours = 0;
		if (stateData2?.hours) {
			newHours = Number(stateData2?.hours) * 60;
		}
		if (stateData2?.minutes) {
			newMinutes = Number(stateData2?.minutes);
		}
		newTime = Number(Number(newMinutes) + Number(newHours));

		let { response, errArr, errMsg } = await apiCall(
			"post",
			`/api/v1/course/manage-quiz/institution`,
			{
				questions: newReturnedData,
				duration: newTime,
				module: getSearch?.get("module") || null,
				course: getSearch?.get("course") || institution?.docs?.[0]?._id || null,
			}
		);
		console?.log({ response, errArr, errMsg });
		if (errArr) {
			setLoading(false);
			return returnErrors(errArr);
		}
		if (errMsg) {
			setLoading(false);
			return toast.error(errMsg);
		}
		setLoading(false);
		if (response) {
			// toast.success(response?.msg || response?.message);
			navigate("/courses");
			return;
		}
		setLoading(false);
		setLoading(false);
	};

	return (
		<div>
			<h3 className="tw-text-base tw-font-bold tw-text-myblue satoshi">
				Add Course ~
				<span className="tw-font-medium">
					{" "}
					{getSearch?.get("type") === "module" ? `Module ~` : ""} Quiz{" "}
				</span>
			</h3>
			<form action="" className="tw-mt-10 lg:tw-w-1/2">
				<div className="tw-space-y-4">
					{questions?.map((q, i) => (
						<CreateQuestion
							key={i}
							question={q}
							handleDeleteRowForMutipleItem={handleDeleteRowForMutipleItem}
							handleInputChangeForMutipleItem={handleInputChangeForMutipleItem}
							index={i}
							questionsLength={questions?.length}
							handleChangeImage={handleChangeImage}
							handleChangeImageRemoved={handleChangeImageRemoved}
							textChange2={textChange2}
						/>
					))}
					<div className="tw-flex tw-justify-end">
						<button
							type="button"
							onClick={addRowForMutipleItem}
							className="tw-mb-4 tw-px-4 tw-py-2 tw-bg-[#0b2239] tw-rounded-full tw-text-white tw-w-12 tw-h-12 tw-flex tw-justify-center tw-items-center tw-text-lg">
							<HiPlus className="tw-icon icon tw-font-bold" size={24} />
						</button>
					</div>
				</div>
				<div className="tw-mt-8 tw-flex tw-gap-5 tw-items-center">
					<Button
						onClick={handleSubmit}
						children={"Upload Course"}
						loading={loading}
						type="button"
					/>
				</div>
			</form>
		</div>
	);
};

function hasUniqueValue(array, propertyName, desiredValue) {
	let count = 0;

	for (let i = 0; i < array.length; i++) {
		if (array[i][propertyName]) {
			count++;
			if (count > 1) {
				return false; // More than one object has the desired value
			}
		}
	}

	return count === 1; // Only one object has the desired value
}

const CreateQuestion = ({
	question,
	handleInputChangeForMutipleItem,
	index,
	handleDeleteRowForMutipleItem,
	questionsLength,
	handleChangeImage,
	handleChangeImageRemoved,
}) => {
	let fileInputRef = useRef();
	return (
		<div className="tw-mb-4 tw-py-4">
			<TextInput
				label={`Question ${index + 1}:`}
				value={question?.question}
				onChange={event =>
					handleInputChangeForMutipleItem(event, index, "question")
				}
			/>
			<div className="tw-flex tw-flex-col tw-items-center tw-gap-4 tw-mt-8">
				{question?.file && (
					<img
						src={question?.file ? URL.createObjectURL(question?.file) : null}
						alt=""
						className="tw-h-48 tw-w-auto tw-rounded-sm tw-border"
					/>
				)}
				<div className="tw-flex tw-gap-6 tw-ms-auto">
					{question?.file && (
						<button
							type="button"
							onClick={handleChangeImageRemoved(index, "file")}
							className="tw-h-8 tw-w-24 tw-bg-main tw-rounded-xl tw-text-white tw-barlow tw-text-base tw-font-normal">
							Remove
						</button>
					)}
					<div>
						<input
							ref={fileInputRef}
							type="file"
							accept="image/*"
							style={{ display: "none" }}
							onChange={handleChangeImage(index, "file")}
							name="image"
						/>
						<button
							type="button"
							className="tw-h-8 tw-w-36 tw-bg-main tw-rounded-xl tw-text-white tw-barlow tw-text-base tw-font-normal tw-hidden"
							onClick={() => fileInputRef.current.click()}>
							Upload Image
						</button>
					</div>
				</div>
			</div>
			<div className="tw-mt-2">
				{/* Option A  */}
				<OptionCreate
					option={"option_a"}
					question={question}
					index={index}
					handleInputChangeForMutipleItem={handleInputChangeForMutipleItem}
					handleChangeImage={handleChangeImage}
					handleChangeImageRemoved={handleChangeImageRemoved}
					title="A"
				/>

				{/* Option B  */}
				<OptionCreate
					option={"option_b"}
					question={question}
					index={index}
					handleInputChangeForMutipleItem={handleInputChangeForMutipleItem}
					handleChangeImage={handleChangeImage}
					handleChangeImageRemoved={handleChangeImageRemoved}
					title="B"
				/>

				{/* Option C  */}
				<OptionCreate
					option={"option_c"}
					question={question}
					index={index}
					handleInputChangeForMutipleItem={handleInputChangeForMutipleItem}
					handleChangeImage={handleChangeImage}
					handleChangeImageRemoved={handleChangeImageRemoved}
					title="C"
				/>

				{/* Option D  */}
				<OptionCreate
					option={"option_d"}
					question={question}
					index={index}
					handleInputChangeForMutipleItem={handleInputChangeForMutipleItem}
					handleChangeImage={handleChangeImage}
					handleChangeImageRemoved={handleChangeImageRemoved}
					title="D"
				/>
			</div>
			{questionsLength > 1 && (
				<button
					type="button"
					onClick={() => handleDeleteRowForMutipleItem(index)}
					className="tw-mb-4 tw-px-4 tw-py-2 tw-bg-[#0b2239] tw-rounded-full tw-text-white tw-w-12 tw-h-12 tw-flex tw-justify-center tw-items-center tw-text-lg tw-ms-auto">
					<HiTrash className="tw-icon icon tw-font-bold" size={24} />
				</button>
			)}
		</div>
	);
};

export const OptionCreate = ({
	question,
	index,
	handleInputChangeForMutipleItem,
	handleChangeImage,
	handleChangeImageRemoved,
	option,
	title,
}) => {
	let fileInputRef = useRef();

	return (
		<>
			<OptionsInput
				label={`Option ${title}`}
				placeholder
				onChange
				name={`answer${index}`}
				index={index}
				question={question}
				option={option}
				handleInputChangeForMutipleItem={handleInputChangeForMutipleItem}
			/>
			<div className="tw-flex tw-flex-col tw-items-center tw-gap-4 tw-mt-8">
				{question?.[`${option}file`] && (
					<img
						src={
							question?.[`${option}file`]
								? URL.createObjectURL(question?.[`${option}file`])
								: null
						}
						alt=""
						className="tw-h-48 tw-w-auto tw-rounded-sm tw-border"
					/>
				)}
				<div className="tw-flex tw-gap-6 tw-ms-auto">
					{question?.[`${option}file`] && (
						<button
							type="button"
							onClick={handleChangeImageRemoved(index, `${option}file`, "file")}
							className="tw-h-8 tw-w-24 tw-bg-main tw-rounded-xl tw-text-white tw-barlow tw-text-base tw-font-normal">
							Remove
						</button>
					)}
					<div>
						<input
							ref={fileInputRef}
							type="file"
							accept="image/*"
							style={{ display: "none" }}
							onChange={handleChangeImage(index, `${option}file`, "file")}
							name="image"
						/>
						<button
							type="button"
							className="tw-h-8 tw-w-36 tw-bg-main tw-rounded-xl tw-text-white tw-barlow tw-text-base tw-font-normal tw-hidden"
							onClick={() => fileInputRef.current.click()}>
							Upload Image
						</button>
					</div>
				</div>
			</div>
		</>
	);
};
