import React, { useEffect, useState, useContext } from "react";
import MainContainer from "../../components/maincontainer";
import MainHeader from "../../components/mainheader";
import List from "../../assets/list.svg";
import { MainBtn, MainPaginate } from "../../components/button";
import { useNavigate, useLocation } from "react-router-dom";
import { GlobalState } from "../../data/Context";
import usePlanStore from "../../data/stores/planstore";
import useAuthStore, { apiCall } from "../../data/stores/authstore";

export const MainPricing = () => {
	const navigate = useNavigate(),
		{ state } = useLocation(),
		{ numberWithCommas, nairaSign } = useContext(GlobalState),
		{ user } = useAuthStore();

	useEffect(() => {
		if (!state) navigate(-1);
	}, [state, navigate]);
	const Arr = [
		{
			name: "Basic",
			price: "#125",
			features: [
				"10% Discount",
				"All courses",
				"2 month access to course after certificate earned",
				"10 Staffs",
			],
		},
		{
			name: "Professional",
			price: "#125",
			features: [
				"10% Discount",
				"All courses",
				"2 month access to course after certificate earned",
				"10 Staffs",
			],
		},
		{
			name: "Standard",
			price: "#125",
			features: [
				"10% Discount",
				"All courses",
				"2 month access to course after certificate earned",
				"10 Staffs",
			],
		},
	];

	let [datum, setDatum] = useState({ docs: Arr }),
		{ isFound, mainSearch, data, getPlan } = usePlanStore();

	useEffect(() => {
		if (isFound) setDatum(mainSearch);
		else setDatum(data);
	}, [data, isFound, mainSearch]);

	useEffect(() => {
		apiCall("get", "/api/v1/plan", null, getPlan);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	const [itemOffset, setItemOffset] = useState(0);
	let [range] = useState(10);
	const endOffset = itemOffset + range;

	// if (!datum && !data) return <PageLoader />;
	if (!datum) return;

	const currentItems = datum?.docs?.slice(itemOffset, endOffset);
	const pageCount = Math.ceil(datum?.docs?.length / range);

	const handlePageClick = event => {
		const newOffset = (event.selected * range) % datum?.docs?.length;
		setItemOffset(newOffset);
	};

	let amountCalc = item => {
		let discounter = (100 - item?.discount) / 100;

		return state?.amount * discounter;
	};

	return (
		<div>
			<MainContainer>
				<MainHeader text={"Courses"} />
				<div className="tw-w-full tw-bg-white tw-rounded-3xl tw-p-6">
					<h2 className="tw-text-3xl tw-font-extrabold satoshi tw-text-center tw-text-secondary">
						Simple Pricing for Your{" "}
						{user?.privilege === "company" ? "Company" : "Institution"}
					</h2>
					<h6 className="tw-text-center tw-font-normal tw-text-[#252525] tw-text-sm">
						You can invest in your future by studying with us whenever and{" "}
						wherever
					</h6>
					<div className="tw-mt-10 tw-w-11/12 tw-mx-auto tw-grid lg:tw-grid-cols-3 tw-gap-6">
						{currentItems?.map(it => (
							<div className="tw-bg-[#F2F8FF] tw-rounded-xl tw-border tw-border-[#0269D0] tw-p-6">
								<h2 className="tw-text-main tw-text-2xl tw-font-bold satoshi tw-capitalize">
									{it?.name}
								</h2>
								<div className="tw-mt-6 tw-grid">
									{" "}
									<h3 className="tw-text-5xl tw-text-main tw-font-bold satoshi">
										{nairaSign}
										{numberWithCommas(Number(amountCalc(it) || 0).toFixed(0))}
									</h3>
									<small className="tw-text-3xl tw-pt-2 tw-text-main tw-font-bold tw-text-opacity-40 tw-line-through">
										{nairaSign}
										{numberWithCommas(Number(state?.amount.toFixed(0)))}
									</small>
								</div>
								<div className="tw-mt-10 tw-space-y-">
									<div className="tw-flex tw-items-center tw-gap-3">
										<img src={List} alt="" className="" />
										<h6 className="tw-pt-1 tw-text-base tw-text-main tw-font-normal satoshi">
											{it?.discount}% Discount
										</h6>
									</div>
									<div className="tw-flex tw-items-center tw-gap-3">
										<img src={List} alt="" className="" />
										<h6 className="tw-pt-1 tw-text-base tw-text-main tw-font-normal satoshi">
											Maximum number of {it?.noOfPartakers}{" "}
											{user?.privilege === "company" ? "Staffs" : "Students"}
										</h6>
									</div>
									{it?.benefits?.map((feature, f) => (
										<div key={f} className="tw-flex tw-items-center tw-gap-3">
											<img src={List} alt="" className="" />
											<h6 className="tw-pt-1 tw-text-base tw-text-main tw-font-normal satoshi">
												{feature}
											</h6>
										</div>
									))}
								</div>
								<div className="tw-mt-4">
									<MainBtn
										text={"Checkout"}
										onClick={() =>
											navigate("/all-staff", { state: { ...state, plan: it } })
										}
									/>
								</div>
							</div>
						))}
					</div>
					{pageCount > 1 && (
						<MainPaginate
							pageCount={pageCount}
							handlePageClick={handlePageClick}
						/>
					)}
				</div>
			</MainContainer>
		</div>
	);
};

const Pricing = () => <MainPricing />;

export default Pricing;
