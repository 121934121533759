import React from "react";
import Delete from "../../assets/delete.svg";
import { AiOutlineClose } from "react-icons/ai";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { MainBtn } from "../button";

const DeleteModal = ({ handleCloseModal }) => {
  const [modal, setModal] = useState("");
  const handleClose = () => {
    setModal("");
  };
  return (
    <div>
      <div className="tw-fixed tw-z-50 tw-inset-0 tw-bg-myblue tw-bg-opacity-30 tw-flex tw-justify-center tw-h-screen tw-w-full tw-items-center">
        <div className="tw-bg-white tw-rounded-xl tw-w-72 tw-p-4">
          <div className=" tw-flex tw-justify-between">
            <img src={Delete} alt="" className="" />
            <AiOutlineClose
              className="tw-cursor-pointer"
              onClick={handleCloseModal}
            />
          </div>
          <h6 className="tw-text-xl tw-font-bold satoshi tw-text-secondary">
            Delete Module ?
          </h6>
          <p className="tw-text-sm tw-text-secondary satoshi">
            Are you sure you want to delete this Module
          </p>
          <div className="tw-flex tw-mt-4 tw-gap-3 tw-items-center">
            <MainBtn onClick={() => setModal("deleted")} text={"Delete"} />
            <small
              onClick={handleClose}
              className="smallText tw-cursor-pointer"
            >
              Cancel
            </small>
          </div>
        </div>
      </div>
      {modal === "deleted" && <DeletedModal handleClose={handleClose} />}
    </div>
  );
};

const DeletedModal = ({ handleClose }) => {
  const navigate = useNavigate();
  return (
    <div className="tw-fixed tw-z-50 tw-inset-0 tw-bg-myblue tw-bg-opacity-30 tw-flex tw-justify-center tw-h-screen tw-w-full tw-items-center">
      {" "}
      <div className="tw-bg-white tw-rounded-xl tw-w-72 tw-p-4">
        <div className=" tw-flex tw-justify-between">
          <img src={Delete} alt="" className="" />
          <AiOutlineClose className="tw-cursor-pointer" onClick={handleClose} />
        </div>
        <h6 className="tw-text-xl tw-font-bold satoshi tw-text-secondary">
          Module Deleted
        </h6>

        <div className="tw-flex tw-mt-4 tw-gap-3 tw-items-center">
          <MainBtn onClick={() => navigate("/courses")} text={"Okay"} />
        </div>
      </div>
    </div>
  );
};
export default DeleteModal;
