import React from "react";
import Delete from "../../assets/delete.svg";
import { AiOutlineClose } from "react-icons/ai";
import { Button, MainBtn } from "../button";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

const DeleteModal = ({ handleCloseModal, cancel, loading, onClick, title }) => {
	const [modal, setModal] = useState("");
	const handleClose = () => {
		setModal("");
	};
	return (
		<div>
			<div className="tw-fixed tw-z-50 tw-inset-0 tw-bg-myblue tw-bg-opacity-30 tw-flex tw-justify-center tw-h-screen tw-w-full tw-items-center">
				<div className="tw-bg-white tw-rounded-xl tw-w-72 tw-p-4">
					<div className=" tw-flex tw-justify-between">
						<img src={Delete} alt="" className="" />
						<AiOutlineClose
							className="tw-cursor-pointer"
							onClick={handleCloseModal}
						/>
					</div>
					<h6 className="tw-text-xl tw-font-bold satoshi tw-text-secondary">
						Delete {title || "Module"} ?
					</h6>
					<p className="tw-text-sm tw-text-secondary satoshi">
						Are you sure you want to delete this {title || "Module"}
					</p>
					<div className="tw-flex tw-mt-4 tw-gap-3 tw-items-center">
						<Button
							css={
								"tw-h-10 tw-px-4 tw-bg-skyblue tw-rounded-3xl tw-text-base satoshi tw-font-medium tw-text-white"
							}
							type="button"
							children={"Delete"}
							loading={loading}
							onClick={
								onClick
									? e => onClick(e, () => setModal("deleted"))
									: () => setModal("deleted")
							}
						/>
						<small
							onClick={cancel || handleCloseModal || handleClose}
							className="smallText tw-cursor-pointer">
							Cancel
						</small>
					</div>
				</div>
			</div>
			{modal === "deleted" && (
				<DeletedModal handleClose={handleClose} title={title} />
			)}
		</div>
	);
};

const DeletedModal = ({ handleClose, title }) => {
	const navigate = useNavigate(),
		{ page, id } = useParams();
	return (
		<div className="tw-fixed tw-z-50 tw-inset-0 tw-bg-myblue tw-bg-opacity-30 tw-flex tw-justify-center tw-h-screen tw-w-full tw-items-center">
			{" "}
			<div className="tw-bg-white tw-rounded-xl tw-w-72 tw-p-4">
				<div className=" tw-flex tw-justify-between">
					<img src={Delete} alt="" className="" />
					<AiOutlineClose className="tw-cursor-pointer" onClick={handleClose} />
				</div>
				<h6 className="tw-text-xl tw-font-bold satoshi tw-text-secondary">
					{title || "Module"} Deleted
				</h6>

				<div className="tw-flex tw-mt-4 tw-gap-3 tw-items-center">
					<MainBtn
						onClick={() => navigate(`/${page}${id ? `/${id}` : ""}`)}
						text={"Okay"}
					/>
				</div>
			</div>
		</div>
	);
};
export default DeleteModal;
