import React from "react";
import { MainBtn } from "../button";
import { useNavigate } from "react-router-dom";
import useAuthStore from "../../data/stores/authstore";

const ParticipantTab = ({ datum }) => {
	const { userPage } = useAuthStore();

	let navigate = useNavigate();
	return (
		<div>
			<div className="tw-w-full tw-space-y-4">
				{datum?.partakers?.map((item, i) => (
					<div
						key={i}
						style={{
							border: "0.5px solid rgba(37, 37, 37, 0.50)",
						}}
						className="tw-px-4 tw-w-full tw-cursor-pointer tw-rounded-lg">
						<div className="tw-h-12 tw-flex tw-justify-between tw-items-center tw-w-full">
							<small className="tw-text-sm tw-font-medium tw-text-main satoshi">
								{item?.firstName} {item?.lastName}
							</small>
							<small
								className={`tw-text-xs tw-font-medium tw-capitalize ${
									item?.progress?.status === "in-progress"
										? "tw-text-secondary"
										: item?.progress?.status === "completed"
										? "tw-text-[#0B6C25]"
										: "tw-text-[#E34033]"
								} satoshi`}>
								{item?.progress?.status}
							</small>
						</div>
					</div>
				))}
			</div>
			{["company", "institution"]?.includes(userPage) && (
				<div className="tw-flex tw-gap-5 tw-mt-5">
					{datum?.partakers?.length < datum?.plan?.noOfPartakers && (
						<MainBtn
							onClick={() => navigate(`/all-staff`, { state: datum })}
							text={`Add ${userPage === "institution" ? "Student" : "Staff"}`}
						/>
					)}
				</div>
			)}
		</div>
	);
};

export default ParticipantTab;
