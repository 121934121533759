import React, { useContext } from "react";
import { GlobalState } from "../../data/Context";
import useAuthStore from "../../data/stores/authstore";
import { useLocation, useNavigate } from "react-router-dom";
import { AiOutlineMenu } from "react-icons/ai";
import Cta from "../../assets/cta.svg";
import { LogoDark } from "../Header";

const Sidebar = ({ children }) => {
  const { nav, toggleNav, sidebarList } = useContext(GlobalState),
    { isAuth } = useAuthStore(),
    navigate = useNavigate(),
    location = useLocation(),
    currentPath = location.pathname;
  return (
    <div>
      {isAuth && (
        <>
          <button
            type="button"
            className="tw-inline-flex tw-items-center tw-p-2 tw-mt-2 tw-ml-3 tw-text-sm tw-text-gray-500 tw-rounded-lg sm:tw-hidden hover:tw-bg-gray-100 focus:tw-outline-none tw-overflow-visible focus:tw-ring-2 focus:tw-ring-gray-200"
            onClick={toggleNav}
          >
            <span className="tw-sr-only">Open sidebar</span>

            <AiOutlineMenu size={20} />
          </button>
          <div
            className={`tw-fixed md:tw-hidden tw-top-0 tw-left-0 tw-z-40 tw-w-full tw-h-screen tw-transition-transform tw-bg-black tw-opacity-10 ${
              nav
                ? "tw-translate-x-0"
                : "-tw-translate-x-full md:tw-translate-x-0"
            }`}
            onClick={toggleNav}
          ></div>
          <aside
            className={`tw-fixed tw-shadow-sm tw-top-0 tw-left-0 tw-z-40 tw-w-56 tw-bg-white tw-min-h-screen tw-pb-8 tw-transition-transform ${
              nav ? "translate-x-0" : "-tw-translate-x-full md:tw-translate-x-0"
            }`}
          >
            <div className="tw-min-h-screen tw-relative tw-py-4 noScroll">
              <div className="tw-flex tw-my-6 tw-justify-center">
                <LogoDark />
              </div>
              <div className="tw-flex tw-justify-center">
                <div className="tw-space-y-4 tw-mt-6">
                  {sidebarList
                    ?.filter((ol) => ol?.show)
                    ?.map((item, i) => (
                      <div
                        onClick={() => navigate(item?.url)}
                        key={i}
                        className={`tw-flex tw-h-8 tw-w-32 tw-cursor-pointer tw-items-center tw-gap-3 tw-px-3 ${
                          currentPath === item?.url ||
                          currentPath.includes(item?.url)
                            ? "tw-bg-[#0269d01a] tw-transition-all tw-duration-500 tw-ease-in-out tw-border-r tw-border-r-[#0269D0]"
                            : "tw-bg-transparent"
                        }`}
                      >
                        {currentPath === item?.url ||
                        currentPath.includes(item?.url) ? (
                          <img
                            src={item?.active || item?.icon}
                            alt=""
                            className=""
                          />
                        ) : (
                          <img src={item?.icon} alt="" className="" />
                        )}
                        <small
                          className={`tw-text-sm satoshi tw-capitalize tw-font-normal ${
                            currentPath === item?.url ||
                            currentPath.includes(item?.url)
                              ? "tw-text-[#0269D0]"
                              : "tw-text-secondary"
                          }`}
                        >
                          {item?.name}
                        </small>
                      </div>
                    ))}
                </div>
              </div>
              <div className="tw-mt-6 tw-w-full">
                <img src={Cta} alt="" className="tw-mx-auto" />
                <div className=" tw-w-11/12 tw-mx-auto -tw-mt-10 tw-bg-[#0269D0] tw-rounded-xl tw-p-3">
                  <h6 className="tw-text-sm tw-pt-4 tw-text-center tw-text-white tw-font-bold">
                    You are on the right path
                  </h6>
                  <p className="tw-text-sm tw-text-center tw-text-white">
                    Education is the passport to the future, for tomorrow
                    belongs to those who prepare for it today.
                  </p>
                </div>
              </div>
            </div>
          </aside>
        </>
      )}
      <div className={`${isAuth ? "sm:tw-ml-[224px]" : ""}`}>
        <div className={`${isAuth ? "" : ""}`}>{children}</div>
      </div>
    </div>
  );
};

export default Sidebar;
