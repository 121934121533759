import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { AiOutlineClose } from "react-icons/ai";
import { toast } from "react-toastify";
import useAuthStore, { apiCall } from "../../data/stores/authstore";
import useErrorStore from "../../data/stores/errorstore";
import useUserStore from "../../data/stores/userstore";
import { Button } from "../button";
import { EyeToggler } from "../../screens/login";

const Adminmodal = ({ handleClose, handleModal, privilege }) => {
	let [state, setState] = useState({}),
		textChange = ({ target: { value, name } }) => {
			setState({ ...state, [name]: value });
		},
		[loading, setLoading] = useState(null),
		{ addUser, addAdmins, addInstructors } = useUserStore(),
		{ returnErrors } = useErrorStore(),
		handleSubmit = async e => {
			e?.preventDefault();
			if (!state?.lastName) return toast.info("Last name is required");
			if (!state?.firstName) return toast.info("First name is required");
			if (!state?.email) return toast.info("User email is required");
			let newState = state;
			console.log({ newState });
			setLoading(true);
			let { response, errArr, errMsg } = await apiCall(
				"post",
				`/api/v1/user/manage-users`,
				{
					...newState,
					nextScreen: `${window.location.origin}/activate`,
					privilege,
				}
			);
			console?.log({ response, errArr, errMsg });
			if (errArr) {
				setLoading(false);
				return returnErrors(errArr);
			}
			if (errMsg) {
				setLoading(false);
				return toast.error(errMsg);
			}
			setLoading(false);
			if (response) {
				if (privilege && privilege?.includes("admin")) addAdmins(response);
				else if (privilege && privilege?.includes("instructor"))
					addInstructors(response);
				else addUser(response);
				setState(null);
				handleModal(state);
				return;
			}
			setLoading(false);
		},
		navigate = useNavigate();

	return (
		<div className="tw-fixed tw-z-50 tw-inset-0 tw-bg-myblue tw-bg-opacity-30 tw-flex tw-justify-center tw-h-screen tw-w-full tw-items-center">
			<div className="tw-bg-white tw-rounded-xl lg:tw-w-96 tw-w-11/12 tw-p-4">
				<div className="tw-flex tw-justify-between tw-items-center">
					<h6 className="tw-text-2xl tw-font-extrabold tw-text-main satosho">
						Add New{" "}
						<span className="tw-capitalize">
							{privilege
								? privilege?.includes("admin")
									? "Admin"
									: privilege
								: "Staff"}
						</span>
					</h6>
					<AiOutlineClose className="tw-cursor-pointer" onClick={handleClose} />
				</div>
				<form action="" className="tw-mt-4">
					<div>
						<p className="tw-text-sm tw-font-medium tw-text-main">Last Name</p>
						<input
							type="text"
							name="lastName"
							className="tw-h-12 tw-pl-4 tw-w-full tw-rounded-lg tw-bg-[#F3F5F5] smallText"
							id=""
							onChange={textChange}
							value={state?.lastName}
							/>
					</div>
					<div className="tw-mt-4">
						<p className="tw-text-sm tw-font-medium tw-text-main">First Name</p>
						<input
							type="text"
							onChange={textChange}
							value={state?.firstName}
							name="firstName"
							className="tw-h-12 tw-pl-4 tw-w-full tw-rounded-lg tw-bg-[#F3F5F5] smallText"
							id=""
						/>
					</div>
					<div className="tw-mt-3">
						<p className="tw-text-sm tw-font-medium tw-text-main">Email</p>
						<input
							type="email"
							name="email"
							onChange={textChange}
							className="tw-h-12 tw-pl-4 tw-w-full tw-rounded-lg tw-bg-[#F3F5F5] smallText"
							id=""
						/>
					</div>
					<div className="tw-flex tw-mt-5 tw-items-center tw-justify-between">
						{privilege && (
							<div className="tw-float-right">
								<Button
									onClick={() => navigate("/bulk-upload", { state: privilege })}
									disabled={loading}
									children={"Use Bulk Upload"}
									type="button"
								/>
							</div>
						)}
						<div className="tw-float-right">
							<Button
								onClick={handleSubmit}
								loading={loading}
								children={"Send Invite"}
								type="button"
							/>
						</div>
					</div>
				</form>
			</div>
		</div>
	);
};

export default Adminmodal;

export const AdminLogin = ({ handleClose, handleModal }) => {
	let [state, setState] = useState({}),
		textChange = ({ target: { value, name } }) => {
			setState({ ...state, [name]: value });
		},
		[loading, setLoading] = useState(null),
		{ returnErrors } = useErrorStore(),
		{ setUserPage, login } = useAuthStore(),
		[show, setShow] = useState(false),
		handleSubmit = async e => {
			e?.preventDefault();
			if (!state?.password) return toast.info("Password is required");
			if (!state?.email) return toast.info("User email is required");
			let newState = state;
			console.log({ newState });
			setLoading(true);
			let { response, errArr, errMsg } = await apiCall(
				"post",
				`/api/v1/user/login`,
				newState
			);
			console?.log({ response, errArr, errMsg });
			if (errArr) {
				setLoading(false);
				return returnErrors(errArr);
			}
			if (errMsg) {
				setLoading(false);
				return toast.error(errMsg);
			}
			setLoading(false);
			if (response) {
				login(response);
				let privi =
					response?.data?.data?.privilege || response?.data?.privilege;
				setUserPage(privi?.includes("institution") ? "institution" : privi);
				setState(null);
				handleModal(state);
				return;
			}
			setLoading(false);
		};

	return (
		<div className="tw-fixed tw-z-50 tw-inset-0 tw-bg-myblue tw-bg-opacity-30 tw-flex tw-justify-center tw-h-screen tw-w-full tw-items-center">
			<div className="tw-bg-white tw-rounded-xl lg:tw-w-96 tw-w-11/12 tw-p-4">
				<div className="tw-flex tw-justify-between tw-items-center">
					<h6 className="tw-text-2xl tw-font-extrabold tw-text-main satosho">
						Switch to Admin Dashboard
					</h6>
					<AiOutlineClose className="tw-cursor-pointer" onClick={handleClose} />
				</div>
				<form action="" className="tw-mt-4">
					<div className="tw-mt-3">
						<p className="tw-text-sm tw-font-medium tw-text-main">
							Email Address
						</p>
						<input
							type="email"
							name="email"
							onChange={textChange}
							className="tw-h-12 tw-pl-4 tw-w-full tw-rounded-lg tw-bg-[#F3F5F5] smallText"
							id=""
						/>
					</div>
					<div className="tw-w-full tw-mt-4 tw-relative">
						<p className="t tw-text-left tw-font-medium tw-text-sm tw-text-black">
							Password
						</p>
						<input
							type={show ? "text" : "password"}
							className="tw-h-12 tw-pl-4 tw-w-full tw-rounded-lg tw-bg-[#F3F5F5] smallText"
							placeholder="Password"
							value={state?.password}
							onChange={textChange}
							name="password"
						/>
						<EyeToggler
							show={show}
							toggleShow={() => setShow(!show)}
							color={"black"}
						/>
					</div>
					<div className="tw-mt-5 tw-float-right">
						<Button
							onClick={handleSubmit}
							loading={loading}
							children={"Sign In"}
							type="button"
						/>
					</div>
				</form>
			</div>
		</div>
	);
};