import React, { useState } from "react";
import MainContainer from "../../components/maincontainer";
import MainHeader from "../../components/mainheader";
import CertImg from "../../assets/certificatemain.png";
import { MainBtn } from "../../components/button";
import { ThanksModal } from "../../components/modal/certificate";

const CertDetail = () => {
  const [modal, setModal] = useState("");
  return (
    <div>
      <MainContainer>
        <MainHeader />
        <div className="tw-bg-white tw-w-full tw-h-screen tw-mt-5 tw-rounded-xl tw-flex tw-justify-center tw-items-center">
          <div>
            <img src={CertImg} alt="" className="tw-h-96" />
            <div className="tw-flex tw-justify-center tw-mt-6">
              <MainBtn onClick={() => setModal("download")} text={"Download"} />
            </div>
          </div>
        </div>
      </MainContainer>
      {modal === "download" && <ThanksModal handleClose={() => setModal("")} />}
    </div>
  );
};

export default CertDetail;
